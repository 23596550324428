import { createContext, useContext } from 'react'

/* Contexts */
export const UserContext = createContext({user: null, token: null})
const PusherContext = createContext()


/* Providers */
function UserProvider({ user, token, children }) {
    return (
        <UserContext.Provider value={{user, token}}>
            {children}
        </UserContext.Provider>
    )
}

function PusherProvider({ pusher, children }) {
    return (
        <PusherContext.Provider value={{ pusher }}>
            {children}
        </PusherContext.Provider>
    );
}



/* Hooks */
function usePusher() {
    const context = useContext(PusherContext)
    if (!context) {
        throw new Error("usePusher must be used within a PusherProvider")
    }

    const { pusher } = context
    return pusher
}


/* Exports */
export { UserProvider, PusherProvider, usePusher }