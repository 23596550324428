import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import { UserContext } from "../../Utils/context"
import AdminOneHittersCell from "./AdminOneHittersCell"

import "../../Styles/adminOneHitters.scss"

function AdminOneHitters() {
    let history = useHistory()
    const { token } = useContext(UserContext)

    const [oneHitters, setOneHitters] = useState([])

    useEffect(() => {
        
        if (token !== null) {
            getOneHitters()
        }
        
    }, [token])

    const getOneHitters = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/one-hitters`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.bubbles !== null) {
                setOneHitters(response.data.bubbles)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createOneHitterButtonPressed = () => {
        history.push("/admin/one-hitters/create")
    }

    return (
        <div className="admin-one-hitters">
            <table className="admin-one-hitters-table">
                <thead>
                    <tr>
                        <td>
                            <button type="button" onClick={createOneHitterButtonPressed}>+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                   { oneHitters.map((oneHitter, index) => (
                        <AdminOneHittersCell oneHitter={oneHitter} />
                    )) }
                </tbody>
            </table>
        </div>
    )
}

export default AdminOneHitters
