import React from 'react'
import { useState, useEffect, useContext, useRef } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import SubmitBtn from "../../Shared/SubmitBtn"

import "../../Styles/adminContentForm.scss"

function AdminContentForm({ mode }) {
    let history = useHistory()
    const location = useLocation()

    const { token } = useContext(UserContext)
    const { contentid } = useParams()
    
    const [subscription, setSubscription] = useState(null)

    const [inventoryType, setInventoryType] = useState("clouds")
    const [clouds, setClouds] = useState([])
    const [stickers, setStickers] = useState([])
    const [stripeIDs, setStripeIDs] = useState([])

    const [contentTitle, setContentTitle] = useState("")
    const [contentFileName, setContentFileName] = useState("")
    const [contentMultiplier, setContentMultiplier] = useState(null)
    const [contentCloud, setContentCloud] = useState([])
    const [contentSticker, setContentSticker] = useState([])
    const [contentStripeID, setContentStripeID] = useState(null)

    const cloudSelectRef = useRef()
    const stickerSelectRef = useRef()

    const [contentType, setContentType] = useState(() => {
        return location.state != null ? location.state.type : null
    })

    const createMode = mode === "create"
    const editMode = mode === "edit"

    useEffect(() => {

        if (editMode && token !== null && contentid !== null) {
            getContent()
        }

        if (token !== null && contentType === "subs") {
            getClouds()
            getStickers()
            getStripeIDs()
        }

    }, [token])

    const getContent = async () => {

        const endPoint = 
            contentType === "clouds" ? `bubbles/${contentid}` :
            contentType === "stickers" ? `stickers/${contentid}` :
            contentType === "subs" ? `subscriptions/${contentid}` :
            ""

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/${endPoint}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (contentType === "clouds" && response.data.bubble !== null) {
                setContentTitle(response.data.bubble.name)
                
                const fileName = response.data.bubble.image.replace("clouds/", "")
                setContentFileName(fileName)
            }

            if (contentType === "stickers" && response.data.sticker !== null) {
                setContentTitle(response.data.sticker.name)
                
                const fileName = response.data.sticker.image.replace("stickers/", "")
                setContentFileName(fileName)
            }

            if (contentType === "subs" && response.data.subscription !== null) {
                await setSubscription(response.data.subscription)
                await setContentTitle(response.data.subscription.title)
                setContentMultiplier(`${response.data.subscription.multiplier}`)
                setContentStripeID(response.data.subscription.stripe_sub_id)

                const subOnItem = response.data.subscription.onItem
                if (subOnItem === "Bubble") {
                    setInventoryType("clouds")
                }

                if (subOnItem === "Sticker") {
                    setInventoryType("stickers")
                }

                if (editMode) {
                    handleSelect(cloudSelectRef, "clouds")
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getClouds = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/bubbles`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.bubbles != null) {
                setClouds(response.data.bubbles)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const getStickers = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/stickers`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.stickers != null) {
                setStickers(response.data.stickers)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const getStripeIDs = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/subscriptions/stripe?type=recurring`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.subscriptions != null) {
                setStripeIDs(response.data.subscriptions)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const createContent = async () => {

        const dataType = 
            contentType === "clouds" ? "bubbles" :
            contentType === "stickers" ? "stickers" :
            contentType === "subs" ? "subscriptions" :
            ""

        const sendData = 
            contentType === "clouds" ?
                {
                    "bubble": {
                        "name": contentTitle,
                        "image": `${contentType}/${contentFileName}`
                    }
                } :
            contentType === "stickers" ? 
                {
                    "sticker": {
                        "name": contentTitle,
                        "image": `${contentType}/${contentFileName}`
                    }
                } :
            contentType === "subs" ? 
                {
                    "subscription": {
                        "title": contentTitle,
                        "items": inventoryType === "clouds" ? contentCloud : contentSticker,
                        "onItem": inventoryType === "clouds" ? "Bubble" : "Sticker",
                        "multiplier": parseInt(contentMultiplier),
                        "stripe_sub_id": contentStripeID
                    }
                } :
            {}

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/${dataType}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data !== null) {
                history.push({
                    pathname: `/admin/content`,
                    state: { withTableFilter: contentType }
                })
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const editContent = async () => {

        const dataType = 
            contentType === "clouds" ? "bubbles" :
            contentType === "stickers" ? "stickers" :
            contentType === "subs" ? "subscriptions" :
            ""

        const sendData = 
            contentType === "clouds" ?
                {
                    "bubble": {
                        "name": contentTitle,
                        "image": `${contentType}/${contentFileName}`
                    }
                } :
            contentType === "stickers" ? 
                {
                    "sticker": {
                        "name": contentTitle,
                        "image": `${contentType}/${contentFileName}`
                    }
                } :
            contentType === "subs" ? 
                {
                    "subscription": {
                        "title": contentTitle,
                        "items": inventoryType === "clouds" ? contentCloud : contentSticker,
                        "onItem": inventoryType === "clouds" ? "Bubble" : "Sticker",
                        "multiplier": parseInt(contentMultiplier),
                        "stripe_sub_id": contentStripeID
                    }
                } :
            {}

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/${dataType}/${contentid}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data !== null) {
                history.push({
                    pathname: `/admin/content`,
                    state: { withTableFilter: contentType }
                })
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitContentForm = (e) => {
        e.preventDefault()

        if (contentTitle.trim().length === 0) {
            alert("Title cannot be empty")
            return false
        }


        if (contentType != "subs" && contentFileName.trim().length === 0) {
            alert("File Name cannot be empty")
            return false
        }

        if (contentType === "subs") {

            if (inventoryType === "clouds") {

                if (contentCloud === null || contentCloud.length === 0) {
                    alert("Clouds are required")
                    return false
                }
            }

            if (inventoryType === "stickers") {

                if (contentSticker === null || contentSticker.length === 0) {
                    alert("Stickers are required")
                    return false
                }
            }

            if (contentMultiplier === null || contentMultiplier.length === 0) {
                alert("Multiplier is required")
                return false
            }

            if (contentStripeID === null || contentStripeID.length === 0) {
                alert("Stripe ID is required")
                return false
            }

        }

        if (createMode) {
            createContent()
        }

        if (editMode) {
            editContent()
        }

        return true
    }

    const handleSelect = (selectRef, type) => {
        if (selectRef.current != null) {

            const selectedItems = selectRef.current.selectedOptions

            const selectedOptionValues = [];
            for (let i = 0; i < selectedItems.length; i++) {
                selectedOptionValues.push(selectedItems[i].value);
            }

            if (type === "clouds") {
                setContentCloud(selectedOptionValues)
            }else{
                setContentSticker(selectedOptionValues)
            }
        }
    }

    const selectOption = (sub, option) => {


        let shouldSelect = false
        
        if (editMode && sub != null) {
            for (let i = 0; i < sub.items.length; i++) {
                const anItem = sub.items[i]
                if (anItem._id === option._id) {
                    shouldSelect = true
                    break
                }
            }
        }

        return shouldSelect
    }

    const pageLabelText = 
        (createMode && contentType === "clouds") ? "Create Cloud" :
        (createMode && contentType === "stickers") ? "Create Sticker" :
        (createMode && contentType === "subs") ? "Create Subscription" :
        (editMode) ? `Edit ${contentTitle}` : ""

    return (
        <div>
            <label className="admin-page-label">{pageLabelText}</label>

            <form className="admin-content-form" onSubmit={submitContentForm}>

                <div>
                    { contentType === "clouds"  && <label>Cloud Title:</label> }
                    { contentType === "stickers"  && <label>Sticker Title:</label> }
                    { contentType === "subs" && <label>Subscription Title:</label> }
                    <input value={contentTitle} placeholder="Enter title" onChange={(e) => setContentTitle(e.target.value)}></input>
                </div>

                { contentType != "subs" &&
                    <div>
                        <label>File Name:</label>
                        <input value={contentFileName} placeholder="Enter the file name" onChange={(e) => setContentFileName(e.target.value)}></input>
                    </div>
                }

                { contentType === "subs" &&
                    <div>

                        <div className="admin-content-options-container">
                            <button className={inventoryType === "clouds" ? "pro-active" : ""} type="button" onClick={() => setInventoryType("clouds")}>
                                Clouds
                            </button>

                            <button className={inventoryType === "stickers" ? "pro-active" : ""} type="button" onClick={() => setInventoryType("stickers")}>
                                Stickers
                            </button>
                        </div>
                        
                        { inventoryType === "clouds" && 
                            <div>
                                <select className="clouds-select" multiple={true} ref={cloudSelectRef} onChange={() => handleSelect(cloudSelectRef, "clouds")}>
                                    { clouds.map((cloud, index) => (
                                        <option value={`${cloud._id}`} selected={selectOption(subscription, cloud, "clouds")}>{cloud.name}</option>
                                    )) }
                                </select>
                            </div>
                        }

                        { inventoryType === "stickers" &&
                            <div>
                                <select className="stickers-select" multiple={true} ref={stickerSelectRef} onChange={() => handleSelect(stickerSelectRef, "stickers")}>
                                    { stickers.map((sticker, index) => (
                                        <option value={`${sticker._id}`} selected={selectOption(subscription, sticker, "stickers")}>{sticker.name}</option>
                                    )) }
                                </select>
                            </div>
                        }

                        <div>
                            <label>Multiplier:</label>
                            <select onChange={(e) => setContentMultiplier(e.target.value)}>
                                { [...Array(150).keys()].map((key) => (
                                    <option value={key === 0 ? "" : `${key}`} selected={`${key}` === contentMultiplier}>{key === 0 ? "Please select a multiplier" : `${key}`}</option>
                                )) }
                            </select>
                        </div>

                        <div>
                            <label>Stripe ID:</label>
                            <select onChange={(e) => setContentStripeID(e.target.value)}>
                                <option value="">Please select a stripe ID</option>

                                { stripeIDs.map((stripeKey, index) => (
                                    <option value={`${stripeKey.stripe_sub_id}`} selected={`${stripeKey.stripe_sub_id}` === contentStripeID}>{stripeKey.name}</option>
                                )) }
                            </select>
                        </div>
                    </div>
                }

                <div className="mar-vert-24">
                    <SubmitBtn text={"Save"} height={38} width={72} />
                </div>

            </form>
        </div>
    )
}

export default AdminContentForm
