import React from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import AwsImage from "../Shared/AwsImage"

import "../Styles/searchGrid.scss"

function SearchGrid({ postImages }) {
    let history = useHistory()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {

        /* Check Resize of Window for Grid Image Sizes Determination */
        window.addEventListener("resize", function() {
            setWindowWidth(window.innerWidth)
          });
    }, [])

    const tileViewPortStyle = (imageIndex) => {
        let bigger = false
        let determinedSize = windowWidth >= 500 ? "166.66px" : "calc((100vw / 3))"

        if (imageIndex % 7 === 0 && imageIndex != 0) {
            determinedSize = windowWidth >= 500 ? "333.32px" : "66.66vw"
            bigger = true
        }

        return {
            height: determinedSize,
            width: determinedSize,
            overflow: "hidden",
            border: "1px solid black",
            borderRight: "unset",
            cursor: "pointer",
            gridArea: bigger ? "auto / auto / span 2 / span 2" : "unset"

        }
    }

    const imageViewPortStyle = (imageIndex) => {
        let determinedSize = windowWidth >= 500 ? "166.66px" : "calc((100vw / 3))"

        if (imageIndex % 7 === 0 && imageIndex != 0) {
            determinedSize = windowWidth >= 500 ? "333.32px" : "66.66vw"
        }
        
        return {
            height: determinedSize,
            width: determinedSize,
            objectFit: "cover"
        }
    }

    const gridItemClicked = (image) => {
        if (image.postid != null) {
            history.push(`/posts/${image.postid}`)
        }
    }




    return (
        <div className={`search-grid-container`}>
            { postImages.map((image, index) => (
                <div className="grid-item" id={`grid-item-${image.postid}`} style={tileViewPortStyle(index)}>
                    <AwsImage fileKey={image.src} customStyle={imageViewPortStyle(index)} customOnClick={() => {gridItemClicked(image)}} />
                </div>
            )) }
        </div>
    )
}

export default SearchGrid
