import React from 'react'
import { useContext } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../components/Utils/context"
import { trackPromise } from "react-promise-tracker"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
import StoreFront from "../components/StorePage/StoreFront"
import StoreCategory from '../components/StorePage/StoreCategory'
import LogoImage from "../components/Images/Logo.png"


import "../components/Styles/storePage.scss"

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);


function StorePage({ mode }) {
    const { token } = useContext(UserContext)
    const { category } = useParams()

    const storeFrontMode = mode === "front"
    const storeCategoryMode = mode === "category"
    const storeSuccessMode = mode === "success"
    const storeCanceledMode = mode === "canceled"

    const stripeCheckout = async (priceId) => {

        const sendData = {
          "priceId": priceId
        }

        try {
          const response = await trackPromise(axios.post(
              `${process.env.REACT_APP_BASE_API}/store/checkout`,
              sendData,
              { headers: { 'authorization': 'Bearer ' + token.access_token } }
          ))

          if (response.data.session != null) {

            const stripe = await stripePromise

            const result = await stripe.redirectToCheckout({
              sessionId: response.data.session
            })

            if (result.error) {
                alert(result.error.message)
            }

          }

      }catch (error) {
          if (error.response.data?.message != null) {
              alert(error.response.data.message)
          }
          throw(error)
      }
    }

    return (
        <div className="store-page-container">
            { storeFrontMode && <StoreFront /> }
            { storeCategoryMode && <StoreCategory category={category} stripeCheckout={stripeCheckout} />}

            { storeSuccessMode && 
              <div className="store-page-status-container">
                <img src={LogoImage}></img>
                <label>Thank you! Your purchase is available now. When you make a new post or comment, tap on Custom Clouds, or Add a Sticker, to see your inventory. <a href="/create">Make a new Post (+) now!</a></label>
              </div>
            }

            { storeCanceledMode && 
              <div className="store-page-status-container">
                <img src={LogoImage}></img>
                <label>Your order has been canceled. <a href="/store">Return to store</a></label>
              </div>
            }
        </div>
    )
}

export default StorePage
