import React from 'react'
import { useHistory } from "react-router-dom"

function AdminBadgesCell({ badge }) {
    let history = useHistory()

    const badgeCellClicked = () => {
        history.push(`/admin/badges/${badge._id}`)
    }

    return (
        <tr onClick={badgeCellClicked}>
            <td className="admin-badge-cell">
                <div className="admin-badge-cell-content">
                    <div><span>{badge.name}</span></div>
                    <div>{badge.target_value.toLocaleString('en')}</div>
                    <div>{badge.image.replace("badges/", "")}</div>
                </div>
            </td>
        </tr>
    )
}

export default AdminBadgesCell
