import React from 'react'
import { useState, useContext, useEffect } from 'react'
import axios from "axios"
import { UserContext } from "../components/Utils/context"
import { awsPutObject } from "../components/Utils/awsHandler"
import { useParams } from "react-router-dom"
import { trackPromise } from 'react-promise-tracker'

import ProfileActionBar from "../components/ProfilePage/ProfileActionBar"
import ProfileImageFull from "../components/ProfilePage/ProfileImageFull"
import DialogModal from "../components/Shared/DialogModal"
import DropDown from "../components/Shared/DropDown"

import "../components/Styles/profilePage.scss"

function ProfilePage({ localStoreUserData }) {
    const { user, token } = useContext(UserContext)
    const [editMode, setEditMode] = useState(false)
    const [viewUser, setViewUser] = useState(null)
    const [viewUserPoints, setViewUserPoints] = useState(0)
    const [viewFollowers, setViewFollowers] = useState([])
    const [viewFollowing, setViewFollowing] = useState([])
    const [currentUserFollowingViewUser, setCurrentUserFollowingViewUser] = useState(false)
    const [viewUserPostCount, setViewUserPostCount] = useState(0)

    const [showDialogModal, setShowDialogModal] = useState(false)

    const { userid } = useParams()
    const isCurrentUser = userid === user._id
   
    useEffect(() => {
        setEditMode(false)

        if (token != null) {
            retrieveProfile(userid)
        }
    }, [userid, token])

    const toggleEditMode = (shouldEdit) => {
        setEditMode(shouldEdit);
    }

    const retrieveProfile = async (userID) => {

        const uri = isCurrentUser ? "me" : `users/${userID}`

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/${uri}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.points != null) {
                setViewUserPoints(response.data.points)
            }

            if (response.data.post_count != null) {
                setViewUserPostCount(response.data.post_count)
            }

            if (response.data.followers != null) {
                setViewFollowers(response.data.followers)
                checkCurrentUserFollowingViewUser(response.data.followers)
            }

            if (response.data.following != null) {
                setViewFollowing(response.data.following)
            }

            if (response.data.user != null) {
                setViewUser(response.data.user)

                if (isCurrentUser) {
                    localStoreUserData(response.data)
                }

                if (isCurrentUser && (!response.data.user.bio || !response.data.user.photo_url)) {
                    setEditMode(true)
                    setShowDialogModal(true)
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const editProfile = async (profilePic, bio, badge_visible, badge) => {

        let photo_url = user.photo_url != null ? user.photo_url : ""
        if (profilePic != null) {

            try {

                const fileName = `users/${user._id}/profile/avatar_${Date.now()}`
                let profilePicBlob = await trackPromise(fetch(profilePic).then(r => r.blob()));
                let file = new File([profilePicBlob], fileName, { type: profilePicBlob.type, lastModified: Date.now() })
                
                const uplRes = await trackPromise(awsPutObject(file, fileName))
                if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
                    photo_url = fileName
                }
                
            }catch(err) {
                alert(err)
                throw(err)
            }
        }

        const sendData = {
            "user": {
                "photo_url": photo_url,
                "bio": bio,
                "badge_visible": badge_visible
            }
        }

        if (badge !== null) {
            sendData["user"]["badge"] = badge
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/me`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            localStoreUserData(response.data)
            setEditMode(false)

            if (response.data.points != null) {
                setViewUserPoints(response.data.points)
            }

            if (response.data.user != null) {
                setViewUser(response.data.user)
            }


        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
        
    }

    const toggleFollowUser = async (type) => {

        try {
            const response = await trackPromise(axios.put(
                `${process.env.REACT_APP_BASE_API}/users/${type}?user_id=${viewUser._id}`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.followers != null) {
                setViewFollowers(response.data.followers)
                checkCurrentUserFollowingViewUser(response.data.followers);
            }

            if (response.data.following != null) {
                setViewFollowing(response.data.following)
            }

            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const checkCurrentUserFollowingViewUser = (followersData) => {
        
        let valid = false
        for (let i = 0; i < followersData.length; i++) {
            const aFollower = followersData[i]
            if (aFollower._id === user._id) {
                valid = true
                break
            }
        }

        setCurrentUserFollowingViewUser(valid)

    }

    const dialogModalClosed = () => {
        setShowDialogModal(false)
    }
    
    return (
        <div className="profile-container">

            {(!editMode && viewUser) && <ProfileActionBar type={"actions"} viewUser={viewUser} isCurrentUser={isCurrentUser} postCount={viewUserPostCount} toggleFollowUser={toggleFollowUser} currentUserFollowingViewUser={currentUserFollowingViewUser} />}
            { viewUser != null && <ProfileImageFull toggleEditMode={toggleEditMode} editModeEnabled={editMode} editProfile={editProfile} viewUser={viewUser} isCurrentUser={isCurrentUser} viewUserPoints={viewUserPoints} /> }
            {(!editMode && viewUser) && <ProfileActionBar type={"stats"} viewUser={viewUser} isCurrentUser={isCurrentUser} postCount={viewUserPostCount} followers={viewFollowers} following={viewFollowing} />}

            {(viewUser != null && user != null && viewUser._id != user._id) &&
                <div style={{width: "96%", textAlign: "right", marginTop: "10px"}}>
                    <DropDown items={[
                        {
                            title: "Report",
                            url: `/report/user/${viewUser._id}`
                        }
                    ]}/>
                </div>
            }

            {(editMode && showDialogModal) && <DialogModal closeAction={dialogModalClosed} /> }
            
        </div>
    )
}

export default ProfilePage
