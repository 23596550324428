import React from 'react'
import { useContext } from "react"
import { useHistory } from "react-router-dom";
import { UserContext } from "../Utils/context"
import AwsImage from "../Shared/AwsImage"

import "../Styles/postAddComment.scss"

function PostAddComment({ post }) {
    const history = useHistory()
    const { user } = useContext(UserContext)

    const redirectToComments = () => {
        history.push(`/posts/${post._id}/comments`)
    }

    const currentUserCommented = () => {
        let currentUserCommented = false
        if (post.commented != null && post.commented.length > 0) {
            for (let i = 0; i < post.commented.length; i++) {
                const aComment = post.commented[i]
                if (aComment._id === user._id) {
                    currentUserCommented = true
                    break
                }
            }
        }

        return currentUserCommented
    }

    const addCommentLabelStyle = {
        color: currentUserCommented() ? "#0433ff" : "black"
    }

    return (
        <div className="post-add-comment-container">
                <div className="post-add-comment-content">
                    <AwsImage fileKey={user.photo_url} customClassName={"post-add-comment-profile"} />
                </div>
                <div className="post-add-comment-content">
                    <label className="post-add-comment-label" style={addCommentLabelStyle} onClick={redirectToComments}>+ Add a Comment</label>
                </div>
                <div className="post-add-comment-content">
                    <label className="post-add-comment-label" style={addCommentLabelStyle} onClick={redirectToComments}>
                        View All ({post.comment_count})
                    </label>
                </div>
        </div>
    )
}

export default PostAddComment
