import React from 'react'
import { useState } from "react"
import Ellipsis from "../Images/ellipsis.png"

import "../Styles/dropDown.scss"

function DropDown({ items }) {

    const [show, setShow] = useState(false)

    return (
        <div className="dropdown">

            <img className="post-action-elip" src={Ellipsis} onClick={() => setShow(!show)}></img>

            <ul class="dd-menu" style={{display: show ? "block" : "none"}}>
                { items.map((item, index) => (
                    <li className={item.title === "divider" ? "divider" : ""}>
                       { item.url !== null &&  <a href={item.url}>{item.title}</a> }
                       { item.url === null && 
                            item.title
                       }
                    </li>
                )) }
            </ul>
        </div>
    )
}

export default DropDown
