import React from 'react'
import { useState, useContext, useEffect } from 'react'
import { UserContext } from "../Utils/context"
import { trackPromise } from 'react-promise-tracker';
import axios from "axios"
import Modal from "react-bootstrap/Modal"
import AwsImage from "../Shared/AwsImage"
import PadLockImage  from "../Images/store/padlock.png"

import "../Styles/selectionModal.scss"

function SelectionModal({ closeAction, chooseAction, selectionType }) {
    /* Modal States */
    const [show, setShow] = useState(true)
    const handleShow = () => setShow(true)
    const handleClose = () => {
        closeAction()
        setShow(false)
    }

    const bubblesType = selectionType === "bubbles"
    const stickersType = selectionType === "stickers"
    const badgesType = selectionType === "badges"

    const { token } = useContext(UserContext)
    const [selections, setSelections] = useState([])

    useEffect(() => {

        if (bubblesType || stickersType || badgesType) {
            retrieveUserInventory()
        }
    }, [])

    const retrieveUserInventory = async () => {

        const endPoint = badgesType === true ? "/badges" : "" 

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/inventory${endPoint}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if ((response.data.bubbles != null || response.data.one_hitters != null) && bubblesType) {
                let bubbles = response.data.bubbles != null ? response.data.bubbles : []
                let one_hitters = response.data.one_hitters != null ? response.data.one_hitters : []

                // Used to distinguish bubbles (clouds) from one hitters in Post Create
                for (let i = 0; i < one_hitters.length; i++) {
                    one_hitters[i]["one_hitter"] = true
                }

                // Add One Hitters to the Selection List when choosing Bubbles (clouds)
                bubbles = bubbles.concat(one_hitters)

                setSelections(bubbles)
            }

            if (response.data.stickers != null && stickersType) {
                setSelections(response.data.stickers)
            }

            if (response.data.badges != null && badgesType) {
                setSelections(response.data.badges)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const badgeNoDisplayChoosen = () => {
        handleClose()
        chooseAction("no-display")
    }

    const selectionChoosen = (selection) => {
        handleClose()

        const selectionType = selection.one_hitter != null ? "OneHitter" : bubblesType ? "Bubble" : stickersType ? "Sticker" : badgesType ? "Badge" : ""

        const selectionElem = document.getElementById(selection._id)
        const selectionImage = selectionElem.src != null ? selectionElem.src : ""
        chooseAction(selection._id, selectionImage, selectionType)
    }


    const modalTitle = bubblesType ? "Custom Clouds" : stickersType ? "Stickers" : badgesType ? "My Badges" : ""

    return (
        <>
            <Modal show={show} onHide={handleClose} scrollable={false}>
                <Modal.Header closeButton style={{position: "sticky", top: "0", zIndex: "1", background: "white"}}>
                <Modal.Title>
                    {modalTitle}
                    { (bubblesType || stickersType) &&
                        <a className="selection-modal-route" href="/store">View Store</a>
                    }
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: "400px", padding: "0px"}}>
                    <table className="selection-table">
                        <tbody>
                            { badgesType && 
                                <tr>
                                    <td className="selection-tablecell">
                                        <div className="selection-tc-head">
                                            <label className="selection-head-title">No Display</label>
                                        </div>
                                        <div className="selection-tc-img-container" style={{textAlign: "center", margin: "85px 0px"}}>
                                            <label className="selection-head-title">Please don't display my badge.</label>
                                        </div>
                                        <div className="selection-tc-select-container">
                                            <button type="button" className="selection-tc-selectbtn" onClick={() => {badgeNoDisplayChoosen()}}>Choose</button>
                                        </div>
                                    </td>
                                </tr>
                            
                            }

                            { selections.map((result, index) => (
                                <tr key={result._id}>
                                    <td className="selection-tablecell">
                                        <div className="selection-tc-head">
                                            <label className="selection-head-title">{result.name}</label>
                                            {/* <label className="selection-head-subtitle">The classic Do Better cloud</label> */}
                                        </div>
                                        <div className="selection-tc-img-container">
                                            <AwsImage fileKey={result.image} customClassName={"selection-tc-img"} customID={`${result._id}`} />
                                        </div>
                                        <div className="selection-tc-select-container">
                                            { (badgesType && result.target_value != null) && <label>{result.target_value.toLocaleString('en')} pts</label> }
                                            { (!badgesType || (badgesType && result.owned)) &&
                                                <button type="button" className="selection-tc-selectbtn" onClick={() => {selectionChoosen(result)}}>Choose</button>
                                            }
                                            { (badgesType && !result.owned) &&
                                                <img src={PadLockImage}></img>
                                            }
                                        </div>
                                    </td>
                                </tr>                          

                            )) }
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectionModal
