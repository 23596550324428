import React from 'react'
import RemoveIcon from "../Images/remove-icon.png"

import "../Styles/removeBtn.scss"

function RemoveBtn({ removeAction }) {
    return (
        <img className="removebtn" src={RemoveIcon} onClick={() => {removeAction()}}></img>
    )
}

export default RemoveBtn
