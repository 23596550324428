import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory, useParams } from "react-router-dom"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import SubmitBtn from "../../Shared/SubmitBtn"

import "../../Styles/adminBadgeForm.scss"

function AdminBadgeForm({ mode }) {
    let history = useHistory()
    const { token } = useContext(UserContext)
    const { badgeid } = useParams()
    
    const [badgeTitle, setBadgeTitle] = useState("")
    const [badgePoints, setBadgePoints] = useState("")
    const [badgeFileName, setBadgeFileName] = useState("")

    const createMode = mode === "create"
    const editMode = mode === "edit"

    useEffect(() => {

        if (editMode && token !== null && badgeid !== null) {
            getBadge()
        }

    }, [token])

    const getBadge = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/badges/${badgeid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.badge !== null) {
                setBadgeTitle(response.data.badge.name)
                setBadgePoints(response.data.badge.target_value)
                
                const fileName = response.data.badge.image.replace("badges/", "")
                setBadgeFileName(fileName)
            }



        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createBadge = async () => {

        const sendData = {
            "badge": {
                "name": badgeTitle,
                "image": `badges/${badgeFileName}`,
                "target_value": badgePoints
            }
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/badges`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.badge !== null) {
                history.push("/admin/badges")
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const editBadge = async () => {
        const sendData = {
            "badge": {
                "name": badgeTitle,
                "image": `badges/${badgeFileName}`,
                "target_value": badgePoints
            }
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/badges/${badgeid}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.badge !== null) {
                history.push("/admin/badges")
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitBadgeForm = (e) => {
        e.preventDefault()

        if (badgeTitle.trim().length === 0) {
            alert("Badge Title cannot be empty")
            return false
        }

        if (badgePoints === null) {
            alert("Points cannot be empty")
            return false
        }

        if (badgeFileName.trim().length === 0) {
            alert("File Name cannot be empty")
            return false
        }

        if (createMode) {
            createBadge()
        }

        if (editMode) {
            editBadge()
        }

        return true
    }

    return (
        <div>
            <form className="admin-badge-form" onSubmit={submitBadgeForm}>

                <div>
                    <label>Badge Title:</label>
                    <input value={badgeTitle} placeholder="Enter new badge title" onChange={(e) => setBadgeTitle(e.target.value)}></input>
                </div>

                <div>
                    <label>Points:</label>
                    <input value={badgePoints} type="number" placeholder="Enter points" onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} onChange={(e) => setBadgePoints(e.target.value)}></input>
                </div>

                <div>
                    <label>File Name:</label>
                    <input value={badgeFileName} placeholder="Enter the file name" onChange={(e) => setBadgeFileName(e.target.value)}></input>
                </div>

                <div className="mar-vert-24">
                    <SubmitBtn text={"Save"} height={38} width={72} />
                </div>

            </form>
        </div>
    )
}

export default AdminBadgeForm
