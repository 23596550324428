import React from 'react'
import { useState, useEffect, useContext, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import { trackPromise } from "react-promise-tracker"
import { UserContext, usePusher } from "../components/Utils/context"
import ConversationCell from "../components/MessagePage/ConversationCell"
import SubmitBtn from "../components/Shared/SubmitBtn"
import AwsImage from '../components/Shared/AwsImage'
import MessageBubble from "../components/MessagePage/MessageBubble"
import UserCell from "../components/Shared/UserCell"
import { use100vh } from 'react-div-100vh'

import InfiniteScroll from 'react-infinite-scroll-component'
import RefreshContent from "../components/Shared/RefreshContent"

import NewMessageIcon from "../components/Images/message-icons/new-message.png"
import VerifiedIcon from "../components/Images/profile-icons/verified.png"

import "../components/Styles/messagePage.scss"


function MessagePage({ mode, conversations, retrieveConversations, updateConversation }) {
    const mainContainerHeight = use100vh()
    const pusher = usePusher()
    let history = useHistory()
    const { user, token } = useContext(UserContext)
    const { userid } = useParams()

    const searchFollowingInput = useRef()
    const messageInput = useRef()
    const messagesEndDiv = useRef()
    const mesagesTableContainer = useRef()

    const createMode = mode === "create"
    const converseMode = mode === "converse"
    const viewMode = mode === "view"

    const [otherParticipant, setOtherParticipant] = useState(null)
    const [currentConversation, setCurrentConversation] = useState(null)
    const [messages, setMessages] = useState(null)


    const [messageText, setMessageText] = useState("")

    const [following, setFollowing] = useState([])
    const [followingResults, setFollowingResults] = useState([])

    const [page, setPage] = useState(1)
    const pageLimit = 10
    const [hasMorePages, setHasMorePages] = useState(false)

    useEffect(() => {
        if (token != null) {

            if (viewMode) {
                retrieveConversations()
            }

            if (converseMode && userid != null) {

                if (messages === null) {
                    subscribeToAndLoadConversation()
                    handleScrollingBehaivors()
                }

            }

            if (createMode) {
                getCurrentUserFollowing()
                searchFollowingInput.current.focus()
            }
        }

        history.listen(onRouteChange.bind(this))

    }, [token, messages])

    const onRouteChange = (route) => {
        if (converseMode && currentConversation != null) {
            pusher.unbind(`conversation_${currentConversation._id}`)
        }
    }

    const subscribeToAndLoadConversation = () => {
        getConversationByUserID()
    }

    const conversationEventCallback = (data) => {
        setMessages(messages => [...messages, data])
        markMessageAsRead(data)
    }

    const getCurrentUserFollowing = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/me`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.following != null) {
                setFollowing(response.data.following)
            }
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getConversationByUserID = async () => {

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/conversations/messages?user_id=${userid}&page=${page}&limit=${pageLimit}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.conversation != null) {
                setCurrentConversation(response.data.conversation)
                updateConversation(response.data.conversation)

                const otherParticipant = getOtherParticipant(response.data.conversation)
                if (otherParticipant != null) {
                    setOtherParticipant(otherParticipant)
                }

                pusher.bind(`conversation_${response.data.conversation._id}`, conversationEventCallback)

            }


            if (response.data.messages != null) {
                let newMessages = response.data.messages.docs

                if (newMessages != null) {
                    if (newMessages.length > 0) {
                        newMessages = newMessages.reverse()
                    }

                    const hasNextPage = response.data.messages.hasNextPage
                    const nextPage = response.data.messages.nextPage
                
                    if (page === 1) {
                        setMessages(newMessages)
                    }else{
                        setMessages(newMessages.concat(messages))
                    }
                    
    
                    if (hasNextPage != null) {
                        
                        setPage(nextPage)
                        setHasMorePages(hasNextPage)
                    }
                }
            }else{
                setMessages([])
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const sendMessage = async (event) => {
        event.preventDefault()

        if (messageText === null || messageText.trim().length === 0) {
            alert("Message Text is required")
            return false
        }

        const sendData = {
            message: {
                body: messageText
            }
        }
        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/conversations/messages?user_id=${userid}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.conversation != null) {
                setCurrentConversation(response.data.conversation)
            }

            if (response.data.message != null) {
                setMessages(messages => [...messages, response.data.message])
            }

            handleScrollingBehaivors()

            setMessageText(null)
            messageInput.current.value = ""

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 


        return true
    }

    const markMessageAsRead = async (message) => {

        axios.patch(
            `${process.env.REACT_APP_BASE_API}/conversations/messages?message_id=${message._id}`,
            {},
            { headers: { 'authorization': 'Bearer ' + token.access_token } }
        )
    }

    const otherParticipantClicked = () => {
        if (otherParticipant != null) {
            history.push(`/profile/${otherParticipant._id}`)
        }   
    }

    const getOtherParticipant = (convo) => {

        const participants = convo.participants

        let otherParticipant = null
        for (let i = 0; i < participants.length; i++) {
            const aParticipant = participants[i]
            if (aParticipant._id != user._id) {
                otherParticipant = aParticipant
                break
            }
        }

        return otherParticipant
    }

    const searchFollowing = (searchValue) => {
        if (searchValue.trim().length === 0) {
            setFollowingResults([])
            return
        }

        const lowerCasedSearchValue = searchValue.toLowerCase()

        let results = []
        for (let i = 0; i < following.length; i++) {
            const aFollowing = following[i]
            if (aFollowing != null && aFollowing.username.includes(lowerCasedSearchValue)) {
                results.push(aFollowing)
            }
        }

        setFollowingResults(results)
    }

    const createMessageClicked = () => {
        history.push("/messages/create")
    }

    function preventDefault(e){
        e.preventDefault();
    }

    const handleScrollingBehaivors = () => {

        // Prevent Incorrect Mobile Browser Grab of Elems Which Causes Bounce on Page
        const noScrollElems = document.getElementsByClassName("no-scroll-elem");
        for (let i = 0; i < noScrollElems.length; i++) {
            const anElem = noScrollElems[i]
            anElem.addEventListener('touchmove', preventDefault, { passive: false });
        }

        // Scroll to Last Message in Table (bottom)
        if (converseMode && messages != null) {
            messagesEndDiv.current.scrollIntoView()
        }
    }

    const messagePageContainerStyle = {
        minHeight: (viewMode || createMode) ? "92.3vh" : "unset"
    }

    const messageTableContainerStyle = {
        display: "flex",
        flexDirection: "column-reverse",
        height: `${mainContainerHeight - 251}px`
    }

    return (
        <div className="messages-page-container" style={messagePageContainerStyle}>
            <div className="messages-head-container no-scroll-elem">
                { viewMode && "Messages" }
                { viewMode && <img className="messages-create-icon" src={NewMessageIcon} onClick={createMessageClicked}></img>}

                { (converseMode && otherParticipant != null) &&
                    <div className="messages-head-img">
                        <AwsImage fileKey={otherParticipant.photo_url} customOnClick={otherParticipantClicked} />
                    </div>
                    
                }
                { (converseMode && otherParticipant != null) && <label className="messages-head-username">
                    {`@${otherParticipant.username}`}
                    { otherParticipant.verified === true &&
                        <img src={VerifiedIcon}></img>
                    }
                </label> }

                { createMode &&
                    <div className="messages-search-input-container">
                        <span>To:</span> <input ref={searchFollowingInput} onChange={(e) => searchFollowing(e.target.value)}></input>
                    </div>
                }
            </div>
            <div className={`messages-table-container ${(viewMode || createMode) ? "mtc-converse" : ""}`} id="messages-table-container" ref={mesagesTableContainer} style={messageTableContainerStyle}>


                { (converseMode && messages != null) ?
                    <InfiniteScroll dataLength={messages.length} next={getConversationByUserID} hasMore={hasMorePages} inverse={true} scrollableTarget="messages-table-container">
                        <table className="messages-table">
                            <tbody>
                                { (converseMode && messages != null && messages.length > 0) && <MessageBubble messages={messages} /> }
                            </tbody>
                            <tfoot>
                                <div ref={messagesEndDiv}></div>
                            </tfoot>
                        </table>
                    </InfiniteScroll>
                :
                    <table className="messages-table">
                        <thead>                         
                            { viewMode && (conversations === null || conversations.length === 0) &&
                                <tr>
                                    <td>
                                        <label className="messages-table-empty-label">Start a conversation, do better sparking with those you follow!</label>
                                    </td>
                                </tr>
                            }
                        </thead>

                        <tbody>
                            { (viewMode && conversations != null && conversations.length > 0) && <ConversationCell conversations={conversations} /> }
                            { (createMode && followingResults.length > 0) && <UserCell users={followingResults} userClickLocation={"messages"} /> }
                        </tbody>
                    </table>
                }
                
                
            </div>
            { converseMode && 
                <form onSubmit={(e) => sendMessage(e)} className="no-scroll-elem">
                    <div className="messages-text-input-container">
                        <input ref={messageInput} placeholder="write your message" onChange={(e) => setMessageText(e.target.value)}></input>
                        <SubmitBtn text={"Send"} height={38} width={72} />
                    </div>
                </form>
            }
        </div>
    )
}

export default MessagePage
