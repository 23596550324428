import React from 'react'
import { useState } from "react"
import SubmitBtn from "../Shared/SubmitBtn"

import "../Styles/helpForm.scss"

function HelpStepTwo({ resetPassword }) {
    const [activationCode, setActivationCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const handleResetPassword = async (e) => {
        e.preventDefault()

        if (activationCode.trim().length === 0) {
            alert("Activation Code is Required")
            return false;
        }

        if (newPassword.trim().length === 0) {
            alert("New Password is Required")
            return false
        }

        if (confirmPassword.trim().length === 0) {
            alert("Confirm Password is Required")
            return false
        }

        if (newPassword != confirmPassword) {
            alert("Passwords do not match")
            return false
        }

        resetPassword(activationCode, newPassword)
    }

    return (
        <div>
            <div className="mar-vert-36"></div>
            <div>
                <label className="help-step-label">
                    Activation code sent to your phone:
                </label>
            </div>
            <div className="mar-vert-24"></div>

            <form className="help-form" id="help-step-one-form" onSubmit={handleResetPassword}>
                
                <div className="help-form-content" style={{width: "75%"}}>
                    <input className="help-form-input" placeholder="activation code" type="text" onChange={(e) => setActivationCode(e.target.value)} style={{width: "100%"}} autoComplete="one-time-code" required></input>
                    
                    <div className="mar-vert-36"></div>

                    <div>
                        <label className="help-step-label">
                            Reset your password:
                        </label>
                    </div>
                    
                    <div className="mar-vert-24"></div>


                    <input className="help-form-input" placeholder="new password" type="password" onChange={(e) => setNewPassword(e.target.value)} style={{width: "100%"}} required></input>
                    <input className="help-form-input" placeholder="confirm" type="password" onChange={(e) => setConfirmPassword(e.target.value)} style={{width: "100%"}} required></input>
                </div>

                <div className="mar-vert-24"></div>

                <SubmitBtn text={"Finish!"} height={38} width={82} />

            </form>
        </div>
    )
}

export default HelpStepTwo
