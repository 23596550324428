import React from 'react'
import { useState, useEffect } from "react"
import { awsGetObject } from "../Utils/awsHandler"
import VisibilitySensor from 'react-visibility-sensor';

import "../Styles/awsImage.scss"

function AwsImage({ fileKey, customClassName, customID, customRef, customStyle, customOnClick, imageProps, useLoadingClass, overrideImage }) {
    const [imageSrc, setImageSrc] = useState(null)
    const [loadingClassName, setLoadingClassName] = useState(() => {
        return useLoadingClass != null && !useLoadingClass ?  "" : "img-loading"
    })

    const [visible, setVisible] = useState(false)

    useEffect(async () => {
        if (visible && imageSrc === null && (overrideImage === null || !overrideImage)) {
            // AWS File Dir/Key Has Been Provided as Prop
            if (fileKey != null) {
                getImage(fileKey)
            }

            // AWS File Dir/Key is contained within ImageProps (ex. Search Grid)
            if (imageProps != null) {
                getImage(imageProps.src)
            }
        }

        if (visible && overrideImage != null) {
            setImageSrc(overrideImage)
            setLoadingClassName("")
        }

    }, [visible, fileKey])

    const getImage = async (key) => {
        if (key != null) {

             awsGetObject(key).then(res => {

                if (res != null && res.Body != null) {
                    const base64 = res.Body.toString('base64')
                    setImageSrc(`data:image;base64,${base64}`)

                    setLoadingClassName("")
                }

            }).catch(error => {
                //setLoadingClassName("")
                
                //console.log(error)
                throw(error)
            })
        }
    }

    return (
        <VisibilitySensor onChange={
            (isVisible) => {
                setVisible(isVisible)
            }
        } partialVisibility={true}>
            <div className="awsimg-container">
                { loadingClassName.length > 0 &&
                    <div class={"_loading_overlay_spinner css-50etie img-loading-spinner"}><svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle></svg></div>
                }
                <img className={`${customClassName} ${loadingClassName}`} id={customID} src={imageSrc} ref={customRef} style={customStyle} onClick={customOnClick} loading="lazy" alt=""></img>
            </div>
        </VisibilitySensor>
    )
}

export default AwsImage
