import React from 'react'
import { useHistory } from "react-router-dom"
import AwsImage from "../../Shared/AwsImage"

import SuspendedImage from "../../Images/profile-icons/suspended-image.png"

function AdminUsersCell({ user, reportType }) {
    let history = useHistory()

    const userCellClicked = (e) => {
        e.stopPropagation()

        history.push({
            pathname: `/admin/users/${user._id}`,
            state: { withTableFilter: reportType }
        })
    }

    return (
        <tr key={user._id}>
            <td className="admin-users-tablecell">
                <div className="admin-users-tr-content">
                    <AwsImage fileKey={user.photo_url} customClassName={"admin-users-tr-img"} overrideImage={user.suspended ? SuspendedImage : null} />
                        <div className="admin-users-tr-label-container"> 
                            <label className="admin-users-tr-userlabel">@{user.username}</label>
                        </div>

                        <div className="admin-users-tr-viewreport" onClick={(e) => userCellClicked(e)}>
                            view report
                        </div>
                </div>


            </td>
        </tr>
    )
}

export default AdminUsersCell
