import React from 'react'
import { useLocation } from 'react-router-dom'

import LogoImg from "../Images/Logo.png"


import "../Styles/logo.scss"

function Logo({ from, size }) {
    const location = useLocation();
    const logoClassName =  `logo-img-${size}`;

    const onLandingRoute = location.pathname === "/"
    const onRegisterRoute = location.pathname === "/register"
    const onHelpRoute = location.pathname === "/help"
    const onTermsRoute = location.pathname === "/terms"
    const onContactRoute = location.pathname === "/contact"
    const onAboutRoute = location.pathname === "/about"

    const logoImgStyle = (from != null && from === "header" && (onLandingRoute || onRegisterRoute || onHelpRoute || onTermsRoute || onContactRoute || onAboutRoute)) ? {visibility: "hidden"} : {}

    return (
        <div className="logo-container">
            
            <img className={logoClassName} src={LogoImg} alt="" style={logoImgStyle}></img>

        </div>
    )
}

export default Logo
