import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory, useParams } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import { UserContext } from "../../Utils/context"
import AdminOutletsCodesCell from './AdminOutletsCodesCell'

import "../../Styles/adminOutletsCodes.scss"

function AdminOutletsCodes() {
    let history = useHistory()
    const { token } = useContext(UserContext)

    const { outletid } = useParams()

    const [codes, setCodes] = useState([])
    const [filteredCodes, setFilteredCodes] = useState([])

    useEffect(() => {
        
        if (token !== null) {
            getCodes()
        }
        
    }, [token])

    const getCodes = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}/codes`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.codes !== null) {
                setCodes(response.data.codes)
                setFilteredCodes(response.data.codes)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createCodeButtonPressed = () => {
        history.push(`/admin/outlets/${outletid}/codes/create`)
    }

    const searchCodes = (searchString) => {
        let newFilteredCodes = []
        for (let i = 0; i < codes.length; i++) {
            const aCode = codes[i]
            if (aCode != null && (aCode.code.includes(searchString) || aCode.claimed_by?.email.includes(searchString))) {
                newFilteredCodes.push(aCode)
            }
        }

        if (newFilteredCodes.length === 0) {
            newFilteredCodes = codes
        }

        setFilteredCodes(newFilteredCodes)
    }

    return (
        <div className="admin-outlets-codes">
            <table className="admin-outlets-codes-table">
                <thead>
                    <tr>
                        <td>
                            <div className="admin-outlets-thead-content">
                                <div>
                                    <input className="admin-outlets-search-bar" type="text" placeholder="Search" onChange={(e) => {searchCodes(e.target.value)}}></input>
                                </div>
                                <button type="button" onClick={createCodeButtonPressed}>+</button>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                   { filteredCodes.map((code, index) => code != null && (
                        <AdminOutletsCodesCell code={code} />
                    )) }
                </tbody>
            </table>
        </div>
    )
}

export default AdminOutletsCodes
