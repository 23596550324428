import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { UserContext } from '../components/Utils/context'
import axios from "axios"
import InfiniteScroll from 'react-infinite-scroll-component'
import PointsStatBar from '../components/PointsPage/PointsStatBar'
import PointsTableCell from '../components/PointsPage/PointsTableCell'

import "../components/Styles/pointsPage.scss"

function PointsPage() {
    let history = useHistory()
    const location = useLocation()
    const { token } = useContext(UserContext)

    const [tableFilter, setTableFilter] = useState(() => {
        return location.state != null ? location.state.withTableFilter : "earned"
    })

    const [earnedPoints, setEarnedPoints] = useState([])
    const [redeemedPoints, setRedeemedPoints] = useState([])
    const [availablePoints, setAvailablePoints] = useState("-")
    const [totalPoints, setTotalPoints] = useState("-")

    useEffect(() => {
        if (token != null) {
            getPoints()
        }
    }, [token])

    const getPoints = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_API}/me/points`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            )

            if (response.data.earned_points != null) {
                let earned_points = response.data.earned_points

                // Append Registered Account Achievement To Earned Points
                earned_points.push({
                    createdAt: null,
                    doc_type: "Achievement",
                    id: null,
                    name: "Registered Account",
                    points: 1000,
                    _id: null
                })

                setEarnedPoints(earned_points)
            }

            if (response.data.redeemed_points != null) {
                setRedeemedPoints(response.data.redeemed_points)
            }
            
            if (response.data.available_points != null) {
                setAvailablePoints(response.data.available_points)
            }

            if (response.data.total_points != null) {
                setTotalPoints(response.data.total_points)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const switchTableFilter = (type) => {
        setTableFilter(type)
    }

    const redeemBtnClicked = () => {
        if (availablePoints < 5000 || availablePoints === "-") {
            alert("You must have at least 5000 available points in order to redeem")            
            return
        }

        history.push("/points/redeem")
    }

    return (
        <div className="points-container">
            <PointsStatBar totalPoints={totalPoints} availablePoints={availablePoints} />

            <div className="points-table-container">
                    <div className="points-options-container">
                        <button className={tableFilter === "earned" ? "pro-active" : ""} onClick={() => switchTableFilter("earned")}>
                            <span>Earned</span>
                            <br></br>
                            Points
                        </button>

                        <button className={tableFilter === "redeemed" ? "pro-active" : ""} onClick={() => switchTableFilter("redeemed")}>
                            <span>Redeemed</span>
                            <br></br>
                            Points
                        </button>
                    </div>

                    <InfiniteScroll dataLength={tableFilter === "earned" ? earnedPoints.length : redeemedPoints.length}>
                        <table className="points-table">
                            <thead>
                                <tr><td>
                                    <div className="points-redeem-btn-container">
                                        <button className={`points-redeem-btn ${availablePoints < 5000 ? "prb-inactive" : ""}`} onClick={redeemBtnClicked}>Redeem</button>
                                    </div> 
                                </td></tr>
                            </thead>

                            <tbody>
                                { tableFilter === "earned" && earnedPoints.map((earnedPoint, index) => (
                                    <PointsTableCell point={earnedPoint} type={"earned"} />
                                )) }

                                {tableFilter === "redeemed" && redeemedPoints.map((redeemedPoint, index) => (
                                    <PointsTableCell point={redeemedPoint} type={"redeemed"} />
                                )) }
                            </tbody>
                        </table>
                    </InfiniteScroll>

                </div>


        </div>
    )
}

export default PointsPage
