import React from 'react'
import { useState, useContext } from "react"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import Moment from "react-moment"
import AdminCaptureModal from "../AdminCaptureModal"

function AdminUsersDetailReport({ reportitem, initialSuspension, reportUserSuspendedUntil }) {
    const { token } = useContext(UserContext)

    const [captureModalType, setCaptureModalType] = useState("dismiss")
    const [showCaptureModal, setShowCaptureModal] = useState(false)

    const reportUserSuspended = new Date(reportUserSuspendedUntil) > new Date()

    const suspendClicked = () => {
        setCaptureModalType("suspend")
        setShowCaptureModal(true)
    }

    const dismissClicked = () => {
        setCaptureModalType("dismiss")
        setShowCaptureModal(true)
    }

    const captureModalClosed = () => {
        setShowCaptureModal(false)
    }

    const submitWarn = async () => {
        captureModalClosed()

        const sendData = {
            "user_id": reportitem.offender._id,
            "item_id": reportitem._id
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/reports/warn`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.report_card != null) {
                alert("User has been warned")
                window.location.reload()
            }


        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitSuspend = async (suspendInterval, reason) => {
        captureModalClosed()

        const sendData = {
            "action": {
                "reason": reason
            },
            "user_id": reportitem.offender._id,
            "item_id": reportitem._id,
            "duration": parseInt(suspendInterval)
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/reports/suspend`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.report_card != null) {
                alert("User has been suspended")
                window.location.reload()
            }
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitDismiss = async (reason) => {
        captureModalClosed()

        const sendData = {
            "action": {
                "reason": reason
            },
            "user_id": reportitem.offender._id,
            "item_id": reportitem._id
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/reports/dismiss`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.report_card != null) {
                alert("Report has been dismissed")
                window.location.reload()
            }
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const unsuspendUser = async () => {
        const sendData = {
            "user_id": reportitem.offender._id
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/reports/activate`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.report_card != null) {
                alert("User has been activated")
                window.location.reload()
            }
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getReportCategory = (category) => {
        switch(category) {
            case "explicit":
                return "Explicit Content"
            case "violence":
                return "Violence"
            case "harasment":
                return "Harassment"
            case "hate_speech":
                return "Hate Speech"
            case "copyright":
                return "Copyright Infringement"
            case "self_harm":
                return "Self Harm"
            default: 
                return ""
        }
    }

    return (
        <div className="admin-users-detail-report">
            <div className="admin-users-detail-report-labels">
                { reportitem.status === "open" && <label><span>Reported: </span><Moment format={"MM/DD/YY hh:mma"}>{reportitem.createdAt}</Moment> by @{reportitem.victim.username}</label> }
                
                { reportitem.status != "open" && <label><span>{reportitem.status}: </span><Moment format={"MM/DD/YY hh:mma"}>{reportitem.updatedAt}</Moment> by @{reportitem.action.admin.username}</label> }
                { (reportitem.status != "open" && reportitem.status != "warned") && <label><span>{reportitem.status} Reason: </span>{reportitem.action.reason}</label> }

                { (initialSuspension && reportUserSuspended) && <label><span>Suspended Until: </span><Moment format={"MM/DD/YY @ hh:mma"}>{reportUserSuspendedUntil}</Moment></label> }
                { reportitem.status != "open" && <br></br> }

                <label><span>Report Reason: </span>{getReportCategory(reportitem.category)} - {reportitem.reason}</label>
            </div>

            <div className="admin-users-detail-report-buttons">
                { reportitem.status === "open" &&
                    <div>
                        <button type="button" style={{backgroundColor: "#f0d32d"}} onClick={submitWarn}>Warn</button>
                        <button type="button" style={{backgroundColor: "#bd1b11"}} onClick={suspendClicked}>Suspend</button>
                        <button type="button" style={{backgroundColor: "#38b23a"}} onClick={dismissClicked}>Dismiss</button>
                    </div>
                }

                { (initialSuspension && reportUserSuspended) &&
                    <div>
                        <button type="button" style={{backgroundColor: "#38b23a"}} onClick={unsuspendUser}>Activate</button>
                    </div>
                }
            </div>

            { showCaptureModal && 
                <AdminCaptureModal closeAction={captureModalClosed} customAction={captureModalType === "suspend" ? submitSuspend : submitDismiss} type={captureModalType} />
            }

        </div>
    )
}

export default AdminUsersDetailReport
