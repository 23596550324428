import React from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import axios from "axios"
import { UserContext } from "../components/Utils/context"
import { trackPromise } from 'react-promise-tracker';

import SearchGrid from "../components/SearchPage/SearchGrid"
import SearchBar from "../components/SearchPage/SearchBar"
import SearchResult from "../components/SearchPage/SearchResult"
import InfiniteScroll from "react-infinite-scroll-component"
import RefreshContent from "../components/Shared/RefreshContent"

import "../components/Styles/searchPage.scss"

function SearchPage() {
    const { token } = useContext(UserContext)
    const [searchView, setSearchView] = useState("grid")

    const searchBarRef = useRef()

    const [page, setPage] = useState(1)
    const pageLimit = 25
    const [hasMorePages, setHasMorePages] = useState(false)

    const [postImages, setPostImages] = useState([])
    const [searchResults, setSearchResults] = useState([])
    
    useEffect(() => {
       getDiscoveryFeed(false);
    }, [])

    const getDiscoveryFeed = async (refresh) => {

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/posts?feed_type=discovery&page=${!refresh ? page : 1}&limit=${pageLimit}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.posts != null) {
                const newPosts = response.data.posts.docs

                if (newPosts != null) {
                    let images = formatPostImagesData(newPosts)
                    

                    const hasNextPage = response.data.posts.hasNextPage
                    const nextPage = response.data.posts.nextPage
                
                    if (page === 1 || refresh) {
                        setPostImages(images)
                    }else{
                        setPostImages(postImages.concat(images))
                    }
                    
    
                    if (hasNextPage != null) {
                        
                        setPage(nextPage)
                        setHasMorePages(hasNextPage)
                    }
                }
            }
        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const refreshDiscoveryFeed = () => {
        getDiscoveryFeed(true)
    }

    const getSearchResults = async (searchString) => {

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_API}/users?q=${searchString}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            )

            if (response.data.users) {
                setSearchResults(response.data.users)
                if (searchView != "results") {
                    setSearchView("results")
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const formatPostImagesData = (data) => {
        let images = []
        data.map((post, i) => {
            images.push({
                src: post.media_url,
                thumbnail: post.media_url,
                postid: post._id
            })
        })

        return images
    }

    const presentSearchView = (type) => {
        if (type === "grid") {
            searchBarRef.current.value = ""
            setSearchResults([])
        }
        setSearchView(type)
    }

    return (
        <div className="search-container">
            <div className={`${searchView === "results" ? "search-bar-results" : ""} search-bar-container`}>
                <SearchBar presentSearchView={presentSearchView} getSearchResults={getSearchResults} searchBarRef={searchBarRef} />
                { searchView === "results" && <button type="button" onClick={() => {presentSearchView("grid")}}>Cancel</button> }
            </div>

            { searchView === "grid" &&
                <InfiniteScroll dataLength={postImages.length} next={getDiscoveryFeed} hasMore={hasMorePages} pullDownToRefresh refreshFunction={refreshDiscoveryFeed} pullDownToRefreshThreshold={80} pullDownToRefreshContent={<RefreshContent type={"pull"} />} releaseToRefreshContent={<RefreshContent type={"release"} />}>
                    <SearchGrid postImages={postImages} />
                </InfiniteScroll>
            }
            
            { searchView === "results" &&
                <InfiniteScroll dataLength={searchResults.length}>
                    <SearchResult searchResults={searchResults} /> 
                </InfiniteScroll>
            }
        </div>
    )
}

export default SearchPage
