import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory, useParams } from "react-router-dom"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import SubmitBtn from "../../Shared/SubmitBtn"

import "../../Styles/adminOneHitterForm.scss"

function AdminOneHitterForm({ mode }) {
    let history = useHistory()
    const { token } = useContext(UserContext)
    const { onehitterid } = useParams()

    const [stripeIDs, setStripeIDs] = useState([])
    
    const [oneHitterTitle, setOneHitterTitle] = useState("")
    const [oneHitterFileName, setOneHitterFileName] = useState("")
    const [oneHitterDescription, setOneHitterDescription] = useState("")
    const [oneHitterForSale, setOneHitterForSale] = useState(null)
    const [oneHitterStripeID, setOneHitterStripeID] = useState(null)

    const createMode = mode === "create"
    const editMode = mode === "edit"

    useEffect(() => {

        if (editMode && token !== null && onehitterid !== null) {
            getOneHitter()
        }
        
        if (token !== null) {
            getStripeIDs()
        }

    }, [token])

    const getOneHitter = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/one-hitters/${onehitterid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.bubble !== null) {
                setOneHitterTitle(response.data.bubble.name)
                setOneHitterDescription(response.data.bubble.description)
                setOneHitterForSale(response.data.bubble.available === true ? "true" : "false")
                setOneHitterStripeID(response.data.bubble.stripe_id)

                const fileName = response.data.bubble.image.replace("one-hitters/", "")
                setOneHitterFileName(fileName)

            }



        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getStripeIDs = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/subscriptions/stripe?type=one_time`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.subscriptions != null) {
                setStripeIDs(response.data.subscriptions)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const createOneHitter = async () => {

        const sendData = {
            "bubble": {
                "name": oneHitterTitle,
                "image": `one-hitters/${oneHitterFileName}`,
                "description": oneHitterDescription,
                "available": oneHitterForSale === "true" ? true : false,
                "stripe_id": oneHitterStripeID
            }
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/one-hitters`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.bubble !== null) {
                history.push("/admin/one-hitters")
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const editOneHitter = async () => {

        const sendData = {
            "bubble": {
                "name": oneHitterTitle,
                "image": `one-hitters/${oneHitterFileName}`,
                "description": oneHitterDescription,
                "available": oneHitterForSale === "true" ? true : false,
                "stripe_id": oneHitterStripeID
            }
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/one-hitters/${onehitterid}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.bubble !== null) {
                history.push("/admin/one-hitters")
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitOneHitterForm = (e) => {
        e.preventDefault()

        if (oneHitterTitle.trim().length === 0) {
            alert("One Hitter Title cannot be empty")
            return false
        }

        if (oneHitterFileName.trim().length === 0) {
            alert("One Hitter File Name cannot be empty")
            return false
        }

        if (oneHitterDescription.trim().length === 0) {
            alert("One Hitter Description cannot be empty")
            return false
        }

        if (oneHitterForSale === null || oneHitterForSale.length === 0) {
            alert("For Sale is required")
            return false
        }

        if (oneHitterStripeID === null || oneHitterStripeID.length === 0) {
            alert("Stripe ID is required")
            return false
        }

        if (createMode) {
            createOneHitter()
        }

        if (editMode) {
            editOneHitter()
        }

        return true
    }

    return (
        <div>
            <form className="admin-one-hitter-form" onSubmit={submitOneHitterForm}>

                <div>
                    <label>Title:</label>
                    <input value={oneHitterTitle} placeholder="Enter new name" onChange={(e) => setOneHitterTitle(e.target.value)}></input>
                </div>

                <div>
                    <label>File Name:</label>
                    <input value={oneHitterFileName} placeholder="Enter the file name" onChange={(e) => setOneHitterFileName(e.target.value)}></input>
                </div>

                <div>
                    <label>Description:</label>
                    <textarea value={oneHitterDescription} placeholder="Enter description" onChange={(e) => setOneHitterDescription(e.target.value)}></textarea>
                </div>

                <div>
                    <label>For Sale:</label>
                    <select onChange={(e) => setOneHitterForSale(e.target.value)}>
                        <option value="">Please select yes or no</option>
                        <option value="true" selected={oneHitterForSale === "true"}>yes</option>
                        <option value="false" selected={oneHitterForSale === "false"}>no</option>
                    </select>
                </div>

                <div>
                    <label>Stripe ID:</label>
                    <select onChange={(e) => setOneHitterStripeID(e.target.value)}>
                        <option value="">Please select a stripe ID</option>

                        { stripeIDs.map((stripeKey, index) => (
                            <option value={`${stripeKey.stripe_sub_id}`} selected={`${stripeKey.stripe_sub_id}` === oneHitterStripeID}>{stripeKey.name}</option>
                        )) }
                    </select>
                </div>

                <div className="mar-vert-24">
                    <SubmitBtn text={"Save"} height={38} width={72} />
                </div>

            </form>
        </div>
    )
}

export default AdminOneHitterForm
