import React from 'react'
import { useState, useEffect, useContext } from "react"
import axios from "axios"
import { useParams, useHistory } from "react-router-dom"
import { UserContext } from "../components/Utils/context"
import { trackPromise } from "react-promise-tracker"
import SearchGrid from '../components/SearchPage/SearchGrid'
import InfiniteScroll from "react-infinite-scroll-component"
import RefreshContent from "../components/Shared/RefreshContent"

import "../components/Styles/profileDynamicPage.scss"


function ProfilePostPage() {
    let history = useHistory()
    
    const propUsername = (history.location &&
    history.location.state &&
    history.location.state.username) ? history.location.state.username : null;

    const { token } = useContext(UserContext)
    const { userid } = useParams()

    const [page, setPage] = useState(1)
    const pageLimit = 25
    const [hasMorePages, setHasMorePages] = useState(false)

    const [postUsername, setPostUsername] = useState(null)
    const [postCount, setPostCount] = useState(null)
    const [postImages, setPostImages] = useState([])

    useEffect(() => {
        if (token != null) {
            retrieveUserPosts(false)
        }
    }, [token])

    const retrieveUserPosts = async (refresh) => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/posts?feed_type=user&user_id=${userid}&page=${!refresh ? page : 1}&limit=${pageLimit}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.posts != null) {
                const newPosts = response.data.posts.docs
                setPostCount(response.data.posts.totalDocs)

                if (newPosts != null) {
                    let images = formatPostImagesData(newPosts)
                    

                    const hasNextPage = response.data.posts.hasNextPage
                    const nextPage = response.data.posts.nextPage
                
                    if (page === 1 || refresh) {
                        setPostImages(images)
                    }else{
                        setPostImages(postImages.concat(images))
                    }
                    
    
                    if (hasNextPage != null) {
                        
                        setPage(nextPage)
                        setHasMorePages(hasNextPage)
                    }

                    const firstPost = response.data.posts.docs[0]
                    if (firstPost != null) {
                        if (firstPost.author != null && firstPost.author.username != null) {
                            setPostUsername(`${firstPost.author.username}`)
                        }
                    }
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const refreshUserPosts = () => {
        retrieveUserPosts(userid, true)
    }

    const formatPostImagesData = (data) => {
        let images = []
        data.map((post, i) => {
            images.push({
                src: post.media_url,
                thumbnail: post.media_url,
                postid: post._id
            })
        })
        
        return images
    }

    return (
        <div className="profile-posts-page-container">
            <div>
                <div className="profile-relations-username-container">
                    {propUsername != null && `@${propUsername}`}
                    {propUsername === null &&  `@${postUsername}`}
                </div>
                <div className="profile-relations-options-container">
                    <button className="pro-single pro-active">
                        <span>{postCount != null && `${postCount}`}</span>
                        <br></br>
                        Posts
                    </button>
                </div>
            </div>
            <InfiniteScroll dataLength={postImages.length} next={retrieveUserPosts} hasMore={hasMorePages} pullDownToRefresh refreshFunction={refreshUserPosts} pullDownToRefreshThreshold={80} pullDownToRefreshContent={<RefreshContent type={"pull"} />} releaseToRefreshContent={<RefreshContent type={"release"} />}>
                <div>
                    <SearchGrid postImages={postImages} />
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default ProfilePostPage
