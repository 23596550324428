import React from 'react'
import { Helmet } from "react-helmet"
import Logo from "../components/Shared/Logo"

import "../components/Styles/termsPage.scss"

function TermsPage() {
    return (
        <div className="terms-page-container">
            <Helmet>
                <title>Do Better - Terms / AUP / Privacy </title>
                <meta name="description" content="Do Better Social Terms of Service, Acceptable Use Policy and Privacy Policy. Let's do better." />
            </Helmet>

            <Logo size={"med"}/>

            <label>{"Terms & Conditions"}</label>

            <p>
            These Terms of Service (this <span>“Agreement”</span>) constitute a legally binding contract between Redemption
            Software LLC dba DoBetter.Social, a Colorado Limited Liability Company (<span>“DoBetter,” “we,” “us,” “our”</span>)
            and you with respect to your use of our websites, applications, Additional Features (as defined in
            Section 17), and other products, services, and platforms, including our website accessible at
            DoBetter.Social (collectively, the “Service(s)”), including your submission of any User Content (as
            defined below) to the Service.
            <br></br>
            <br></br>
            BY ACCESSING OR USING THE SERVICE, REGISTERING AN ACCOUNT, OR BY OTHERWISE INDICATING
            YOUR ASSENT TO THIS AGREEMENT. BY CLICKING “I ACCEPT,” OR ANY SIMILAR MECHANISM, YOU ARE
            CONSENTING TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT.
            <span>
                {" "}IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE
                SERVICE OR CREATE AN ACCOUNT.
            </span>
            <br></br>
            <br></br>
            IF YOU ACCESS OR USE THE SERVICE OR REGISTER AN ACCOUNT ON BEHALF OF A COMPANY, PRINCIPAL,
            OR OTHER ENTITY, YOU REPRESENT THAT YOU HAVE AUTHORITY TO BIND SUCH ENTITY AND ITS
            AFFILIATES TO THIS AGREEMENT AND THAT IT IS FULLY BINDING ON THEM. IN SUCH CASE, THE TERM
            “YOU,” AS USED IN THIS AGREEMENT, WILL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT
            HAVE AUTHORITY, YOU MAY NOT ACCESS OR USE THE SERVICE OR CREATE AN ACCOUNT.
            <br></br>
            <br></br>
            BE ADVISED THAT THIS AGREEMENT CONTAINS DISCLAIMERS OF WARRANTIES AND LIMITATIONS ON
            LIABILITY THAT MAY BE APPLICABLE TO YOU.
            <br></br>
            <br></br>
            <span>
                Notice Regarding Dispute Resolution: This Agreement contains provisions that govern how claims you
                and DoBetter have against each other are resolved (see Section 11 (Limitation of Liability), Section 15
                (Dispute resolution and arbitration: MANDATORY BINDING INDIVIDUAL ARBITRATION INSTEAD OF
                COURT; CLASS ACTION WAIVER), and Section 16 (Choice of Law and Forum) below). It also contains an
                agreement to arbitrate, which will, with limited exception, require you to submit claims you have
                against us to binding and final arbitration, unless you opt-out of the agreement to arbitrate in
                accordance with Section 15(J). Unless you opt-out: (A) you will only be permitted to pursue claims
                against DoBetter on an individual basis, not as part of any class or representative action or
                proceeding, and (B) you will be permitted only to seek relief (including without limitation monetary,
                injunctive, and declaratory relief) on an individual basis.
            </span>
            <br></br>
            <br></br>
            <span>1. Changes to this Agreement.</span>
            <br></br>
            <br></br>
            We reserve the right to, at any time, with or without cause:
            <br></br>
            <br></br>
            <ul>
                • change the terms and conditions of this Agreement;
                <br></br>
                <br></br>
                • change the Service, including eliminating or discontinuing any product, service or other feature
                of the Service or using different URL(s) to provide the Service; and
                <br></br>
                <br></br>
                • deny or terminate your use of and/or access to all or any portion of the Service.
            </ul>
            <br></br>
            <br></br>
            We will attempt to notify you of any material changes to the terms and conditions of this Agreement.
            You must accept all changes in order to continue to use and access the Service. If you do not object to
            these changes within thirty (30) days or you continue to access and use the Service after the effective
            date of these changes, you will be deemed to have accepted these changes. If you do not accept such
            changes, your use and/or access to the Service will be terminated.
            <br></br>
            <br></br>
            <span>2. Use of the Service; Fees.</span>
            <br></br>
            <br></br>
            Before using the Service, you may need to register with us and create an account to access such portions
            of the Service (an <span>“Account”</span>). We reserve the right to decline to provide the Service to any person, or to
            restrict access to any portion of the Service, for any or no reason. When you create an account, you
            agree to provide to us with information that we request for verification purposes or detecting whether
            you are accessing the Service for any inappropriate purposes; however, we have no obligation to
            conduct any such verification or detection activities. If and when you register with or provide
            information to DoBetter, you agree to: (A) provide accurate, current, and complete information as
            prompted (including without limitation your contact information), and (B) maintain and update your
            information to keep it accurate, current, and complete. By providing DoBetter with your email address
            or other contact information, you consent to our use of this information to send you Service-related
            notices and other administrative notices, including without limitation any notices required by any
            applicable law, statute, rule, ordinance, code, guideline, order or regulation of any government or quasi-
            government authority (<span>“Applicable Law”</span>).
            <br></br>
            <br></br>
            You are solely responsible for the activity that occurs on your Account. You must keep your Account
            credentials (including username and password) and any other access information secure at all times.
            You may not share your Account with any third party without DoBetter’s prior written consent. You
            must notify DoBetter immediately of any breach or suspected breach of security or unauthorized use of
            your Account. DoBetter will not be liable for any losses caused by any unauthorized use of your Account.
            You agree to pay all fees, and other charges you incur in connection with your Account.
            <br></br>
            <br></br>
            You represent and warrant that you have the full right, power, and authority to enter into and perform
            this Agreement without the consent or approval of any third party.
            <br></br>
            <br></br>
            <span>3. Rules Governing Your Use of the Service.</span>
            <br></br>
            <br></br>
            You may not use the Service, or assist or encourage any other party to use the Service, to engage in any
            activity that DoBetter deems objectionable, including any of the following prohibited activities:
            <br></br>
            <br></br>
            <ul>
                • Copying, framing or mirroring any part of the Service;
                <br></br>
                <br></br>
                • Accessing the Service for purposes of monitoring its availability, performance, or functionality;
                <br></br>
                <br></br>
                • Using, copying, modifying, creating a derivative work of, reverse engineering, decompiling or
                otherwise attempting to extract the source code of the software underlying the Service or any
                part thereof, except to the extent expressly permitted or required by Applicable Law;
                <br></br>
                <br></br>
                • Using the Service to build or benchmark a competitive product, software or services or copying
                any features, functions, graphic or content on the Service (including any User Content);
                <br></br>
                <br></br>
                • Attempting to disrupt, degrade, impair or violate the integrity or security of the Service or the
                computers, services, accounts or networks of any other party (including “hacking,” “denial of
                service” attacks, etc.), including any activity that typically precedes attempts to breach security
                such as scanning, probing or other testing or vulnerability assessment activity or engaging in or
                permitting any network or hosting activity that results in the blacklisting or other blockage of
                DoBetter internet protocol space;
                <br></br>
                <br></br>
                • Distributing or disclosing any part of the Service in any medium, including by any automated or
                non-automated “scraping”;
                <br></br>
                <br></br>
                • Using any automated system, including “robots,” “spiders,” “offline readers,” etc., to access the
                Service in a manner that sends more request messages to DoBetter than a human can
                reasonably produce in the same period of time by using a conventional web browser;
                <br></br>
                <br></br>
                • Taking any action that imposes, or may impose, as we determine in our sole discretion, an
                unreasonable or disproportionately large load on our infrastructure;
                <br></br>
                <br></br>
                • Accessing any content on the Service through any technology or means other than those
                provided or authorized by the Service;
                <br></br>
                <br></br>
                • Submitting to the Service or to DoBetter any personally identifiable information or any other
                information that may be protected from disclosure by Applicable Law, except as necessary for
                the establishment and operation of your Account;
                <br></br>
                <br></br>
                • Bypassing the measures we may use to prevent or restrict access to the Service, including
                features that prevent or restrict use or copying of any content or enforce limitations on use of
                the Service or the content therein;
                <br></br>
                <br></br>
                • Violating any Applicable Law or encouraging any conduct that could lead to such a violation,
                constitute a criminal offense, or give rise to civil liability;
                <br></br>
                <br></br>
                • Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make
                available the Service, or any features or functionality of the Service, to any third party for any
                reason, including by making the Service available on a network where it is capable of being
                accessed by more than one device at any time;
                <br></br>
                <br></br>
                • Removing any copyright, trademark or other proprietary or intellectual property right notices
                contained in or on the Service; and/or
                <br></br>
                <br></br>
                • Executing any form of network monitoring or running a network analyzer or packet sniffer or
                other technology to intercept, decode, mine or display any packets used to communicate
                between the Service’s servers or any data not intended for you.

            </ul>
            <br></br>
            <br></br>
            Improper use of the Services may result in civil or criminal liabilities to you or the termination of access by us to the Services.
            <br></br>
            <br></br>
            <span>4. Intellectual Property.</span>
            <br></br>
            <br></br>
            A. <u>Your Rights.</u> Subject to the terms and conditions of this Agreement, you are hereby granted a non-
            exclusive, non-transferable, non-sublicensable, and revocable license to access and use the Service
            solely for your own internal use. DoBetter reserves all rights not expressly granted herein in the Service.
            Except as expressly set forth herein, no rights or licenses are granted to you under this Agreement,
            whether by implication, estoppel or otherwise.
            <br></br>
            <br></br>
            B. <u>DoBetter Rights.</u>
            <br></br>
            <br></br>
            <ul>
                • <span>User Content License.</span> You expressly grant, and you represent and warrant that you have all
                rights necessary to grant, to DoBetter a royalty-free, fully paid-up, fully sublicensable (through
                multiple tiers of sublicensees), fully transferable, perpetual, irrevocable, non-exclusive,
                worldwide license to use, distribute, modify, reproduce, publicly display, publicly perform and
                create derivative works of all information, data, materials, and content you provide to DoBetter
                (collectively, “User Content”), and DoBetter will be free to use and exploit such User Content
                without obligation or liability to you, financial or otherwise, for the purposes of (i) providing the
                Service, (ii) marketing, selling or otherwise providing products and services using the Service, or
                (iii) developing, maintaining, supporting or improving the Service. You agree that all User
                Content is non-confidential and may be used, collected, stored, shared, or otherwise exploited
                by us or on our behalf (except as expressly set forth in our Privacy Policy (as defined below),
                including in order to provide the Service. DoBetter aggregates User Content with other data and
                also collects technical information and data about your use of the Service. You expressly agree
                that DoBetter may use any aggregated and anonymized data for any purpose during or after the
                term of this Agreement, including to develop and improve the Service or otherwise develop and
                improve DoBetter services and products.
            </ul>
            <br></br>
            <br></br>
            C. <u>Proprietary Rights.</u> The Service is owned and operated by DoBetter, and the Service (and all
            intellectual property and other rights relating thereto) is and will remain the property of DoBetter. The
            Service is protected by U.S. and international copyright, trademark and other Applicable Laws, and you
            acknowledge that these rights are valid and enforceable. The Service may be used by you solely to the
            extent expressly permitted in this Agreement. Use of the Service by you for any other purpose is strictly
            prohibited. You acknowledge that you do not acquire any ownership rights by using the Service. The
            trademarks, trade names, service marks, brands, logos, and trade dress displayed on the Service
            (collectively, the <span>“DoBetter Trademarks”</span>) are the registered and unregistered intellectual and
            proprietary property of DoBetter and/or others. Nothing contained in this Agreement or the Service
            should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any
            DoBetter Trademark(s) without the express written permission of DoBetter or the third-party owner of
            any such DoBetter Trademark.
            <br></br>
            <br></br>
            D. <u>User Content.</u> You are solely responsible for any User Content that you submit, post, or display on or
            via the Service. Without limitation to the restrictions set forth in Section 3, you represent and warrant
            that: (i) you own the User Content or otherwise have the right to grant the rights and licenses set forth
            in this Agreement; (ii) the use of your User Content does not and will not violate, misappropriate or
            infringe on the rights of any third party, including privacy rights, publicity rights, copyrights, trademark
            and/or other intellectual property rights; (iii) the User Content does and will comply with Applicable
            Law. You acknowledge and agree that your relationship with DoBetter is not a confidential, fiduciary, or
            other type of special relationship, and that your decision to submit any User Content does not place
            DoBetter in a position that is any different from the position held by members of the general public,
            including with regard to your User Content.
            <br></br>
            <br></br>
            E. <u>Reporting Copyright and Other IP Violations.</u> The Digital Millennium Copyright Act (the “DMCA”)
            provides recourse for copyright owners who believe that material appearing on the Internet infringes
            their rights under U.S. copyright law. DoBetter also reviews claims of trademark infringement. If you
            believe in good faith that materials hosted by DoBetter infringe your copyright or trademark rights, you
            (or your agent) may send us a notice requesting that the material be removed, or access to it be
            blocked. The notice must include the following information: (a) a physical or electronic signature of a
            person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (b)
            identification of the copyrighted work or trademark claimed to have been infringed on (or, if multiple
            copyrighted works or trademarks located on the Service are covered by a single notification, a
            representative list of such works); (c) identification of the material that is claimed to be infringing or the
            subject of infringing activity, and information reasonably sufficient to allow DoBetter to locate the
            material on the Service; (d) the name, address, telephone number, and e-mail address (if available) of
            the complaining party; (e) a statement that the complaining party has a good-faith belief that use of the
            material in the manner complained of is not authorized by the copyright or trademark owner, its agent
            or the law; and (f) a statement that the information in the notification is accurate and, under penalty of
            perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed. Please be advised that DoBetter will not respond to complaints that do not meet
            these requirements. If DoBetter determines that the materials alleged to infringe your copyright or
            trademark rights do not require removal, DoBetter will remove those materials only pursuant to a court
            order declaring the content or use of the materials unlawful.
            <br></br>
            <br></br>
            If you believe in good faith that a notice of copyright infringement has been wrongly filed against you,
            the DMCA permits you to send us a counter-notice. Counter-notices must include the following
            information: (a) your name, address, and telephone number; (b) the source of the content that was
            removed; (c) a statement under penalty of perjury that you have a good-faith belief that the content
            was removed in error; (d) a statement that you consent to the jurisdiction of Federal District Court for
            the judicial district in which your address is located; or, if your address is outside of the United States,
            for any judicial district in which DoBetter may be found, and that you will accept service of process from
            the person who provided the original complaint; and (e) a physical or electronic signature (for example,
            typing your full name).
            <br></br>
            <br></br>
            Notices and counter-notices with respect to the Service must meet the then-current statutory
            requirements imposed by the DMCA (see http://copyright.gov/title17/92appb.html) and should be sent
            to the agent identified below. We suggest that you consult your legal advisor before filing a notice or
            counter-notice. Also, be aware that there can be penalties for false claims under the DMCA.
            <br></br>
            <br></br>
            Agent to Receive Notices of Claimed Infringement:
            <br></br>
            <br></br>
            [Mitch Lane]
            <br></br>
            [PO Box 205, Louviers, CO 80131]
            <br></br>
            [support@dobetter.social]
            <br></br>
            [303-905-5912]
            <br></br>
            <br></br>
            <span>5. Third-Party Links.</span>
            <br></br>
            <br></br>
            The Service may provide you with access to third party websites, databases, networks, servers,
            information, software, programs, systems, directories, applications, products or services, including
            without limitation, other websites, apps and/or platforms to which User Content has been shared or
            embedded using the Service (hereinafter <span>“External Services”</span>).
            <br></br>
            <br></br>
            DoBetter does not have or maintain any control over the External Services and is not and cannot be
            responsible for their content, operation or use. By linking or otherwise providing access to any External
            Services, DoBetter does not give any representation, warranty or endorsement, express or implied, with
            respect to the legality, accuracy, quality or authenticity of content, information or services provided by
            such External Services.
            <br></br>
            <br></br>
            External Services may have their own terms of use and/or privacy policy and may have different
            practices and requirements than DoBetter with respect to the Service. You are solely responsible for
            reviewing any terms of use, privacy policy or other terms governing your use of these External Services,
            which you use at your own risk. You are advised to make reasonable inquiries before entering into any
            transaction, financial or otherwise, and whether online or offline, with any third party related to any
            External Services.
            <br></br>
            <br></br>
            When using External Services, you are solely responsible for protecting yourself from fraud and for
            protecting your computer systems from viruses, worms, Trojan horses, and other harmful or destructive
            content and material that may be included on or may emanate from any External Services.
            <br></br>
            <br></br>
            DoBetter disclaims any and all responsibility or liability for any harm resulting from your use of External
            Services, and you hereby irrevocably waive any claim against DoBetter with respect to the content or
            operation of any External Service.
            <br></br>
            <br></br>
            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS:
            “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
            SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM
            MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR”.
            <br></br>
            <br></br>
            <br></br>
            <span>6. Competency.</span>
            <br></br>
            <br></br>
            You hereby affirm that you are at least 18 years old and are fully able and competent to enter into the
            terms, conditions, obligations, affirmations, representations and warranties set forth in this Agreement
            and to abide by and comply with this Agreement.
            <br></br>
            <br></br>
            <span>7. Privacy Policy.</span>
            <br></br>
            <br></br>
            Your use of the Service shall be subject to DoBetter’s Privacy Policy (the <span>“Privacy Policy”</span>), which can be
            accessed at dobetter.social. You expressly consent to the practices described in the Privacy Policy.
            DoBetter reserves the right to modify the Privacy Policy in its discretion from time to time, with or
            without prior notice. Access to or use of the Service after any such changes shall constitute your
            agreement to such changes.
            <br></br>
            <br></br>
            <span>8. Acceptable Use Policy.</span>
            <br></br>
            <br></br>
            Your use of the Service shall be subject to DoBetter’s Acceptable Use Policy (the <span>“AUP”</span>), which can be
            accessed at: dobetter.social. You expressly consent to comply with the terms of the AUP as incorporated
            herein by reference. DoBetter reserves the right to modify the AUP in its discretion from time to time,
            with or without prior notice. Access to or use of the Service after any such changes shall constitute your
            agreement to such changes.
            <br></br>
            <br></br>
            <span>9. Term & Termination.</span>
            <br></br>
            <br></br>
            This Agreement is effective from the date on which you first access the Service or submit any User
            Content to DoBetter, whichever is earlier, and shall remain effective until terminated in accordance with
            its terms. DoBetter may immediately terminate this Agreement, and/or your access to and/or use of the
            Service, or any portion thereof, at any time and for any reason, with or without cause, without prior
            notice. DoBetter may also terminate this Agreement immediately if you fail to comply with any term or
            provision of this Agreement. Upon termination of this Agreement, your right to access and use the
            Service shall immediately cease and you shall cease all access to and use of the Service. In the event of
            termination of this Agreement for any reason, Sections 1, 3, 4(B)-(E), and 5–18 of this Agreement shall
            survive, except that you shall no longer have any right to access or use the Service.
            <br></br>
            <br></br>
            We reserve the right at any time and from time to time to modify or discontinue, temporarily or
            permanently, the Service, or any part or portion thereof, with or without notice to you. You agree that
            we shall not be liable to you or to any third party for any modification, suspension or discontinuance of
            the Service, or any part or portion thereof. Nothing in this Agreement shall be construed to obligate
            DoBetter to maintain and support the Service, or any part or portion thereof, during the term of this
            Agreement.
            <br></br>
            <br></br>
            <span>10. DoBetter Not Party to Transactions; Disclaimers.</span>
            <br></br>
            <br></br>
            DoBetter strongly urges all users to be responsible about their use of the Service and any transaction
            entered into as a result of references or connections you make or receive from using the Service.
            DoBetter is not a party to any agreement between or among users of the Service. As a result, verifying
            the truth or accuracy of information provided by any users, including with respect to any such users’
            financial performance, and the consummation of any actual or potential transaction between or among
            users, is solely your responsibility.
            <br></br>
            <br></br>
            While we do take certain measures with a goal to assist users to avoid potentially fraudulent or other
            inappropriate activity of which we become aware, such as identity verification measures, and other
            know-your-customer measures, we assume no liability or obligation to take any such measures or
            actions. We do not warrant that such measures or actions will reach any or all users in a timely manner
            or at all or that such measures or actions will prevent any harm or otherwise have any impact.
            <br></br>
            <br></br>
            User verification on the Internet is difficult and we cannot and do not assume any responsibility for the
            confirmation of each user’s purported identity, performance, or other information. We encourage you
            to communicate directly with other users through the tools available on the Service, though even this
            does not assure you of the truthfulness or accuracy of the information provided by the user with whom
            you are communicating. We further encourage you to, and you are solely responsible for, taking other
            reasonable measures to assure yourself of the other person’s identity and to verify all information
            provided by any user of the Service.
            <br></br>
            <br></br>
            THE SERVICE IS PROVIDED “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS” AND WITHOUT
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, COMPLETENESS,
            QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ANY WARRANTIES THAT MAY
            ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE, AND ANY
            WARRANTIES THAT THE SERVICE IS CURRENT AND/OR UP-TO-DATE, ALL OF WHICH ARE HEREBY
            EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW.
            <br></br>
            <br></br>
            THERE IS NO WARRANTY, REPRESENTATION, OR GUARANTEE THAT THE SERVICE, OR YOUR USE OF THE
            SERVICE, OR ANY INFORMATION, MATERIAL, OR CONTENT ACCESSIBLE USING THE SERVICE, WILL BE
            UNINTERRUPTED, COMPLETE, ACCURATE, CURRENT, RELIABLE, ERROR-FREE, SECURE, OR THAT ANY
            PROBLEMS WILL BE CORRECTED, OR THAT THE SERVICE, OR ANY INFORMATION, SOFTWARE, OR OTHER
            MATERIAL ACCESSIBLE FROM THE SERVICE, IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE
            DO NOT WARRANT, GUARANTEE, OR MAKE ANY REPRESENTATION REGARDING THE USE OF, OR THE
            RESULTS OF THE USE OF, THE SERVICE AND YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE
            OF THE SERVICE AND YOUR RELIANCE THEREON.
            <br></br>
            <br></br>
            <span>11. Limitation of Liability.</span>
            <br></br>
            <br></br>
            NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY OF ANY KIND,
            NEITHER DOBETTER NOR ANY OF ITS AGENTS, SUCCESSORS, OR ASSIGNS, NOR OUR OR THEIR
            DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER REPRESENTATIVES, ARE RESPONSIBLE OR
            LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER INDIRECT
            DAMAGES (INCLUDING ANY LOSS OF PROFITS, LOST SAVINGS, OR LOSS OF DATA) UNDER ANY
            CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY
            MANNER TO THE SERVICE OR ANY LINKED WEBSITE, WHETHER OR NOT WE HAVE BEEN INFORMED OF
            THE POSSIBILITY OF SUCH DAMAGES OR LIABILITIES. DOBETTER’S MAXIMUM AGGREGATE LIABILITY TO
            YOU SHALL NOT EXCEED TEN DOLLARS ($10).
            <br></br>
            <br></br>
            SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES AND/OR
            LIABILITIES, SO CERTAIN OF THE LIMITATIONS OR EXCLUSIONS IN THIS AGREEMENT MAY NOT APPLY TO
            YOU.
            <span>12. Indemnification.</span>
            <br></br>
            <br></br>
            You agree to fully indemnify, defend (at DoBetter’s request), and hold harmless DoBetter, our agents,
            successors, and assigns, and our and their directors, officers, employees, consultants and other
            representatives (collectively, the <span>“DoBetter Parties”</span>) from and against any and all claims, damages,
            losses, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly
            out of or from: (A) your actual or alleged breach of this Agreement; (B) any allegation that any User
            Content or other materials you submit to us or transmit to the Service infringe, misappropriate, or
            otherwise violate the copyright, patent, trademark, trade secret or other intellectual property or other
            right of any third party; (C) your activities in connection with the Service or any other website to which
            the Service is linked; (D) your negligence or willful misconduct; (E) your use of the results, content,
            products, services, software, data, or information provided via the Service; (F) any service or product
            offered by you in connection with or related to your use of the Service; and/or (G) your violation of any
            Applicable Law.
            <br></br>
            <br></br>
            <span>13. Jurisdictional Issues.</span>
            <br></br>
            <br></br>
            DoBetter makes no representation that the Service operates (or is legally permitted to operate) in all
            geographic areas or that the Service is appropriate or available for use in other locations. Accessing the
            Service from territories where the Service or any content or functionality of the Service or portion
            thereof is illegal is expressly prohibited. If you choose to access the Service, you agree and acknowledge
            that you do so on your own initiative and at your own risk and that you are solely responsible for
            compliance with all Applicable Laws. The Service is operated from the United States and is intended to
            be utilized by persons in the United States. If you are located outside of the United States and choose to
            use the Service or provide your User Content to us, your User Content will be transferred, processed,
            and stored in the United States. U.S. privacy laws may not be as protective as those in your jurisdiction.
            Your agreement to the terms of this Agreement or your submission of your User Content in connection
            with the Service represents your agreement to this practice. If you do not want your User Content
            transferred to or processed or stored in the United States, you should not use the Service.
            <br></br>
            <br></br>
            <span>14. Notice for California Users.</span>
            <br></br>
            <br></br>
            Under California Civil Code Section 1789.3, California Service users are entitled to the following specific
            consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
            California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite N
            112, Sacramento, California 95834, or by telephone at (800) 952-5210.
            <br></br>
            <br></br>
            <span>15. Dispute resolution and arbitration: MANDATORY BINDING INDIVIDUAL ARBITRATION INSTEAD OF
            COURT; CLASS ACTION WAIVER.</span>
            <br></br>
            <br></br>
            A. <u>Binding Arbitration.</u> You and DoBetter both agree that any and all disputes or claims arising out of or
            relating in any way to DoBetter products or services or from any advertising for any such products or
            services, including any question regarding the existence, validity, or termination of the Agreement as
            well as any issue regarding the interpretation of this Section 15, will be resolved by binding arbitration
            before a sole arbitrator (rather than in court), except that you may assert claims in small claims court if
            your claims qualify and DoBetter may pursue a collection action against you in court. This also includes
            any claims that arose before you accepted the Agreement, regardless of whether prior versions of the
            Agreement required arbitration. The Federal Arbitration Act (9 U.S.C. § 1 et seq.) and federal arbitration
            law apply to this arbitration agreement.
            <br></br>
            <br></br>
            B. <u>Neutral Arbitrator.</u> Arbitration uses a neutral arbitrator instead of a judge and jury. An arbitrator can
            award on an individual basis the same damages and relief as a court (including injunctive and
            declaratory relief or statutory damages) and must follow the terms of the Agreement as a court would.
            Arbitration procedures allow for more limited discovery, and court review of an arbitration award is
            limited.
            <br></br>
            <br></br>
            C. <u>Notice.</u> If you have a dispute and elect to seek arbitration or file a claim in small claims court, you
            must first send to DoBetter, by certified mail, a written notice of your claim that (i) describes the nature
            and basis of the claim or dispute; (ii) sets forth the specific relief sought; and (iii) includes a physical
            address and email address where you may be reached (<span>“Notice”</span>). The Notice must be addressed to:
            Redemption Software LLC dba DoBetter.Social, PO Box 205, Louviers, CO 80131 (<span>“Notice Address”</span>). You
            may download or copy a form Notice at www.adr.org. If DoBetter and you do not reach an agreement to
            resolve the claim within sixty (60) days after the Notice is received, you or DoBetter may commence an
            arbitration proceeding or file a claim in small claims court.
            <br></br>
            <br></br>
            D. <u>Initiation of Arbitration.</u> You may download or copy a form to initiate arbitration at www.adr.org.
            <br></br>
            <br></br>
            E. <u>Consumer Arbitration Rules.</u> The arbitration will be governed by the AAA’s then current Consumer
            Arbitration Rules, as modified by the terms of the Agreement, and will be administered by the AAA. The
            AAA Rules and Forms are available online at www.adr.org or by calling the AAA at 1-800-778-7879. In
            the event of any conflict between the terms of the Agreement and the AAA Consumer Arbitration Rules,
            the terms of the Agreement will apply.
            <br></br>
            <br></br>
            F. <u>Fees.</u> Each party will bear its own fees in connection with the arbitration, including the expense of its
            own counsel, experts, witnesses, and preparation and presentation of evidence at any arbitration
            hearing.
            <br></br>
            <br></br>
            G. <u>Hearings.</u> If your claim is for US$25,000 or less, you and DoBetter agree that you may choose whether
            the arbitration will be conducted solely on the basis of documents or through a telephonic hearing. You
            may also request an in-person hearing, which the arbitrator may grant at his or her discretion. If the
            arbitrator grants an in-person hearing, such hearing will be conducted either at a mutually agreed
            location or a location determined by the AAA or the arbitrator. In such an in-person hearing, the parties,
            or any of their witnesses, have the right to participate remotely by way of videoconference or some
            similar means. If your claim exceeds US$25,000, the right to a hearing will be determined by the
            arbitrator. All in-person hearings will be held in Denver, Colorado.
            <br></br>
            <br></br>
            H. <u>No Class Action.</u> You and DoBetter agree that any dispute resolution proceedings will be conducted
            only on an individual basis and not in a class, representative, consolidated, or mass action. Neither you
            nor DoBetter may join or consolidate claims by or against a third party or arbitrate or otherwise
            participate in any claim as a class representative, class member or in a private attorney general capacity.
            If this provision is found to be unenforceable, then the entirety of this Section 15 shall be null and void.
            <br></br>
            <br></br>
            I. <u>Confidentiality.</u> Any arbitration will be confidential. Neither you, DoBetter, the AAA, nor the arbitrator
            may disclose the existence, content (including any oral or written submissions), or results of any
            arbitration, except as may be required by Applicable Law or for purposes of enforcing or challenging of
            the arbitration award.
            <br></br>
            <br></br>
            J. <u>Opt-Out.</u> You may opt out of this dispute resolution procedure by providing written notice to DoBetter
            at the Notice Address no later than thirty (30) calendar days from the date of your purchase of DoBetter
            products or services (or date of first use, in the case of free products or services). Opting out of this
            dispute resolution procedure will not otherwise affect the coverage or applicability of the Agreement or
            your ability to use DoBetter products or services in any way.
            <br></br>
            <br></br>
            K. <u>Time Limitation on Claims.</u> You agree that any claim you may have arising out of or related to your
            relationship with DoBetter and this Agreement must be filed within one year after such claim arose;
            otherwise, your claim is permanently barred.
            <br></br>
            <br></br>
            <span>16. Choice of Law and Forum.</span>
            <br></br>
            <br></br>
            Without limiting Section 15 of this Agreement and your relationship with DoBetter, disputes between
            the Parties shall be governed by, and construed and interpreted in accordance with, the Federal
            Arbitration Act, applicable federal law, and the laws of the State of Colorado without regard to conflict
            of laws principles. Subject to Section 15, the parties irrevocably consent to bring any action to enforce
            this Agreement in the state courts located in Denver, Colorado and you consent to the exclusive
            jurisdiction of the state courts located in Denver, Colorado.
            <br></br>
            <br></br>
            <span>17. Additional Features License; Online Orders.</span>
            <br></br>
            <br></br>
            The Services provide you with the ability to purchase modifications to the Service that may give you
            access to additional features, graphics, or different portions of the Service (the <span>“Additional Features”</span>).
            When you purchase any such Additional Features, these Additional Features are deemed to be the
            “Service” for purposes of this Agreement and your use and access to any such Additional features will be
            subject to the terms of this Agreement, including the limited license granted to you in Section 4.
            Without limiting the foregoing, your use and access to these Additional Features may be for a limited
            period of time or for limited portions of the Service. We reserve the right to accept or reject any order
            for Additional Features in our own discretion. Should we elect to accept your offer, you will receive a
            confirming email at the email address that you provide at such time. All purchases are final and non-
            refundable.
            <br></br>
            <br></br>
            Prices for Additional Features are subject to change at any time by us in our sole discretion. The total
            price and any benefits offered to you for your purchase of an Additional Feature will be set forth in the
            order for the Additional Feature and, in the event of a conflict between such order and this Agreement,
            the terms of the order will prevail. All payments for Additional Features must be made up-front using
            any of the accepted payment methods designated by us (in our sole discretion) on your order, which
            may include credit card, debit card, or use of a bona-fide electronic payment provider (e.g., PayPal). We
            may use third-party service providers to process transactions on the Service and all payments for
            Additional Features must be made in the manner specified by such third-party service providers.
            <br></br>
            <br></br>
            <span>18. Miscellaneous.</span>
            <br></br>
            <br></br>
            If any provision of this Agreement is held to be invalid or unenforceable by a court of competent
            jurisdiction, then the remaining provisions will nevertheless remain in full force and effect, and such
            provision will be reformed in a manner to effectuate the original intent of the parties as closely as
            possible and remain enforceable. If such reformation is not possible in a manner that is enforceable,
            then such term will be severed from the remaining terms, and the remaining terms will remain in effect.
            This is the entire Agreement between you and us relating to the subject matter herein and supersedes
            any and all prior or contemporaneous written or oral agreements between you and us with respect to
            such subject matter. This Agreement may not be changed, waived or modified except by DoBetter
            posting such changes, waivers or modifications within the Services. If any employee of DoBetter offers
            to modify this Agreement, he or she is not acting as an agent for DoBetter or speaking on DoBetter’s
            behalf. You may not rely, and should not act in reliance on, any statement or communication from an
            employee of DoBetter or anyone else purporting to act on DoBetter’s behalf. This Agreement is between
            you and DoBetter; there are no third-party beneficiaries. You are responsible for compliance with all
            Applicable Laws, including United States export and import regulations. You may not use the Service if
            you are a resident of a country embargoed by the United States or are a foreign person or entity blocked
            or denied by the United States government. No agency, partnership, joint venture, employee-employer
            or franchiser-franchisee relationship is intended or created by this Agreement. Neither this Agreement
            nor any right, obligation or remedy hereunder is assignable, transferable, delegable or sublicensable by
            you except with DoBetter’s prior written consent, and any attempted assignment, transfer, delegation
            or sublicense shall be null and void. DoBetter may assign, transfer or delegate this Agreement or any
            right or obligation or remedy hereunder in its sole discretion. DoBetter shall not be in breach of this
            Agreement nor liable for any delay in performing, or failure to perform, any of its obligations under this
            Agreement, if such delay or failure result from events, circumstances or causes beyond its reasonable
            control. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of
            any preceding or subsequent breach or default. Except as explicitly stated otherwise, legal notices shall
            be served, in the case of DoBetter, at support@dobetter.social or, in your case, to contact information
            you choose to provide us. Notice to you shall be deemed given 24 hours after the e-mail is sent. Any
            heading, caption or section title contained in this Agreement is inserted only as a matter of convenience,
            and in no way defines or explains any section or provision hereof. As used in this Agreement, “including”
            means “including without limitation”.
            </p>
            
            <hr></hr>
            <label>{"Acceptable Use Policy"}</label>

            <p>
            This Acceptable Use Policy is established by Redemption Software LLC dba DoBetter.Social, a Colorado
            Limited Liability Company (<span>“DoBetter,” “we,” “us,” “our”</span>) sets forth responsibilities, obligations, and
            restrictions arising out of your use of our websites, applications, and other products, services, and
            platforms, including our website accessible at DoBetter.com (collectively, the <span>“Service”</span>). By using the
            Service, creating a Service account, indicating your assent to the Terms of Service for the Service (the
            <span> “Terms of Service”</span>) in any way (e.g., by clicking “I Agree” or a similar mechanism), or by otherwise
            accessing and using the Service, you accept and agree to comply with the terms of this Acceptable Use
            Policy (the <span>“Policy”</span>). If you do not agree to all of the terms and conditions of this Policy, you may not use
            or access the Service. Failure to comply with this Policy may result in us terminating or suspending your
            use of the Service and potential civil, criminal, or administrative liability or penalties against you. This
            Policy is incorporated by reference and governed by the Terms of Service or any other written
            agreement between you and DoBetter governing the Service. Capitalized terms not otherwise defined
            herein have the meaning set forth in the Terms of Service.
            <br></br>
            <br></br>
            <span>1. Use of Service.</span>
            <br></br>
            <br></br>
            Portions of the Service may allow you to discuss and post content surrounding cannabis and cannabis
            use, including, but not limited to, photos of and information on, all parts of the cannabis plant, whether
            growing or not, seeds from the cannabis plant, resin extracted from any part of the cannabis plant,
            every compound, manufacture, salt, derivative, mixture, or preparation of the cannabis plant, its seeds,
            or resin, cannabis-related consumption accessories such as bongs, pipes, vaporizers, etc., cannabis
            products including hemp and CBD products. However, any use of the Service must be in compliance with
            all Applicable Laws and PROMOTING THE DIRECT SALE OF CANNABIS OR CANNABIS-RELATED
            CONSUMPTION ACCESSORIES IS EXPRESSLY PROHIBITED. You should generally use your good judgment
            before discussing or posting your content, since your content will be publicly available.
            <br></br>
            <br></br>
            <span>2. Prohibited Use of the Service.</span>
            <br></br>
            <br></br>
            The Service may not be used in any manner that is inappropriate, objectionable, deceptive, harmful,
            exploitative, abusive, violates any person’s rights, or that may cause harm to any person, the Service, or
            DoBetter, or that may be otherwise inappropriate for the DoBetter community, in each case as
            determined by DoBetter in its sole discretion. Prohibited use of the Service include, but is not limited to,
            using the Service to:
            <br></br>
            <br></br>
            <ul>
                • Promote the direct sale of cannabis.
                <br></br>
                <br></br>
                • Transmit, or direct the transmission of, any advertising, marketing or other messaging
                promoting the direct sale of cannabis.
                <br></br>
                <br></br>
                • Notwithstanding the limited authorized use surrounding cannabis, the Service may not be used
                in any manner that is illegal pursuant to US Controlled Substances Act (21 USCS §§ 801 et seq.).
                • Transmit any messaging that is offensive, obscene, libelous, defamatory, fraudulent, abusive, or
                contains tortious material.
                <br></br>
                <br></br>
                • Providing false ratings or reviews for third-party products and services.
                <br></br>
                <br></br>
                • Engage in messaging that promotes, incites or instructs unlawful matters.
                <br></br>
                <br></br>
                • Notwithstanding the limited authorized use surrounding cannabis, engage in messaging that is
                otherwise unlawful.
                <br></br>
                <br></br>
                • Impersonate or attempt to impersonate or mislead DoBetter, a DoBetter representative,
                another user, or any other person or entity.
                <br></br>
                <br></br>
                • Engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Service,
                or which, as determined by us, may harm the Service, DoBetter or users of the Service or expose
                them to liability.
                <br></br>
                <br></br>
                • Engage in any unsolicited advertising, marketing, or other activities, including any activities that
                violate anti-spam laws and regulations, including the CAN SPAM Act of 2003, the Telephone
                Consumer Protection Act, and the Do-Not-Call Implementation Act (or any similar or analogous
                anti-spam, data protection, or privacy legislation in any other jurisdiction).
                <br></br>
                <br></br>
                • Use the Service in connection with any unsolicited or harassing messages (commercial or
                otherwise), including unsolicited or unwanted phone calls, emails, direct messages, SMS or text
                messages, voice mail, or faxes.
                <br></br>
                <br></br>
                • Use the Service to harvest or otherwise collect information about others from users, including
                email addresses or phone numbers, without their consent to provide such information.
                <br></br>
                <br></br>
                • Create a false identity or forged email address or header, or phone number, or otherwise
                attempting to mislead others as to the identity of the sender or the origin of a text message or
                phone call.
                <br></br>
                <br></br>
            </ul>
            Additionally, you agree not to:
            <br></br>
            <br></br>
            <ul>
                • Use the Service in any manner that could disable, overburden, damage, or impair the Service or
                interfere with any other person’s or entity’s use of the Service, including their ability to engage
                in real time activities through the Service.
                <br></br>
                <br></br>
                • Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or
                technologically harmful.
                <br></br>
                <br></br>
                • Attack the Service via a denial-of-service attack or a distributed denial-of-service attack (or use
                the Service to engage in such an attack).
                <br></br>
                <br></br>
                • Otherwise interfere with the functionality of the Service.
                <br></br>
                <br></br>
                • Alter, modify, reconfigure, reverse engineer the Service or use the Service and any other third-
                party code, files, script or program to automatically send messages or in any manner that
                violates the terms of this Policy or applicable law.
                <br></br>
                <br></br>
                • Attempt to bypass, exploit, defeat, or disable limitations or restrictions placed on the Service.
            </ul>
            <br></br>
            <br></br>
            <span>3. Amendments to this Policy.</span>
            <br></br>
            <br></br>
            This Policy may be modified at any time, for any reason, and at our sole discretion. You should review
            this Policy periodically to be aware of and understand any changes. A revised version of this Policy will
            be effective as of the date it is posted. Notwithstanding the foregoing, in the event we make a material
            change to this Policy, we will attempt to provide you with notice of such change by posting a notice on
            our website and to the email address you provide to us when you register your account, after which
            your access and use of the Service shall be subject to the updated version of this Policy.
            </p>

            <hr></hr>
            <label>{"Privacy Policy"}</label>

            <p>
            <span style={{display: "block", textAlign: "center"}}>Our Commitment to Your Privacy.</span>
            <br></br>
            DoBetter.Social (<span>“DoBetter”</span>, <span>“we”</span>, or <span>“us”</span>) respects your privacy. 
            This Privacy Policy describes the types of information we may collect from you when you visit dobetter.social (the “Site”), 
            how we use the information we collect, with whom we share it, how we protect it, and the choices we offer you regarding 
            our collection and use of such information.
            <br></br>
            <br></br>
            <span>1. Information We Collect.</span>
            <br></br>
            <br></br>
            <u>Where We Collect PI From</u>. We collect information that can uniquely identify you (<span>“personal information”</span>) directly 
            from you when you use the Site, such as when you register for an account or reach out to us. We may also collect personal information 
            about you from our service providers and business partners.
            <br></br>
            <br></br>
            <u>What We Collect</u>. The personal information we collect about you may include, without limitation:
            <br></br>
            <br></br>
            <ul>
                •	Contact information, such as name, email address, and phone number;
                <br></br>
                <br></br>
                •	Payment information, which is collected and stored solely through our third-party payment provider, Stripe. 
                    Any credit card information is encrypted and directly transmitted to Stripe—we do not collect, store, or otherwise access your credit card information. 
                    Such payment transactions are subject to <a href="https://stripe.com/privacy" target="_blank">Stripe’s privacy policy</a>.
                <br></br>
                <br></br>
                •	Login information, such as username, password, and security questions and answers.
            </ul>
            <span>2. How We Respond to “Do Not Track” Signals.</span>
            <br></br>
            <br></br>
            A do not track signal (“DNT”) prevents web applications from tracking you, you can learn more about DNT at 
            <a href="https://allaboutdnt.com" target="_blank"> https://allaboutdnt.com</a>. Your web browser may let you choose your preference as to whether you want 
            to allow the collection of information about your online activities over time and across different websites or online services. At this time, the Site do 
            not respond to the preferences you may have set in your web browser regarding such collection of your information, and the Site may continue to collect 
            information in the manner described in this Privacy Policy.
            <br></br>
            <br></br>
            <span>3. How We Use the Information We Collect.</span>
            <br></br>
            <br></br>
            We may use information we obtain about you to:
            <br></br>
            <br></br>
            <ul>
            •	Allow you access to the Site and provide you with services you request;
            <br></br>
            <br></br>
            •	Send you information or materials you request;
            <br></br>
            <br></br>
            •	Respond to your questions and comments and provide customer support;
            <br></br>
            <br></br>
            •	Communicate with you about our products and services;
            <br></br>
            <br></br>
            •	Operate, evaluate, and improve our business and the products and services we offer;
            <br></br>
            <br></br>
            •	Analyze trends and statistics regarding use of the Site and transactions conducted using the Site;
            <br></br>
            <br></br>
            •	Protect against and prevent fraud, unauthorized transactions, claims, and other liabilities, and manage risk exposure, including by 
            identifying potential hackers and other unauthorized users;
            <br></br>
            <br></br>
            •	Enforce our Terms of Use and other agreements; and
            <br></br>
            <br></br>
            •	Comply with applicable legal requirements and industry standards.
            </ul>
            <u>Other Uses</u>. We also may use the information we obtain about you in other ways for which we provide specific notice at the time of collection.
            <br></br>
            <br></br>
            <span>4. Disclosure of Information.</span>
            <br></br>
            <br></br>
            <u>Who We Disclose PI To</u>. We may disclose aggregated information about our users without restriction. We may disclose personal information about you:
            <br></br>
            <br></br>
            <ul>
                •	To service providers we use to support our business;
                <br></br>
                <br></br>
                •	To comply with any court order, law, or legal process, including to respond to any government or regulatory request;
                <br></br>
                <br></br>
                •	To enforce or apply our agreements;
                <br></br>
                <br></br>
                •	If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of DoBetter, users of the Site, or others; and
                <br></br>
                <br></br>
                •	With your consent.
            </ul>
            <br></br>
            We also reserve the right to transfer your personal information to a buyer or other transferee in the event of a merger, divestiture, restructuring, 
            reorganization, dissolution, sale, or other transfer of some or all of our assets, equity or similar transaction.  Should such a sale, merger, or 
            transfer occur, we will use reasonable efforts to direct the transferee to use your personal information in a manner that is consistent with our Privacy Policy.  
            <br></br>
            <br></br>

            <span>5. Data Security.</span> We have implemented measures intended to protect your personal information from accidental loss and from unauthorized access, use, 
            alteration and disclosure. Any payment transactions will be encrypted and subject to our third-party payment provider, 
            <a href="https://stripe.com/privacy" target="_blank"> Stripe’s, privacy policy</a>. Please note that 
            the electronic transmission of information is not completely secure. We cannot guarantee that the security measures we have in place to safeguard personal 
            information will never be defeated or fail, or that such measures will always be sufficient or effective. Any transmission of personal information is at your own risk.
            <br></br>
            <br></br>
            <span>6. Accessing and Changing Your Information.</span> You can review and change some of the personal information associated with your account by logging into 
            your account and visiting your account profile page. We may not accommodate a request to change information if we believe the change would violate any law or 
            legal requirement or cause the information to be incorrect.
            <br></br>
            <br></br>
            <span>7. Your California Privacy Rights.</span> California Civil Code Section § 1798.83 permits users of our Site who are California 
            residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing 
            purposes. To make such a request, please contact us.
            <br></br>
            <br></br>
            <span>8. Children Under the Age of 13.</span> The Site is not directed to, and we do not knowingly collect or solicit personal information from, children 
            under the age of 13. If we learn we have collected or received personal information from a child under the age of 13, we will delete that information. 
            If you believe we might have any information from or about a child under the age of 13, please contact us.
            <br></br>
            <br></br>
            <span>9. Changes to Our Privacy Policy.</span> We reserve the right to update or change this Privacy Policy at any time. If we make a material change to our 
            Privacy Policy, we will provide notice to you (by posting on our website) that our privacy practices have changed and will provide a link to the new policy. 
            In the event we make a material change to how we use your personal information, we will provide you with an opportunity to opt out of such new or different use. 
            The date this Privacy Policy was last revised is at the top of this page. We encourage you to review this Privacy Policy periodically to check for any 
            updates or changes.
            <br></br>
            <br></br>
            <span>10. Data Transfers.</span> We are based in the United States. When we obtain information about you, we may transfer, process, and store such information
             outside of the country in which you reside, including in the United States. By using the Site, you consent to the transfer to and processing and storage of 
             your information in countries outside of your country of residence, which may have different data protection laws than those in the country where you reside.
            <br></br>
            <br></br>
            <span>11. Contact Us.</span> If you have any questions about this Privacy Policy or our information practices, please email us at support@dobetter.social.
            <br></br>
            <br></br>
            </p>


        </div>
    )
}

export default TermsPage
