import React from 'react'
import { useEffect, useState, useRef, useContext } from "react"
import { UserContext } from "../Utils/context"
import CommentCell from "./CommentCell"


import "../Styles/commentTable.scss"

function CommentTable({ commentsList, toggleCommentLike, focusCommentID }) {
    const { user } = useContext(UserContext)
    
    const [didFocusCommentRef, setDidFocusCommentRef] = useState(false)
    const focusCommentRef = useRef()

    useEffect(() => {

        // Focus the Comment From Prop (flash the cell anim)
        if (focusCommentRef.current != null && !didFocusCommentRef) {
            //focusCommentRef.current.scrollIntoView({behavior: "smooth", block: "center"})
            focusCommentRef.current.style.backgroundColor = "#e7e7e9"
            setTimeout(focusCommentStyleEnded, 1000)
            setDidFocusCommentRef(true)
        }
    }, [commentsList])

    const focusCommentStyleEnded = () => {
        focusCommentRef.current.style.backgroundColor = "unset"
    }

    const currentUserLikedComment = (comment) => {

        let currentUserLiked = false
        if (comment.likes != null && comment.likes.length > 0) {
            for (let i = 0; i < comment.likes.length; i++) {
                const aLike = comment.likes[i]
                if (aLike.author._id === user._id) {
                    currentUserLiked = true
                    break
                }
            }
        }

        return currentUserLiked
    }

    return (
        <div>
            <table className="comments-table">
                    <tbody>
                    {commentsList.map((comment, index) => (
                        <CommentCell comment={comment} index={index} focusCommentID={focusCommentID} focusCommentRef={focusCommentRef} currentUserLikedComment={currentUserLikedComment} toggleCommentLike={toggleCommentLike} />
                    )) }
                    </tbody>
                </table>
        </div>
    )
}

export default CommentTable
