import React from "react";
import { useHistory } from "react-router-dom"
import axios from "axios"
import { trackPromise } from 'react-promise-tracker'
import Moment from "react-moment"

import "../components/Styles/landingPage.scss"
import "../components/Styles/landingImage.scss"

import Logo from "../components/Shared/Logo"
import LoginForm from "../components/LandingPage/LoginForm"
import LandingRegisterLink from "../components/LandingPage/LandingRegisterLink"
import LandingHelpLink from "../components/LandingPage/LandingHelpLink"


const LandingPage = ({ localStoreUserData, userIsBanned, userBannedUntil }) => {
    const history = useHistory();

    const login = async (email, password) => {

        const user = { email, password };

        trackPromise(axios.post(
            `${process.env.REACT_APP_BASE_API}/auth/login`,
            user
        ).then((response) => {
            localStoreUserData(response.data)
            
            if (response.data.user != null) {
                const currentUser = response.data.user
                const userOnboard = currentUser != null && currentUser.phone_confirmed != null && currentUser.username != null && currentUser.bio != null && currentUser.photo_url != null
                const userProfileAccess = currentUser != null && currentUser.phone_confirmed != null && currentUser.username != null

                if (userProfileAccess && !userOnboard) {
                    history.push(`/profile/${currentUser._id}`)
                }else if (userOnboard) {
                    history.push("/feed")
                }
            }
        }).catch((error) => {
            if (error.response.data != null) {
                alert(error.response.data)
            }
            throw(error)
        }))
    }

    return(
        <div className="landing-container">
            <Logo size={"med"} />
            <div className="mar-vert-20"></div>
            
            { userIsBanned &&
                <div className="landing-user-banned">
                    Your account has been suspended until <Moment format={"MM/DD/YY @ hh:mma"}>{userBannedUntil}</Moment> for conduct that violates the Terms and Conditions.  In the future, please keep your participation well within the guidelines.  We can all #DoBetter.
                </div>
            }


            <LoginForm login={login} />
            <div className="mar-vert-12"></div>
            <LandingRegisterLink />
            <div className="mar-vert-12"></div>
            <LandingHelpLink />
        </div>
    );
}

export default LandingPage;