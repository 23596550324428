import React from 'react'
import { useHistory } from "react-router-dom"

function AdminOneHittersCell({ oneHitter }) {
    let history = useHistory()

    const oneHitterCellClicked = () => {
        history.push(`/admin/one-hitters/${oneHitter._id}`)
    }

    return (
        <tr onClick={oneHitterCellClicked}>
            <td className="admin-one-hitters-cell">
                <div className="admin-one-hitters-cell-content">
                    <div><span>{oneHitter.name}</span></div>
                    <div>x{oneHitter.multiplier}</div>
                    <div>{oneHitter.image.replace("one-hitters/", "")}</div>
                </div>
            </td>
        </tr>
    )
}

export default AdminOneHittersCell
