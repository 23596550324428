import React from 'react'
import AdminMetricsCell from "./AdminMetricsCell"
import AdminOptionCell from "./AdminOptionCell"

import "../Styles/adminOptions.scss"

function AdminOptions({ deleteUser }) {

    const deleteUserClicked = () => {
        let email = window.prompt("Please enter the Email Address associated with the user you want to Delete.")

        if (email != null && email.length > 0 && email.indexOf("@") > 0) {
            if (window.confirm(`Are you sure you want to delete the user associated with: ${email}\n\nNote: This action is IRREVERSIBLE. The user record in its entirety will be DELETED.`)) {
                deleteUser(email)
            }
        }else{
            alert("Invalid Email Format Provided. You must provide an email address.")
        }
    }

    return (
        <div>
            <table className="admin-options-table">
                <thead>
                    <AdminMetricsCell />
                </thead>
                <tbody>
                    <AdminOptionCell option={"Users"} location={"users"} />
                    <AdminOptionCell option={"Clouds / Stickers / Subscriptions"} location={"content"} />
                    <AdminOptionCell option={"One Hitters"} location={"one-hitters"} />
                    <AdminOptionCell option={"Badges"} location={"badges"} />
                    <AdminOptionCell option={"Outlets"} location={"outlets"} />
                    <AdminOptionCell option={"Invoices"} location={"invoices"} />
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <button onClick={() => {deleteUserClicked()}}>Delete User</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default AdminOptions
