import React from 'react'
import { useState, useEffect, useContext } from "react"
import { UserContext } from "../components/Utils/context"
import { useHistory } from "react-router-dom"
import axios from "axios"
import RedeemOutlets from '../components/RedeemPage/RedeemOutlets'

import "../components/Styles/redeemPage.scss"

function RedeemPage() {
    let history = useHistory()
    const { token } = useContext(UserContext)

    const [hasAccess, setHasAccess] = useState(null)
    const [availablePoints, setAvailablePoints] = useState(0)

    useEffect(() => {
        
        if (token != null && hasAccess === null) {
            getPoints()
        }

    }, [token])

    const getPoints = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_API}/me/points`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            )
            
            if (response.data.available_points != null) {

                if (response.data.available_points < 5000) {
                    history.push("/points")
                }else{
                    setAvailablePoints(response.data.available_points)
                    setHasAccess(response.data.available_points >= 5000)
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    return (
        <div className="redeem-container">
            { hasAccess && 
                <RedeemOutlets availablePoints={availablePoints} />
            }
        </div>
    )
}

export default RedeemPage
