import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom";
import { UserContext } from "../components/Utils/context"
import axios from "axios"
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useHistory } from "react-router-dom"

import PostProfilePic from "../components/PostPage/PostProfilePic"
import PostText from "../components/PostPage/PostText"
import CommentTable from "../components/CommentPage/CommentTable"
import SubmitBtn from "../components/Shared/SubmitBtn"
import InfiniteScroll from 'react-infinite-scroll-component'
import RefreshContent from "../components/Shared/RefreshContent"

import "../components/Styles/commentPage.scss"

function CommentPage() {
    const location = useLocation()
    let history = useHistory()
    const { token } = useContext(UserContext)
    const [comment, setComment] = useState(null)
    const [commentsList, setCommentsList] = useState([])

    const [focusCommentID, setFocusCommentID] = useState(() => {
        return location.state != null ? location.state.focusCommentID : null
    })

    const [postCloud, setPostCloud] = useState(null)
    const [postCloudType, setPostCloudType] = useState(null)
    const [postMentions, setPostMentions] = useState(null)

    const [commentSubmitted, setCommentSubmitted] = useState(false)

    const [page, setPage] = useState(1)
    const pageLimit = 10
    const [hasMorePages, setHasMorePages] = useState(false)

    const { postid } = useParams()
    
    useEffect(() => {

        if (postid != null && token != null) {
            retrieveComments(false)
        }
    }, [token])

    const postTextChanged = (text) => {
        setCommentSubmitted(false)
        setComment(text)
    }

    const postMentionsChanged = (mentions) => {
        let newMentions = mentions.map(m => m.id)

        setPostMentions(newMentions)
    }

    const postCloudChanged = (cloud, type) => {
        setPostCloud(cloud)
        setPostCloudType(type)
    }

    const createComment = async (e) => {
        e.preventDefault()

        if (comment === null || comment.trim().length === 0) {
            alert("comment text is required")
            return false
        }

        const sendData = {
            "comment": {
                "caption": comment
            }
        }

        if (postCloud != null) {
            sendData["comment"]["bubble"] = postCloud
            sendData["comment"]["onBubble"] = postCloudType
        }

        if (postMentions != null && postMentions.length > 0) {
            sendData["comment"]["mentions"] = postMentions
        }

        try {

            setCommentSubmitted(true)

            const response = await trackPromise(axios.put(
                `${process.env.REACT_APP_BASE_API}/posts/comments?post_id=${postid}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.comment != null) {
                setCommentsList(commentsList => [response.data.comment, ...commentsList])
            }
            
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const retrieveComments = async (refresh) => {

        const URI = focusCommentID != null ? 
        // Retrieve Comments with Focused Notification Comment on Top
        `posts/comments?post_id=${postid}&page=${!refresh ? page : 1}&limit=${pageLimit}&type=notification&comment_id=${focusCommentID}` :
        // Retrieve Comments Regularly 
        `posts/comments?post_id=${postid}&page=${!refresh ? page : 1}&limit=${pageLimit}&type=regular`

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/${URI}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.comments != null) {
                const newComments = correctFocusCommentDuplicates(response.data.comments.docs)

                if (newComments != null) {

                    const hasNextPage = response.data.comments.hasNextPage
                    const nextPage = response.data.comments.nextPage
                
                    if (page === 1 || refresh) {
                        setCommentsList(newComments)
                    }else{
                        setCommentsList(commentsList.concat(newComments))
                    }
                    
    
                    if (hasNextPage != null) {
                        
                        setPage(nextPage)
                        setHasMorePages(hasNextPage)
                    }
                }
            }
            

        }catch (error) {
            // if (error.response?.data?.message != null) {
            //     alert(error.response.data.message)
            // }
            throw(error)
        }
    }

    const refreshComments = () => {
        retrieveComments(true)
    }

    const toggleCommentLike = async (liked, likedCommentID) => {
        
        try {
            const response = await trackPromise(axios.put(
                `${process.env.REACT_APP_BASE_API}/posts/comments/likes?comment_id=${likedCommentID}`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.comment != null) {

                const updatedComment = response.data.comment
                let updatedComments = []
                for (let i = 0; i < commentsList.length; i++) {
                    const aComment = commentsList[i]
                    if (aComment._id === updatedComment._id) {
                        updatedComments.push(updatedComment)
                    }else{
                        updatedComments.push(aComment)
                    }
                }

                setCommentsList(updatedComments)
            }


        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const viewPostClicked = () => {
        if (postid != null) {
            history.push({
                pathname: `/posts/${postid}`,
                state: { hideRelatedPosts: true }
            })
        }
    }

    const correctFocusCommentDuplicates = (comments) => {
        let correctedComments = [...comments]

        if (comments.length >= 2) {
                
            const firstComment = comments[0]
            const secondComment = comments[1]
            const isDuplicateComment = firstComment._id === secondComment._id
            
            if (isDuplicateComment) {
                correctedComments = correctedComments.splice(1)
            }
        }

        return correctedComments
    }

    return (
        <div className="comment-page-container">
            <form onSubmit={createComment}>
                <div style={{width: "97%", display: "inline-flex", alignItems: "center"}}>
                    <PostProfilePic mode={"create"} />
                    <PostText mode={"create"} postTextChanged={postTextChanged} postCloudChanged={postCloudChanged} postMentionsChanged={postMentionsChanged} commentSubmitted={commentSubmitted} />
                </div>
                <SubmitBtn text={"Comment"} height={38} width={94} />
            </form>
            <hr className="comment-page-seperator"></hr>
            <div className="comment-page-viewpost-container">
                <button type="button" onClick={viewPostClicked}>View Post</button>
            </div>
            <InfiniteScroll dataLength={commentsList.length} next={retrieveComments} hasMore={hasMorePages} pullDownToRefresh refreshFunction={refreshComments} pullDownToRefreshThreshold={80} pullDownToRefreshContent={<RefreshContent type={"pull"} />} releaseToRefreshContent={<RefreshContent type={"release"} />}>
                <CommentTable commentsList={commentsList} toggleCommentLike={toggleCommentLike} focusCommentID={focusCommentID} />
            </InfiniteScroll>
        </div>
    )
}

export default CommentPage
