import React from 'react'
import { useHistory } from "react-router-dom"

function AdminOptionCell({option, location}) {
    let history = useHistory()

    const optionCellClicked = () => {
        history.push(`/admin/${location}`)
    }

    return (
        <tr onClick={optionCellClicked}>
            <td className="admin-option-tablecell">
                <div>
                    <label>
                        {option}
                        <span>{">"}</span>
                    </label>
                    
                </div>
            </td>
        </tr>
    )
}

export default AdminOptionCell
