import React from 'react'
import { useState, useCallback } from 'react'
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../Utils/canvasUtils'
import loadImage from "blueimp-load-image/js"

import "../Styles/imageCropModal.scss"

const ORIENTATION_TO_ANGLE = {
    '1': 90,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0,
    '7': 0,
    '8': 0,
}

function ImageCropModal({ handleCroppedImage }) {
    /* Modal States */
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /* Cropper States */
    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
            )
            setCroppedImage(croppedImage)
            handleCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            loadImage(file, 
                (img) => { 
                    var base64data = img.toDataURL('image/jpeg')
                    setImageSrc(base64data)
                    handleShow() 
            }, { orientation: true, canvas: true } )
        }
    }

    const readFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const handleConfirm = () => {
        handleClose()
        showCroppedImage()
    }

    return (
        <>

            <input type="file" onChange={onFileChange} accept="image/*"></input>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Crop Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body className="image-crop-body">
                    <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropShape="round"
                    />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Done
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}




export default ImageCropModal
