import React from 'react'
import { useState } from "react"
import axios from "axios"
import { trackPromise } from "react-promise-tracker"
import { useHistory } from "react-router-dom"
import { Helmet } from "react-helmet"
import Logo from "../components/Shared/Logo"
import HelpStepOne from "../components/HelpPage/HelpStepOne"
import HelpStepTwo from "../components/HelpPage/HelpStepTwo"

function HelpPage() {
    let history = useHistory()

    const [helpStepNumber, setHelpStepNumber] = useState(1)
    const [emailUsed, setEmailUsed] = useState("")
    
    const findEmail = async (email) => {

        setEmailUsed(email)

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/help/request?email=${email}`
            ))

            if (!response.data.success) {
                alert("We cannot find an account with that email. Please try again.")
            }else{
                if (response.data.status != null && response.data.status === "pending") {
                    setHelpStepNumber(2)
                }
            }

        }catch (error) {
            if (error.response.data.error.detail != null) {
                alert(error.response.data.error.detail)
            }
            throw(error)
        } 
    }

    const resetPasword = async (activationCode, newPassword) => {

        const sendData = {
            "code": activationCode,
            "user": {
                "email": emailUsed,
                "password": newPassword
            }
        }
        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/help/update-password`,
                sendData
            ))

            if (!response.data.valid && response.data.status === "pending") {
                alert("Invalid Activation Code Provided")
            }else{
                if (response.data.valid && response.data.status === "approved") {
                    alert("Your password has been successfully reset!")
                    history.push("/")
                }
            }

        }catch (error) {
            if (error) {
                alert("Invalid Activation Code")
            }
            throw(error)
        } 
    }
    
    return (
        <div className="help-container" style={{minHeight: "calc(100vh - 120px)"}}>
            <Helmet>
                <title>Do Better - Help</title>
                <meta name="description" content="Get help from support@dobetter.social, or follow us in the network @dobettersocial." />
            </Helmet>

            <Logo size={"med"}/>
            
            <div className="mar-vert-24"></div>

            { helpStepNumber === 1 && <HelpStepOne findEmail={findEmail} /> }
            { helpStepNumber === 2 && <HelpStepTwo resetPassword={resetPasword} /> }
        </div>
    )
}

export default HelpPage;