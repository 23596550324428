import { useState, useEffect, useContext, useRef } from "react"
import { UserContext } from "../Utils/context"
import Logo from "./Logo"
import HeaderIconLink from "./HeaderIconLink"
import CircleCount from "./CircleCount"
import { Link, useLocation, useHistory } from 'react-router-dom'

import StickyHeader from 'react-sticky-header';

import MenuActive from "../Images/navigation-icons/menu.png"
import MenuInactive from "../Images/navigation-icons/menu-inactive.png"

import ProfileIcon from "../Images/sidenav-icons/menu-profile.png"
import NotificationIcon from "../Images/sidenav-icons/menu-notifications.png"
import MessageIcon from "../Images/sidenav-icons/menu-message.png"
import CloudIcon from "../Images/sidenav-icons/menu-customclouds.png"
import PointsIcon from "../Images/sidenav-icons/menu-points.png"
import SubscriptionIcon from "../Images/sidenav-icons/menu-subscriptions.png"
import AdminIcon from "../Images/sidenav-icons/menu-admin.png"
import LogoutIcon from "../Images/sidenav-icons/menu-signout.png"

import "../Styles/header.scss"
import "../Styles/logo.scss"
import 'react-sticky-header/styles.css';

const Header = ({ localStoreUserData, notificationCount, conversationsCount, userIsAdmin }) => {
    let history = useHistory()
    const location = useLocation()
    const menuOptionRef = useRef()

    const { user } = useContext(UserContext)
    const [sideNavStyle, setSideNavStyle] = useState({width: "0px"})

    const onLandingRoute = location.pathname === "/"
    const userOnboard = user && user.phone_confirmed && user.username && user.bio && user.photo_url

    const logoutUser = () => {
      localStoreUserData(null)
      history.push("/")
    }

    const openSideNav = () => {
      setSideNavStyle({width: "250px"})

      menuOptionRef.current.setAttribute("src", MenuActive)
    }

    const closeSideNav = () => {

      setSideNavStyle({width: "0px"})

      menuOptionRef.current.setAttribute("src", MenuInactive)
    }

    const headerStyle = (!userOnboard && !location.pathname.includes("/profile"))  ? {backgroundColor: "transparent"} : {}
    const headLinkContainerStyle = !userOnboard ? {width: "70%", justifyContent: "space-around"} : {}
    const headerScrollOverAreaStyle = {
      height: onLandingRoute ? "20px" : "63px"
    }

    const homeClassName = (location.pathname === "/") ? "sidenav-active-a" : ""
    const profileClassName = (user != null && location.pathname.includes(`/profile/${user._id}`)) ? "sidenav-active-a" : ""
    const notificationClassName = location.pathname.includes("/notifications") ? "sidenav-active-a" : ""
    const messagesClassName = location.pathname.includes("/messages") ? "sidenav-active-a" : ""
    const storeClassName = location.pathname.includes("/store") ? "sidenav-active-a" : ""
    const adminClassName = location.pathname.includes("/admin") ? "sidenav-active-a" : ""
    const pointsClassName = location.pathname.includes("/points") ? "sidenav-active-a" : ""
    const purchaseHistoryClassName = location.pathname.includes("/purchase-history") ? "sidenav-active-a" : ""
    const termsClassName = location.pathname.includes("/terms") ? "sidenav-active-a" : ""
    const contactClassName = location.pathname.includes("/contact") ? "sidenav-active-a" : ""
    const aboutClassName = location.pathname.includes("/about") ? "sidenav-active-a" : ""

    return (

      <StickyHeader header= {

      <div>
            <div className="sidenav" style={sideNavStyle}>
                <a href="#" className="closebtn" onClick={(e) => {e.preventDefault(); closeSideNav(); return false;}}>&times;</a>
                {user === null && <Link to="/" onClick={closeSideNav} className={homeClassName}>Home</Link>}

                {userOnboard &&
                  <div className="sidenav-option">
                    <img src={ProfileIcon}></img>
                    <Link to={`/profile/${user._id}`} onClick={closeSideNav} className={profileClassName}>
                      Profile
                    </Link>
                  </div>
                }
                
                {userOnboard && 
                  <div className="sidenav-option">
                    <img src={NotificationIcon}></img>
                    <Link to="/notifications" onClick={closeSideNav} className={notificationClassName}>
                      Notifications
                      { notificationCount > 0 && <CircleCount count={notificationCount} /> } 
                    </Link>
                  </div>
                }

                {userOnboard && 
                  <div className="sidenav-option">
                    <img src={MessageIcon}></img>
                    <Link to="/messages" onClick={closeSideNav} className={messagesClassName}>
                      Messages
                      { conversationsCount > 0 && <CircleCount count={conversationsCount} /> }
                    </Link>
                  </div>
                }

                {userOnboard &&
                  <div className="sidenav-option">
                    <img className="sidenav-option-cloud" src={CloudIcon}></img>
                    <Link to={`/store`} onClick={closeSideNav} className={storeClassName}>
                      Custom Clouds
                    </Link>
                  </div>
                }

                {userOnboard &&
                  <div className="sidenav-option">
                    <img className="sidenav-option-points" src={PointsIcon}></img>
                    <Link to={`/points`} onClick={closeSideNav} className={pointsClassName}>
                      My Points
                    </Link>
                  </div>
                }

                {userOnboard &&
                  <div className="sidenav-option">
                    <img className="sidenav-option-subscription" src={SubscriptionIcon}></img>
                    <Link to={`/purchase-history`} onClick={closeSideNav} className={purchaseHistoryClassName}>
                      My Subscriptions
                    </Link>
                  </div>
                }

                <hr className="sidenav-divider"></hr>

                {(userOnboard && userIsAdmin) &&
                  <div className="sidenav-option">
                    <img src={AdminIcon}></img>
                    <Link to="/admin" onClick={closeSideNav} className={adminClassName}>
                      Admin
                    </Link>
                  </div>
                }

                {user != null && 
                  <div className="sidenav-option">
                    <img className="sidenav-option-logout" src={LogoutIcon}></img>
                    <a href="#" onClick={(e) => {e.preventDefault(); closeSideNav(); logoutUser(); return false}}>Logout</a>
                  </div>
                }

                <div className="sidenav-footer-container">
                  <Link to="/terms" onClick={closeSideNav} className={termsClassName}>
                      {"Terms / AUP / Privacy"}
                  </Link>
                  <div className="sidenav-footer-container-spread">
                    <Link to="/contact" onClick={closeSideNav} className={contactClassName}>
                        Contact Us
                    </Link>
                    <Link to="/about" onClick={closeSideNav} className={aboutClassName}>
                        About Us
                    </Link>
                  </div>

                </div>
            </div>

            <header style={headerStyle}>
          
                <div className="head-link-container" style={headLinkContainerStyle}>
                  <div className="head-link" onClick={openSideNav}>
                    <img ref={menuOptionRef} src={MenuInactive} alt="" style={{height: "34px"}}></img>
                    { (notificationCount > 0 || conversationsCount > 0) && <CircleCount type={"dot"} /> }
                  </div>

                  {userOnboard && <HeaderIconLink location="/search" />}
                  
                  <Logo size={"small"} from={"header"} />
                  
                  {userOnboard && <HeaderIconLink location="/feed" />}
                  {userOnboard && <HeaderIconLink location="/create" />}
                </div>
            </header>
      </div>
      }>
        <div className="header-scrollover-sticky-container" style={headerScrollOverAreaStyle}></div>
      </StickyHeader>

    )
}

export default Header
