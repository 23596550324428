import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { UserContext } from "../Utils/context"
import { trackPromise } from 'react-promise-tracker'
import axios from "axios"
import StoreCategoryItemCell from './StoreCategoryItemCell'

import "../Styles/storeCategory.scss"

function StoreCategory({ category, stripeCheckout }) {
    let history = useHistory()
    const { token } = useContext(UserContext)

    const [items, setItems] = useState([])
    const [ownedItems, setOwnedItems] = useState([])
    const [itemPrices, setItemPrices] = useState([])


    useEffect(() => {
        
        if (token != null) {
            getCategoryItems()
        }

    }, [token])

    const getCategoryItems = async() => {

        const endPoint = 
            category === "clouds" ? "bubbles"  :
            category === "stickers" ? "stickers" :
            category === "one-hitters" ? "one-hitters" :
            ""

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/store/${endPoint}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (category === "clouds" || category === "stickers" && response.data != null) {
                setItems(response.data.subscriptions)
            }

            if (category === "one-hitters" && response.data != null) {
                let availableOneHitters = []
                for (let i = 0; i < response.data.one_hitters.length; i++) {
                    const aItem = response.data.one_hitters[i]
                    if (aItem.available != null && aItem.available === true) {
                        availableOneHitters.push(aItem)
                    }
                }
                setItems(availableOneHitters)
            }

            if (response.data.owned != null) {
                setOwnedItems(response.data.owned)
            }

            if (response.data.prices != null) {
                setItemPrices(response.data.prices)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }

    }

    const storeButtonClicked = () => {
        history.goBack()
    }

    const storeCategoryLabelText = 
        category === "clouds" ? "Cloud Packs" :
        category === "one-hitters" ? "One Hitters" :
        category === "stickers" ? "Stickers" : "" 

    return (
        <div className="store-category-container">

            <div className="store-category-top-container">
                <button onClick={storeButtonClicked}>{"< Store"}</button>
                <label>{storeCategoryLabelText}</label>
            </div>

            <div>
                <table className="store-category-items-table">
                    <tbody>

                        { items.map((item, index) => (
                            <StoreCategoryItemCell item={item} category={category} stripeCheckout={stripeCheckout} ownedItems={ownedItems} itemPrices={itemPrices} />
                        )) }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StoreCategory
