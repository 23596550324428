import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import { UserContext } from "../../Utils/context"
import AdminContentCell from "./AdminContentCell"

import "../../Styles/adminContent.scss"

function AdminContent() {
    const location = useLocation()
    let history = useHistory()
    const { token } = useContext(UserContext)

    const [tableFilter, setTableFilter] = useState(() => {
        return location.state != null ? location.state.withTableFilter : "clouds"
    })

    const [clouds, setClouds] = useState([])
    const [stickers, setStickers] = useState([])
    const [subs, setSubs] = useState([])

    useEffect(() => {
        
        if (token !== null && tableFilter === "clouds") {
            getClouds()
        }

        if (token !== null && tableFilter === "stickers") {
            getStickers()
        }

        if (token !== null && tableFilter === "subs") {
            getSubs()
        }
        
    }, [token])

    const getClouds = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/bubbles`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.bubbles !== null) {
                setClouds(response.data.bubbles)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getStickers = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/stickers`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.stickers !== null) {
                setStickers(response.data.stickers)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getSubs = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/subscriptions`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.subscriptions !== null) {
                setSubs(response.data.subscriptions)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createBadgeButtonPressed = () => {
        history.push({
            pathname: "/admin/content/create",
            state: { type: tableFilter }
        })
    }

    const switchTableFilter = (option) => {

        if (option === "clouds") {
            getClouds()
        }else if (option === "stickers") {
            getStickers()
        }else{
            getSubs()
        }

        setTableFilter(option)
    }

    return (
        <div className="admin-content">
             <div className="admin-content-options-container">
                <button className={tableFilter === "clouds" ? "pro-active" : ""} onClick={() => switchTableFilter("clouds")}>
                    Clouds
                </button>

                <button className={tableFilter === "stickers" ? "pro-active" : ""} onClick={() => switchTableFilter("stickers")}>
                    Stickers
                </button>

                <button className={tableFilter === "subs" ? "pro-active" : ""} onClick={() => switchTableFilter("subs")}>
                    Subs
                </button>
            </div>

            <table className="admin-content-table">
                <thead>
                    <tr>
                        <td>
                            <button type="button" onClick={createBadgeButtonPressed}>+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                   { tableFilter === "clouds" && clouds.map((cloud, index) => (
                        <AdminContentCell content={cloud} contentType={tableFilter} />
                    )) }

                    { tableFilter === "stickers" && stickers.map((sticker, index) => (
                        <AdminContentCell content={sticker} contentType={tableFilter} />
                    )) }

                    { tableFilter === "subs" && subs.map((sub, index) => (
                        <AdminContentCell content={sub} contentType={tableFilter} />
                    )) }
                </tbody>
            </table>
        </div>
    )
}

export default AdminContent
