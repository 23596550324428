import React from 'react'
import { useState, useEffect, useContext, useRef } from "react"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import { UserContext } from "../../Utils/context"
import InfiniteScroll from "react-infinite-scroll-component"
import AdminUsersCell from "./AdminUsersCell"
import SearchBar from "../../SearchPage/SearchBar"
import SearchResult from "../../SearchPage/SearchResult"



import "../../Styles/adminUsers.scss"
import "../../Styles/searchPage.scss"

function AdminUsers() {
    const { token } = useContext(UserContext)

    const [displayView, setDisplayView] = useState("table")
    const [tableFilter, setTableFilter] = useState("reported")

    const searchBarRef = useRef()
    const [searchResults, setSearchResults] = useState([])

    const [users, setUsers] = useState([])
    const [suspendedUsers, setSuspendedUsers] = useState([])

    useEffect(() => {

        if (token !== null) {
            getUsers()
        }

    }, [token])

    const getUsers = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/reports`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.reports != null) {
                let reportUsers = []
                for (let i = 0; i < response.data.reports.length; i++) {
                    reportUsers.push(response.data.reports[i].owner)
                }
                setUsers(reportUsers)
            }

            if (response.data.suspended != null) {
                let reportSuspendUsers = []
                for (let i = 0; i < response.data.suspended.length; i++) {
                    reportSuspendUsers.push(response.data.suspended[i].owner)
                }
                setSuspendedUsers(reportSuspendUsers)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getSearchResults = async (searchString) => {

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_API}/users?q=${searchString}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            )

            if (response.data.users) {
                setSearchResults(response.data.users)
                if (displayView != "results") {
                    setDisplayView("results")
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const verifyUser = async (user) => {

        const endPoint = user.verified === true ? "unverify" : "verify"

        const sendData = {
            "user_id": user._id
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/users/${endPoint}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            )

            if (response.data.user != null) {
                const index = searchResults.findIndex(user => user._id === response.data.user._id)
                const newSearchResults = [...searchResults]
                newSearchResults[index] = response.data.user
                setSearchResults(newSearchResults)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    const presentDisplayView = (type) => {
        if (type === "results") {
            searchBarRef.current.value = ""
            setSearchResults([])
        }
        setDisplayView(type)
    }

    const switchTableFilter = (option) => {
        setTableFilter(option)
    }

    return (
        <div className="admin-users">
            <div className={`${displayView === "results" ? "search-bar-results" : ""} search-bar-container`}>
                <SearchBar presentSearchView={presentDisplayView} getSearchResults={getSearchResults} searchBarRef={searchBarRef} />
                { displayView === "results" && <button type="button" onClick={() => {presentDisplayView("table")}}>Cancel</button> }
            </div>
            
            { displayView === "results" &&
                <InfiniteScroll dataLength={searchResults.length}>
                    <SearchResult searchResults={searchResults} userClickLocation={"admin/users"} verifyUser={verifyUser} /> 
                </InfiniteScroll>
            }
            
            { displayView === "table" && 
                <div className="admin-users-table-container">
                    <div className="admin-users-options-container">
                        <button className={tableFilter === "reported" ? "pro-active" : ""} onClick={() => switchTableFilter("reported")}>
                            <span>{users.length}</span>
                            <br></br>
                            Reported
                        </button>

                        <button className={tableFilter === "suspended" ? "pro-active" : ""} onClick={() => switchTableFilter("suspended")}>
                            <span>{suspendedUsers.length}</span>
                            <br></br>
                            Suspended
                        </button>
                    </div>

                    <InfiniteScroll dataLength={tableFilter === "reported" ? users.length : suspendedUsers.length}>
                        <table className="admin-users-table">
                            <tbody>


                            { tableFilter === "reported" && users.map((user, index) => (
                                <AdminUsersCell user={user} reportType={"current"} />
                            )) }

                            {tableFilter === "suspended" && suspendedUsers.map((suspendedUser, index) => (
                                <AdminUsersCell user={suspendedUser} reportType={"suspensions"} />
                            )) }
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            }
        </div>
    )
}

export default AdminUsers
