import React from 'react'

import "../Styles/submitBtn.scss"

function SubmitBtn({ text, height, width, customBkgHex }) {

    const submitBtnStyle = {
        height: height != null ?  `${height}px` : "38px",
        width: width != null ? `${width}px` : "100%",
        background: customBkgHex != null ? customBkgHex : ""
    }

    return (
        <div style={{paddingBottom: "35px"}}>
            <button className="submitbtn" type="submit" style={submitBtnStyle}>{text}</button>
        </div>
    )
}



export default SubmitBtn
