import React from 'react'
import { Helmet } from "react-helmet"
import Logo from "../components/Shared/Logo"
import AwsImage from "../components/Shared/AwsImage"

import "../components/Styles/aboutPage.scss"

function AboutPage() {
    return (
        <div className="about-page-container">
            <Helmet>
                <title>Do Better - About Us</title>
                <meta name="description" content="Turn your comments into commitments - and be who you post to be." />
            </Helmet>

            <Logo size={"med"}/>
            <AwsImage fileKey={"about/aboutus.png"} customClassName={"about-page-img"} />
        </div>
    )
}

export default AboutPage
