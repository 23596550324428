import React from 'react'
import { useState, useContext, useEffect } from 'react'
import { UserContext } from "../Utils/context"
import { trackPromise } from 'react-promise-tracker';
import axios from "axios"
import Modal from "react-bootstrap/Modal"
import UserCell from "../Shared/UserCell"

import "../Styles/listModal.scss"

function ListModal({ closeAction, listType, postID, commentID }) {
    const { token } = useContext(UserContext)

    const [show, setShow] = useState(true)
    const [listData, setListData] = useState([])

    const isUserList = listType === "post-likes" || listType === "comment-likes"

    const isLikesList = listType === "post-likes" || listType === "comment-likes"

    useEffect(() => {
        if (token) {
            if (isLikesList) {
                retrieveLikes(listType)
            }
        }
    }, [token])

    const handleShow = () => setShow(true)
    const handleClose = () => {
        closeAction()
        setShow(false)
    }

    const getModalTitle = () => {
        if (isLikesList) {
            return "Likes"
        }

        return ""
    }

    const retrieveLikes = async (likeType) => {

        const uri = likeType === "post-likes" ? `posts/likes?post_id=${postID}` : `posts/comments/likes?comment_id=${commentID}`
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/${uri}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.likes != null) {
                getLikeAuthors(response.data.likes)
            }
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getLikeAuthors = (likes) => {
        let authors = []
        for (let i = 0; i < likes.length; i++) {
            const aLike = likes[i]
            if (aLike.author != null) {
                authors.push(aLike.author)
            }
        }

        setListData(authors)
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} scrollable={false}>
                <Modal.Header closeButton style={{position: "sticky", top: "0", zIndex: "1", background: "white"}}>
                <Modal.Title>{getModalTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: "400px", padding: "0px"}}>
                    <table className="list-table">
                        <tbody>
                            { isUserList && <UserCell users={listData} /> }
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ListModal
