import React from 'react'
import { Link } from "react-router-dom";

import "../Styles/landingRegisterLink.scss"

function LandingRegisterBtn() {
    return (
        <div className="landing-register-link-container">

            <div className="landing-register-link-hr-container">
                <hr className="landing-register-link-hr"></hr>
                <label>or</label>
            </div>

            <div className="register-link-container">
                <Link to="/register" className="landing-register-link">
                    Create New Account
                </Link>
            </div>


        </div>
    )
}

export default LandingRegisterBtn
