import React from 'react'

function RefreshContent({ type }) {
    return (
        <div>
            { type === "pull" && <h5 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h5> }
            { type === "release" && <h5 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h5> }
        </div>
    )
}

export default RefreshContent