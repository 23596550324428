import React from 'react'
import { useHistory } from "react-router-dom"
import Moment from "react-moment"

import "../Styles/pointsTableCell.scss"

function PointsTableCell({ point, type }) {
    let history = useHistory()

    const getPointTitle = () => {
        return point.doc_type === "Post" ? "New Post" : point.doc_type === "Comment" ? "Comment on a Post" : `${point.doc_type} (${point.name})`
    }

    const pointsCellClicked = () => {
        if (type === "earned") {
            const doc_type = point.doc_type
            if (doc_type === "Post") {
                history.push(`/posts/${point._id}`)
            }else if (doc_type === "Comment") {
                history.push({
                    pathname: `/posts/${point.post}/comments`,
                    state: { focusCommentID: point._id }
                })
            }    
        }

        if (type === "redeemed") {
            history.push(`/points/redeemed/${point._id}`)
        }
    }


    return (
        <tr>
            <td className="points-table-cell" onClick={pointsCellClicked}>
                { type === "earned" && 
                    <div className="points-table-cell-info">
                        <label style={{float: "left"}}>{getPointTitle()}</label>
                        <label style={{float: "right"}}>+{point.points}</label>
                    </div>
                }

                { type === "redeemed" &&
                    <div className="points-table-cell-info">
                        <div>
                            <label style={{float: "left", fontWeight: "600"}}><Moment format={"MM/DD/YY"}>{point.createdAt}</Moment> - {point.outlet.name}</label>
                            <label style={{float: "right"}}>
                                { (point.free === true) && "0 points" }
                                { (point.free === false) && "-5000 points"}
                            </label>
                        </div>
                        <div style={{clear: "both"}}>
                            <label>
                                {point.redemption_code}
                                { (point.code.valid === true && point.free === true) && " (issued by Support)" }
                                { point.code.valid === false && " (canceled by Support)" }
                            </label>
                        </div>
                    </div>
                }
            </td>
        </tr>
    )
}

export default PointsTableCell
