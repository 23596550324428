import React from 'react'
import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { UserContext } from "../Utils/context"
import Moment from "react-moment"
import AwsImage from "../Shared/AwsImage"

import "../Styles/conversationCell.scss"

import SuspendedImage from "../Images/profile-icons/suspended-image.png"
import VerifiedIcon from "../Images/profile-icons/verified.png"

function ConversationCell({ conversations }) {
    let history = useHistory()
    const { user } = useContext(UserContext)

    const conversationCellClicked = (convo) => {
        const otherParticipant = getOtherParticipant(convo)
        history.push(`/messages/${otherParticipant._id}`)
    }

    const getOtherParticipant = (convo) => {

        const participants = convo.participants

        let otherParticipant = null
        for (let i = 0; i < participants.length; i++) {
            const aParticipant = participants[i]
            if (aParticipant._id != user._id) {
                otherParticipant = aParticipant
                break
            }
        }

        return otherParticipant
    }

    const conversationCellStyle = (convo) => {

        if (convo.last_message != null && convo.last_message.read != null) {
        const readIndex = convo.last_message.read.indexOf(user._id)
        const unread = readIndex === -1
        return {
            backgroundColor: !unread ? "#cacac9" : "white"
        }
        }else{
            return {}
        }
    }

    return (
        conversations.map((result, index) => (
            <tr>
                <td key={result._id} className="conversation-tablecell" style={conversationCellStyle(result)} onClick={() => {conversationCellClicked(result)}}>
                    <div className="conversation-tr-content">
                        <AwsImage fileKey={getOtherParticipant(result).photo_url} customClassName={"conversation-tr-img"} overrideImage={getOtherParticipant(result).suspended ? SuspendedImage : null} />
                        <div className="conversation-tr-label-container">
                            <label className="conversation-tr-label" style={{fontWeight: "600", marginBottom: "5px"}}>
                                @{getOtherParticipant(result).username}

                                { getOtherParticipant(result).verified === true && 
                                    <img src={VerifiedIcon}></img>
                                }
                            </label>
                            <label className="conversation-tr-lastmessage">{result.last_message?.body}</label>
                            <Moment className="conversation-tr-time" format={"MM/DD @ hh:mma"}>{result.updatedAt}</Moment>
                        </div>
                    </div>
                </td>
            </tr>
        ))
    )
}

export default ConversationCell
