import React from 'react'
import { useState, useContext } from 'react'
import Pluralize from 'react-pluralize'
import { UserContext } from "../Utils/context"
import ListModal from "../Shared/ListModal"
import LikeEmpty from "../Images/posts/like-empty.png"
import LikeFilled from "../Images/posts/like-filled.png"

import DropDown from "../Shared/DropDown"

import "../Styles/postActions.scss"

function PostActions({ post, togglePostLike }) {
    const { user } = useContext(UserContext)

    const [showListModal, setShowListModal] = useState(false)
    const [likeCount, setLikeCount] = useState(post.like_count)
    const [liked, setLiked] = useState(() => {
        let currentUserLiked = false
        if (post.likes != null && post.likes.length > 0) {
            for (let i = 0; i < post.likes.length; i++) {
                const aLike = post.likes[i]
                if (aLike.author._id === user._id) {
                    currentUserLiked = true
                    break
                }
            }
        }

        return currentUserLiked
    })

    const likeClicked = () => {
        const newLikeCount = likeCount + 1

        setLikeCount(newLikeCount)
        setLiked(true)
        togglePostLike(true, post._id)
    }

    const listModalClosed = () => {
        setShowListModal(false)
    }

    const likesLabelClicked = () => {
        setShowListModal(true)
    }

    const dropDownItems = [
        {
            title: "Report",
            url: `/report/post/${post.author._id}/${post._id}`
        }
    ]

    return (
        <div className="post-actions-container">
            <div style={{float: "left"}}>
                { !liked && <img className="post-action-like" src={LikeEmpty} onClick={likeClicked}></img> }
                { liked && <img className="post-action-like" src={LikeFilled}></img> }
                <label className="post-action-like-label" onClick={likesLabelClicked}><Pluralize singular={'like'} count={likeCount} /></label>
                { showListModal && <ListModal closeAction={listModalClosed} listType={"post-likes"} postID={post._id} /> }
            </div>
            <div style={{float: "right"}}>
                { post.author._id != user._id &&
                    <DropDown items={dropDownItems}/>
                }
            </div>
        </div>
    )
}

export default PostActions
