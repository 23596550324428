import React from 'react'
import { useState } from 'react'
import SubmitBtn from "../Shared/SubmitBtn"

import "../Styles/registerForm.scss"

function RegisterStepTwo({ registerUsername }) {

    const [username, setUsername] = useState("");

    const handleUsernameSubmit = (e) => {
        e.preventDefault()

        /* 
          Usernames:
          - Minimum 3 characters long
          - Maximum 15 characters long 
          - Lowercase Letters (a-z) 
          - Numbers (0-9)
          - Underscores (_)
        */

        if (username.trim().length === 0) {
            alert("username is required")
            return false
        }

        if (username.length > 15) {
            alert("username cannot be over 15 characters long")
            return false
        }

        if (username.length < 3) {
            alert("username must be atleast 3 characters long")
            return false
        }

        if (!isUserNameValid(username)) {
            alert("username can only have lowercase letters, numbers, underscores and must not contain spaces")
            return false
        }
        
        registerUsername(username)
    }

    const isUserNameValid = (username) => {
        const res = /^[a-z0-9_]+$/.exec(username)
        const valid = !!res
        return valid
      }

    return (
        <div>
            
            <form onSubmit={handleUsernameSubmit}>
                <div className="mar-vert-36">
                    <label className="register-step-label">Please enter your @username:</label>
                </div>

                <div className="register-form-content mar-vert-60">
                    <div style={{position: "relative"}}>
                        <input className="register-form-input" placeholder="username" type="text" onChange={(e) => setUsername(e.target.value)} required></input>
                    </div>
                </div>

                <div className="register-form-content">
                    <SubmitBtn text={"Next"} height={38} width={72} />
                </div>
            </form>

        </div>
    )
}

export default RegisterStepTwo
