import React from 'react'
import { useState, useEffect, useContext, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import SubmitBtn from "../../Shared/SubmitBtn"
import CSVReader from "react-csv-reader"
import Moment from "react-moment"

import "../../Styles/adminOutletsCodesForm.scss"

function AdminOutletsCodesForm({ mode }) {
    let history = useHistory()
    const { token } = useContext(UserContext)
    const { outletid, codeid } = useParams()

    const individualCodeInput = useRef()

    const [issuedByUserIsAdmin, setIssuedByUserIsAdmin] = useState(false)

    const [formCodes, setFormCodes] = useState([])

    const [code, setCode] = useState("")
    const [email, setEmail] = useState("")

    const createMode = mode === "create"
    const issueMode = mode === "issue"
    const detailsMode = mode === "details"


    useEffect(() => {
        if (token != null && (detailsMode || issueMode)) {
            getCode()
        }

    }, [token])

    const getCode = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}/codes/${codeid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.code !== null) {
                setCode(response.data.code)

                if (response.data.code.issued_by?.email != null) {
                    setIssuedByUserIsAdmin(process.env.REACT_APP_ADMIN_EMAILS.split(",").includes(response.data.code.issued_by.email))
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }
    
    const createRedemptionCode = async () => {

        const sendData = {
            "codes" : formCodes
        }

        try {
            const response = await trackPromise(axios.put(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}/codes`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.codes !== null) {
                history.push(`/admin/outlets/${outletid}/codes/`)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const issueRedemptionCode = async () => {

        const sendData = {
            "email": email
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}/codes/${codeid}/issue`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.code !== null) {
                history.push(`/admin/outlets/${outletid}/codes/`)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const cancelRedemptionCode = async () => {

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}/codes/${codeid}/cancel`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.code !== null) {
                history.push(`/admin/outlets/${outletid}/codes/`)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitCodesForm = (e) => {
        e.preventDefault()

        if (createMode && formCodes.length === 0) {
            alert("Individual code or import from csv file is required")
            return false
        }

        if (issueMode && email.trim().length === 0) {
            alert("Email address is required")
            return false
        }

        if (createMode) {
            createRedemptionCode()
        }

        if (issueMode) {
            issueRedemptionCode()
        }

        if (detailsMode) {
            cancelRedemptionCode()
        }

        return true
    }

    const readCSVFile = (data, fileInfo) => {
        individualCodeInput.current.value = ""
        let csvCodes = []

        for (let i = 0; i < data.length; i++) {
            const rows = data[i]
            for (let j = 0; j < rows.length; j++) {

                const aCode = {
                    "code": rows[j].toString()
                }

                csvCodes.push(aCode)
            }
        }

        setFormCodes(csvCodes)
    }

    const csvOptions = {
        header: false,
        skipEmptyLines: true
      }

    return (
        <div>
            { createMode && 
                <form className="admin-outlets-codes-form" onSubmit={submitCodesForm}>

                    <div>
                        <label>Individual Code:</label>
                        <input ref={individualCodeInput} placeholder="Enter a code" onChange={(e) => setFormCodes([{"code" : e.target.value}])}></input>
                    </div>

                    <label style={{textAlign: "center", marginBottom: "10px"}}>or</label>

                    <div>
                        <label>Select a .cvs File:</label>
                        <CSVReader parserOptions={csvOptions} onFileLoaded={(data, fileInfo) => readCSVFile(data, fileInfo)} />
                    </div>

                    <div className="mar-vert-24">
                        <SubmitBtn text={"Add"} height={38} width={72} />
                    </div>

                </form>
            }

            { issueMode && 
                <form className="admin-outlets-codes-form" onSubmit={submitCodesForm}>

                    <div>
                        <label>Redemption Code:</label>
                        <input value={code.code} readOnly={true}></input>
                    </div>

                    <div>
                        <label>Email Address:</label>
                        <input placeholder="Enter an email address" onChange={(e) => {setEmail(e.target.value)}}></input>
                    </div>


                    <div className="mar-vert-24">
                        <SubmitBtn text={"Issue"} height={38} width={72} />
                    </div>

                </form>
            }

            { detailsMode && 
                <form className="admin-outlets-codes-form" onSubmit={submitCodesForm}>


                    <div>
                        <label>Redemption Code:</label>
                        { code.code != null && <span>{code.code}</span> }
                    </div>

                    <div>
                        <label>Email Address:</label>
                        { code.claimed_by != null && <span>{ code.claimed_by.email }</span> }
                    </div>

                    <div>
                        <label>Date Issued:</label>
                        { code.issued_date != null && <span><Moment format={"MM/DD/YY @ hh:mma"}>{code.issued_date}</Moment></span> }
                    </div>

                    <div>
                        <label>Issued By:</label>
                        { code.issued_by != null && <span>{code.issued_by.email} {issuedByUserIsAdmin ? "(support)" : ""} </span> }
                    </div>


                    <div className="mar-vert-24">
                        { code.valid === true && <SubmitBtn text={"Cancel"} height={38} width={72} customBkgHex={"#bd1b11"} /> }
                        { code.valid === false && <label style={{color: "#bd1b11", textAlign: "center", fontSize: "17px"}}>Canceled</label> }
                    </div>

                </form>
            }
        </div>
    )
}

export default AdminOutletsCodesForm
