import React from 'react'
import { useState, useEffect, useContext, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import SubmitBtn from "../../Shared/SubmitBtn"

import "../../Styles/adminOutletsForm.scss"

function AdminOutletsForm({ mode }) {
    let history = useHistory()
    const { token } = useContext(UserContext)
    const { outletid } = useParams()

    const [outletTitle, setOutletTitle] = useState("")
    const [outletSubTitle, setOutletSubTitle] = useState("")
    const [outletFileName, setOutletFileName] = useState("")
    const [outletDescription, setOutletDescription] = useState("")
    const [outletAvailableNationWide, setOutletAvailableNationWide] = useState(null)
    const [outletLocations, setOutletLocations] = useState([])
    const [outletWebsite, setOutletWebsite] = useState("")
    const [outletRedemptionText, setOutletRedemptionText] = useState("")

    const createMode = mode === "create"
    const editMode = mode === "edit"

    const states = ["AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"]
    const stateSelectRef = useRef()
    const cityInputRef = useRef()


    useEffect(() => {

        if (editMode && token !== null && outletid !== null) {
            getOutlet()
        }

    }, [token])

    const getOutlet = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.outlet !== null) {
                setOutletTitle(response.data.outlet.name)
                setOutletSubTitle(response.data.outlet.sub_title)
                setOutletDescription(response.data.outlet.description)
                setOutletAvailableNationWide(response.data.outlet.nationwide === true ? "true" : "false")
                
                if (response.data.outlet.locations != null) {
                    setOutletLocations(response.data.outlet.locations)
                }else{
                    setOutletLocations([])
                }
                
                
                setOutletWebsite(response.data.outlet.website)
                setOutletRedemptionText(response.data.outlet.redemption_text)

                const fileName = response.data.outlet.logo.replace("outlets/", "")
                setOutletFileName(fileName)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createOutlet = async () => {
        const sendData = {
            "outlet": {
                "name": outletTitle,
                "sub_title": outletSubTitle,
                "logo": `outlets/${outletFileName}`,
                "description": outletDescription,
                "nationwide": outletAvailableNationWide === "true" ? true : false,
                "locations": outletLocations,
                "website": outletWebsite,
                "redemption_text": outletRedemptionText
            }
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/outlets`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.outlet !== null) {
                history.push("/admin/outlets")
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const editOutlet = async () => {
        const sendData = {
            "outlet": {
                "name": outletTitle,
                "sub_title": outletSubTitle,
                "logo": `outlets/${outletFileName}`,
                "description": outletDescription,
                "nationwide": outletAvailableNationWide === "true" ? true : false,
                "locations": outletLocations,
                "website": outletWebsite,
                "redemption_text": outletRedemptionText
            }
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/admin/outlets/${outletid}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.outlet !== null) {
                history.push("/admin/outlets")
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitOutletForm = async (e) => {
        e.preventDefault()

        if (outletTitle.trim().length === 0) {
            alert("Outlet Title cannot be empty")
            return false
        }

        if (outletSubTitle.trim().length === 0) {
            alert("Outlet Sub Title cannot be empty")
            return false
        }

        if (outletFileName.trim().length === 0) {
            alert("Outlet Logo cannot be empty")
            return false
        }

        if (outletDescription.trim().length === 0) {
            alert("Outlet Description cannot be empty")
            return false
        }

        if (outletAvailableNationWide === null || outletAvailableNationWide.length === 0) {
            alert("Outlet Available Nationwide is required")
            return false
        }

        if (outletAvailableNationWide === "false" && (outletLocations === null || outletLocations.length === 0)) {
            alert("At least 1 location must be added when the outlet is not available nationwide")
            return
        }

        if (outletWebsite.trim().length === 0) {
            alert("Outlet Website cannot be empty")
            return false
        }
        
        if (outletRedemptionText.trim().length === 0) {
            alert("Outlet Redemption Text cannot be empty")
        }

        if (createMode) {
            createOutlet()
        }

        if (editMode) {
            editOutlet()
        }

        return true
    }

    const addLocation = () => {
        const selectedState = stateSelectRef.current.value
        const cityText = cityInputRef.current.value.trim()

        let stateAlreadyExists = false
        for (let i = 0; i < outletLocations.length; i++) {
            const aLocation = outletLocations[i]
            if (aLocation.state === selectedState) {
                stateAlreadyExists = true
                break
            }
        }

        if (stateAlreadyExists) {
            alert("A Location for that state already exists. If you'd like to add/remove cities to that State, please delete the Location and add it again with the updated cities.")
            return
        }

        if (cityText.length === 0) {
            alert("Cities text must be provided in order to add a Location")
            return
        }

        let newLocations = [...outletLocations]
        newLocations.push(
            {
                "state": selectedState,
                "city": cityText
            }
        )

        setOutletLocations(newLocations)
    }

    const removeLocation = (location) => {
        let newLocations = [...outletLocations]
        newLocations.splice(location, 1)

        setOutletLocations(newLocations)
    }

    const redemptionCodesButtonClicked = () => {
        history.push(`/admin/outlets/${outletid}/codes`)
    }

    return (
        <div>
            <form className="admin-outlets-form" onSubmit={submitOutletForm}>
                
                { editMode && 
                    <div className="admin-outlets-form-codes">
                        <button type="button" onClick={() => {redemptionCodesButtonClicked()}}>Manage Redemption Codes</button>
                    </div>
                }

                <div>
                    <label>Outlet Title:</label>
                    <input value={outletTitle} placeholder="Enter outlet title" onChange={(e) => setOutletTitle(e.target.value)}></input>
                </div>

                <div>
                    <label>Sub Title:</label>
                    <input value={outletSubTitle} placeholder="Enter outlet sub title" onChange={(e) => setOutletSubTitle(e.target.value)}></input>
                </div>

                <div>
                    <label>Logo File Name:</label>
                    <input value={outletFileName} placeholder="Enter outlet file name" onChange={(e) => setOutletFileName(e.target.value)}></input>
                </div>

                <div>
                    <label>Description:</label>
                    <textarea value={outletDescription} placeholder="Enter outlet description" onChange={(e) => setOutletDescription(e.target.value)}></textarea>
                </div>

                <div>
                    <label>Available Nationwide:</label>
                    <select onChange={(e) => setOutletAvailableNationWide(e.target.value)}>
                        <option value="">Please select yes or no</option>
                        <option value="true" selected={outletAvailableNationWide === "true"}>yes</option>
                        <option value="false" selected={outletAvailableNationWide === "false"}>no</option>
                    </select>
                </div>

                { outletAvailableNationWide === "false" &&
                    
                    <div>
                        <hr></hr>
                        <div>
                            <label>Select State:</label>
                            <select ref={stateSelectRef}>
                                <option value="">Please select a state</option>

                                { states.map((state, index) => (
                                    <option value={`${state}`}>{state}</option>
                                )) }
                            </select>
                        </div>

                        <div>
                            <label>Cities Text:</label>
                            <input ref={cityInputRef} placeholder="Enter cities text"></input>
                            <button className="admin-outlets-location-btn" type="button" onClick={() => {addLocation()}}>Add</button>
                        </div>

                        
                        { (outletLocations != null && outletLocations.length > 0) &&
                            <div className="admin-outlets-location-list">
                                <span>Locations:</span>
                                { outletLocations.map((location, index) => (
                                    <div>
                                        <label>{location.state} - {location.city}</label>
                                        <button type="button" onClick={() => {removeLocation(location)}}>Delete</button>
                                    </div>
                                )) }      
                            </div>
                        }
                        <hr></hr>
                    </div>
                }

                <div>
                    <label>Website:</label>
                    <input value={outletWebsite} placeholder="Enter outlet website" onChange={(e) => setOutletWebsite(e.target.value)}></input>
                </div>

                <div>
                    <label>Redemption Text:</label>
                    <input value={outletRedemptionText} placeholder="Enter outlet redemption text" onChange={(e) => setOutletRedemptionText(e.target.value)}></input>
                </div>

                <div className="mar-vert-24">
                    <SubmitBtn text={"Save"} height={38} width={72} />
                </div>

            </form>
        </div>
    )
}

export default AdminOutletsForm
