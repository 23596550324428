import React from 'react'
import { useHistory, useParams } from "react-router-dom"

function AdminOutletsCodesCell({ code }) {
    let history = useHistory()
    const { outletid } = useParams()

    const codeCellClicked = () => {
        const endPoint = code.issued_by != null ? "details" : "issue"
        history.push(`/admin/outlets/${outletid}/codes/${code._id}/${endPoint}`)
    }

    return (
        <tr onClick={codeCellClicked}>
            <td className="admin-outlets-codes-cell">
                <div className="admin-outlets-codes-cell-content">
                    <div><span>{ code.code }</span></div>
                    <div>
                        { code.claimed_by != null &&
                            <label>{code.claimed_by.email}</label>
                        }
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletsCodesCell