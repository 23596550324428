import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { useEffect, useState, Fragment } from 'react'
import Honeybadger from "@honeybadger-io/js"
import axios from "axios"
import Pusher from "pusher-js"
import { UserProvider, PusherProvider } from "./components/Utils/context"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Helmet } from "react-helmet"

import Header from "./components/Shared/Header"
import LoadingOverlay from 'react-loading-overlay'
import { use100vh } from 'react-div-100vh'
import ScrollToTop from "./components/Utils/ScrollToTop"

import NotFoundPage from "./pages/404"
import LandingPage from "./pages/LandingPage"
import RegisterPage from "./pages/RegisterPage"
import HelpPage from "./pages/HelpPage"
import ProfilePage from "./pages/ProfilePage"
import SearchPage from "./pages/SearchPage"
import PostPage from "./pages/PostPage"
import CommentPage from "./pages/CommentPage"
import ProfileRelationsPage from "./pages/ProfileRelationsPage.js"
import NotificationPage from "./pages/NotificationPage"
import ProfilePostPage from "./pages/ProfilePostPage"
import MessagePage from "./pages/MessagePage"
import ReportPage from "./pages/ReportPage"
import StorePage from "./pages/StorePage"
import AdminPage from "./pages/AdminPage"
import PurchaseHistoryPage from "./pages/PurchaseHistoryPage"
import PointsPage from "./pages/PointsPage"
import RedeemPage from "./pages/RedeemPage"
import RedeemedPage from "./pages/RedeemedPage"
import TermsPage from "./pages/TermsPage"
import ContactPage from "./pages/ContactPage"
import AboutPage from "./pages/AboutPage"

import 'bootstrap/dist/css/bootstrap.min.css'



if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // Enable pusher logging - don't include this in production
  Pusher.logToConsole = true
}

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER
})

function App() {
    const mainContainerHeight = use100vh()
    const { promiseInProgress } = usePromiseTracker();

    const [user, setUser] = useState(() => {
      const localUser = localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-user`)
      return localUser ? JSON.parse(localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-user`)) : null
    })

    const [token, setToken] = useState(() => {
      const localToken = localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-token`)
      return localToken ? JSON.parse(localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-token`)) : null
    })

    const [forceLoadPostFeed, setForceLoadPostFeed] = useState(true)

    const [notifications, setNotifications] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)

    const [notifPage, setNotifPage] = useState(1)
    const notifPageLimit = 15
    const [hasMoreNotifPages, setHasMoreNotifPages] = useState(false)

    const [conversations, setConversations] = useState(null)
    const [conversationsCount, setConversationsCount] = useState(0)
    
    const [userIsAdmin, setUserIsAdmin] = useState(() => {
      return user != null ? process.env.REACT_APP_ADMIN_EMAILS.split(",").includes(user.email) : false
    })
    const [userIsBanned, setUserIsBanned] = useState(false)
    const [userBannedUntil, setUserBannedUntil] = useState("")
    const userOnboard = user && user.phone_confirmed && user.username && user.bio && user.photo_url
    const userProfileAccess = user && user.phone_confirmed && user.username

    const pusherChannel = user ? pusher.subscribe(`c_${user._id}`) : null

    useEffect(() => {
  
      // Handle Auth Token Expired
      if (token) {
        handleAuthTokenExpired(token.expires_at)
      }

      // User Handling
      if (user != null && !userIsBanned) {

        // Set User Context To HoneyBadger Errors
        Honeybadger.setContext({
          user_id: user._id != null ? user._id : "unknown",
          user_email: user.email != null ? user.email : "unknown",
          username: user.username != null ? user.username : "unknown"
        })

        // Check User is Admin
        setUserIsAdmin(process.env.REACT_APP_ADMIN_EMAILS.split(",").includes(user.email))

        // Handle User Notifications & Subscription
        if (userOnboard && token != null && (notifications === null || notifications.length === 0)) {
          subscribeToAndLoadNotifications()
        }

        // Handle User Conversations & Subscription
        if (userOnboard && token != null && conversations === null) {
          subscribeToAndLoadConversations()
        }

        // Update The Notification Count for Header Component Upon Receiving
        if (notifications != null && notifications.length > 0) {
          updateNotificationCount()
        }

        // Update The Conversations Count for Header Component Upon Receiving
        if (conversations != null && conversations.length > 0) {
          updateConversationsCount(conversations)
        }
      }

    }, [user, token, notifications, conversations])

    // Axios Error Handling Interceptor
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response != null && error.response.status != null && 420 === error.response.status) {
            setUserBanned(error.response.data)
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    });

    /* Auth Funcs */
    const handleAuthTokenExpired = (tokenExpireString) => {
      const expireDate = new Date(tokenExpireString)
      const dateNow = new Date()

      if (expireDate <= dateNow) {
        localStoreUserData(null);
      }
    }

    const setUserBanned = async (bannedUntil) => {
        localStoreUserData(null)
        await setUserIsBanned(true)
        await setUserBannedUntil(bannedUntil)
    }

    const localStoreUserData = (data) => {
        if (data != null) {
          // User Logged in / Updated
          if (data.user != null) {
            localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-user`, JSON.stringify(data.user))
            setUser(data.user)
          }

          if (data.token != null) {
            localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-token`, JSON.stringify(data.token))
            setToken(data.token)
          }
          
        }else{
          // User Logged out
          if (pusher != null && user != null) {
            pusher.unsubscribe(`c_${user._id}`)
          }

          localStorage.removeItem(`${process.env.REACT_APP_CACHE_PREFIX}-user`)
          localStorage.removeItem(`${process.env.REACT_APP_CACHE_PREFIX}-token`)

          setUser(null)
          setToken(null)

          setNotifications([])
          setNotificationCount(0)

          setConversations(null)
          setConversationsCount(0)
          
        }
    }

    /* Notification Funcs */
    const subscribeToAndLoadNotifications = () => {
      retrieveNotifications(false)
      pusherChannel.bind("notification", notificationEventCallback)
    }

    const notificationEventCallback = (data) => {
      setNotifications(notifications => [...new Set([data ,...notifications])])
    }

    const removeNotification = (notif) => {
      const newNotifs = [...notifications]
      const notifIndex = newNotifs.indexOf(notif)
      if (notifIndex !== -1) {
        newNotifs.splice(notifIndex, 1)
        setNotifications(newNotifs)
      }
    }

    const retrieveNotifications = async (refresh, markAsSeen) => {

          axios.get(
            `${process.env.REACT_APP_BASE_API}/notifications?page=${!refresh ? notifPage : 1}&limit=${notifPageLimit}`,
            { headers: { 'authorization': 'Bearer ' + token.access_token } }
          ).then(response => {

            if (response.data.unseen_count) {
              setNotificationCount(response.data.unseen_count)
            }

            if (response.data.notifications != null) {
              let newNotifications = response.data.notifications.docs

              if (newNotifications != null) {
                  const hasNextPage = response.data.notifications.hasNextPage
                  const nextPage = response.data.notifications.nextPage

                  
              
                  if (notifPage === 1 || refresh || notifications === null) {
                    setNotifications(newNotifications)
                  }else if (notifPage > 1) {
                    setNotifications(notifications.concat(newNotifications))
                  }

                  if (markAsSeen != null && markAsSeen === true) {
                    markNotifsAsSeen()
                  }
                  
  
                  if (hasNextPage != null) {
                      
                      setNotifPage(nextPage)
                      setHasMoreNotifPages(hasNextPage)
                  }
              }
          }

          }).catch(error => {
            throw(error)
          })
    }

    const updateNotificationCount = () => {
      let unseenNotifs = 0
      for (let i = 0; i < notifications.length; i++) {
        const aNotif = notifications[i]
        if (aNotif.seen != null && aNotif.seen === false) {
          unseenNotifs++
        }
      }

      setNotificationCount(unseenNotifs)
    }

    const handleNotificationCount = (count) => {
      setNotificationCount(count)
    }

    const markNotifsAsSeen = async() => {
      try {
          const response = await trackPromise(axios.patch(
              `${process.env.REACT_APP_BASE_API}/notifications/seen`,
              {},
              { headers: { 'authorization': 'Bearer ' + token.access_token } }
          ))

          if (response != null) {
              handleNotificationCount(0)
          }

      }catch (error) {
          if (error.response?.data?.message != null) {
              alert(error.response.data.message)
          }
          throw(error)
      }
  }


    /* Messages Funcs */
    const subscribeToAndLoadConversations = () => {
      retrieveConversations()
      pusher.bind("messages", conversationsEventCallback)
    }

    const conversationsEventCallback = (data) => {
      setConversations(data)
    }

    const retrieveConversations = async () => {

      trackPromise(axios.get(
        `${process.env.REACT_APP_BASE_API}/conversations`,
        { headers: { 'authorization': 'Bearer ' + token.access_token } }
      ).then((response) => {
          if (response.data.conversations != null) {
              setConversations(response.data.conversations)
              updateConversationsCount(response.data.conversations)
          }
      }).catch((error) => {
          if (error.response?.data?.message != null) {
              alert(error.response.data.message)
          }
          throw(error)
      }))
  }

  const updateConversationsCount = (convos) => {
    let unreadConvos = 0
    for (let i = 0; i < convos.length; i++) {
      const aConvo = convos[i]
      if (aConvo.last_message != null && aConvo.last_message.read != null) {

        const readIndex = aConvo.last_message.read.indexOf(user._id)
        if (readIndex === -1) {
          unreadConvos++
        }
      }
    }

    setConversationsCount(unreadConvos)
  }

  const updateConversation = (convo) => {
    if (conversations != null) {
      let newConversations = []
      for (let i = 0; i < conversations.length; i++) {
        let aConvo = conversations[i]
        if (aConvo._id === convo._id) {
          newConversations.push(convo)
        }else{
          newConversations.push(aConvo)
        }
      }
  
      setConversations(newConversations)
      updateConversationsCount(newConversations)
    }
  }

  const disableForceLoadPostFeed = () => {
    setForceLoadPostFeed(false)
  }

  return (
    <UserProvider user={user} token={token}>
      <PusherProvider pusher={pusher}>
          <div className="main-app-container" style={{minHeight: `${mainContainerHeight}px`}}>
            <div className="sub-app-container">
              <Helmet>
                <title>Do Better</title>
                <meta name="description" content="Social cannabis. For a cause. Help communities around the world. Earn your own rewards. Let's do better." />
              </Helmet>
              <LoadingOverlay
                active={promiseInProgress}
                spinner
                text=""
              >
                <Router>
                  <Header localStoreUserData={localStoreUserData} notificationCount={notificationCount} conversationsCount={conversationsCount} userIsAdmin={userIsAdmin} />
                  <Fragment>
                    <ScrollToTop />
                    <Switch>

                      {/* No User Routes */}
                      {!user && <Route exact path="/" render={() => (
                        <LandingPage localStoreUserData={localStoreUserData} userIsBanned={userIsBanned} userBannedUntil={userBannedUntil} />
                      )} /> }
                      {!userOnboard && <Route exact path="/register" render={() => (
                        <RegisterPage localStoreUserData={localStoreUserData}  />
                      )} /> }

                      <Route exact path="/help" component={HelpPage} />
                      <Route exact path="/terms" component={TermsPage} />
                      <Route exact path="/contact" component={ContactPage} />
                      <Route exact path="/about" component={AboutPage} />

                      {/* User Onboard Routes */}
                      { userProfileAccess && <Route exact path="/profile/:userid" render={() => (
                        <ProfilePage localStoreUserData={localStoreUserData} />
                      )} /> }

                      { userOnboard && <Route exact path="/profile/:userid/posts" render={() => (
                        <ProfilePostPage />
                      )} /> }

                      { userOnboard && <Route exact path="/profile/:userid/:type" render={() => (
                        <ProfileRelationsPage />
                      )} /> }

                      { userOnboard && <Route exact path="/feed" render={() => (
                        <PostPage mode={"view"} presentStyle={"feed"} forceLoadPostFeed={forceLoadPostFeed} disableForceLoadPostFeed={disableForceLoadPostFeed} key={document.location.href}  />
                      )} /> }

                      { userOnboard && <Route exact path="/posts/:postid" render={() => (
                        <PostPage mode={"view"} key={document.location.href} />
                      )} /> }

                      { userOnboard && <Route exact path="/create" render={() => (
                        <PostPage mode={"create"} key={document.location.href} />
                      )} /> }

                      { userOnboard && <Route exact path="/posts/:postid/comments" component={CommentPage} /> }
                      { userOnboard && <Route exact path="/search" component={SearchPage} /> }
                      { userOnboard && <Route exact path="/report/:content/:userid" component={ReportPage} key={document.location.href} /> }
                      { userOnboard && <Route exact path="/report/:content/:userid/:contentid" component={ReportPage} key={document.location.href} /> }
                      { userOnboard && <Route exact path="/purchase-history" component={PurchaseHistoryPage} /> }
                      { userOnboard && <Route exact path="/points" component={PointsPage} /> }
                      { userOnboard && <Route exact path="/points/redeem" component={RedeemPage} /> }
                      { userOnboard && <Route exact path="/points/redeemed/:redemptionid" component={RedeemedPage} /> }

                      { userOnboard && <Route exact path="/messages" render={() => (
                        <MessagePage mode={"view"} conversations={conversations} retrieveConversations={retrieveConversations} updateConversation={updateConversation} key={document.location.href} />
                      )} /> }

                      { userOnboard && <Route exact path="/messages/create" render={() => (
                        <MessagePage mode={"create"} updateConversation={updateConversation} key={document.location.href} />
                      )} /> }
                      
                      { userOnboard && <Route exact path="/messages/:userid" render={() => (
                        <MessagePage mode={"converse"} updateConversation={updateConversation} key={document.location.href} />
                      )} /> }
                      
                      { userOnboard && <Route exact path="/notifications" render={() => (
                        <NotificationPage notifications={notifications} handleNotificationCount={handleNotificationCount} retrieveNotifications={retrieveNotifications} removeNotification={removeNotification} hasMoreNotifPages={hasMoreNotifPages} key={document.location.href} />
                      )} /> }

                      { userOnboard && <Route exact path="/store" render={() => (
                        <StorePage mode={"front"} />
                      )} /> }

                      { userOnboard && <Route exact path="/store/success" render={() => (
                        <StorePage mode={"success"} />
                      )} /> }

                      { userOnboard && <Route exact path="/store/canceled" render={() => (
                        <StorePage mode={"canceled"} />
                      )} /> }

                      { userOnboard && <Route exact path="/store/:category" render={() => (
                        <StorePage mode={"category"} />
                      )} /> }


                      {/* Admin Routes */}
                      { (userOnboard && userIsAdmin) && <Route exact path="/admin" render={() => (
                        <AdminPage mode={"options"} key={document.location.href} />
                      )} /> }
                      
                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/badges" render={() => (
                        <AdminPage mode={"badges"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/badges/create" render={() => (
                        <AdminPage mode={"badges-create"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/badges/:badgeid" render={() => (
                        <AdminPage mode={"badges-edit"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/users" render={() => (
                        <AdminPage mode={"users"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/users/:userid" render={() => (
                        <AdminPage mode={"users-detail"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/content" render={() => (
                        <AdminPage mode={"content"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/content/create" render={() => (
                        <AdminPage mode={"content-create"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/content/:contentid" render={() => (
                        <AdminPage mode={"content-edit"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/one-hitters" render={() => (
                        <AdminPage mode={"one-hitters"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/one-hitters/create" render={() => (
                        <AdminPage mode={"one-hitters-create"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/one-hitters/:onehitterid" render={() => (
                        <AdminPage mode={"one-hitters-edit"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets" render={() => (
                        <AdminPage mode={"outlets"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets/create" render={() => (
                        <AdminPage mode={"outlets-create"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets/:outletid" render={() => (
                        <AdminPage mode={"outlets-edit"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets/:outletid/codes" render={() => (
                        <AdminPage mode={"outlets-codes"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets/:outletid/codes/create" render={() => (
                        <AdminPage mode={"outlets-codes-create"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets/:outletid/codes/:codeid/issue" render={() => (
                        <AdminPage mode={"outlets-codes-issue"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/outlets/:outletid/codes/:codeid/details" render={() => (
                        <AdminPage mode={"outlets-codes-details"} key={document.location.href} />
                      )} /> }

                      { (userOnboard && userIsAdmin) && <Route exact path="/admin/invoices" render={() => (
                        <AdminPage mode={"invoices"} key={document.location.href} />
                      )} /> }


                      {/* User Redirect Routes */}
                      { !user && <Redirect to="/" /> } {/* No User, Landing Page */}
                      { !userProfileAccess && <Redirect to="/register" /> } {/* User Not Onboard, Register Page */}
                      { (userProfileAccess && !userOnboard) && <Redirect to={`/profile/${user._id}`} /> } {/* User Incomplete Profile */}
                      { userOnboard && <Redirect to="/feed" /> } {/* User Onboard, Posts Page */}
                      

                      <Route path="/404" component={NotFoundPage} />
                      <Redirect to="/404" />
                    </Switch>
                  </Fragment>
                </Router>
              </LoadingOverlay>
            </div>
          </div>
      </PusherProvider>
    </UserProvider>
  );
}

export default App;
