import React from 'react'
import UserCell from "../Shared/UserCell"

import "../Styles/searchResult.scss"

function SearchResult({ searchResults, userClickLocation, verifyUser }) {

    return (
        <div className="search-result-container">
            <table className="search-result-table">
                <tbody>
                   <UserCell users={searchResults} userClickLocation={userClickLocation} verifyUser={verifyUser} />
                </tbody>
            </table>
        </div>
    )
}

export default SearchResult