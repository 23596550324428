import React from 'react'

import "../Styles/profileActionItem.scss"

function ProfileActionItem({ type, count, presentedWithImage }) {

    const labelStyle = {
        lineHeight: presentedWithImage ? "8px" : "20px"
    }

    return (
        <div className="profile-action-item">
            <label style={labelStyle}>
                <span>{count}</span>
                <br></br>
                {type}
            </label>
        </div>
    )
}

export default ProfileActionItem
