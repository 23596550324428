import React from 'react'
import { useHistory } from "react-router-dom"
import AwsImage from '../Shared/AwsImage'

import "../Styles/storeFrontCell.scss"

function StoreFrontCell({ item }) {
    let history = useHistory()

    const viewClicked = (location) => {
        history.push(location)
    }

    return (
        <tr>
            <td className="store-front-cell">
                <div>
                    <AwsImage fileKey={item.imageFileKey} />
                    <button onClick={() => viewClicked(item.location)}>View</button>
                </div>
                <div className="store-front-cell-divider"></div>
            </td>
        </tr>
    )
}

export default StoreFrontCell
