import React from 'react'
import { useState, useEffect, useContext } from "react"
import { UserContext } from "../components/Utils/context"
import { useHistory, useParams } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import AwsImage from "../components/Shared/AwsImage"

import "../components/Styles/redeemedPage.scss"

function RedeemedPage() {
    let history = useHistory()
    const { redemptionid } = useParams()

    const { token } = useContext(UserContext)

    const [redemption, setRedemption] = useState(null)

    useEffect(() => {
        
        if (token != null) {
            getRedemption()
        }
    }, [token])

    const getRedemption = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/me/points/redemption?redemption_id=${redemptionid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.redemption != null) {
                setRedemption(response.data.redemption)
            }


        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const redeemedPointsButtonClicked = () => {
        history.push({
            pathname: "/points",
            state: { withTableFilter: "redeemed" }
        })
    }

    return (
        <div className="redeemed-container">
            <button className="redeemed-back-button" type="button" onClick={() => {redeemedPointsButtonClicked()}}>{"< Redeemed points"}</button>

            { redemption != null &&
                <table className="redeemed-table">
                    <tbody>
                        <tr>
                            <td class="redeem-outlet-cell">
                                <div>
                                    <div class="redeemed-outlet-cell-title">
                                        <label>{redemption.outlet.name}</label>
                                        <span>({redemption.outlet.sub_title})</span>
                                    </div>
                                    <div class="redeem-outlet-cell-image-container">
                                        <AwsImage fileKey={redemption.outlet.logo} />
                                    </div>
                                    <div class="redeemed-outlet-cell-code-info">
                                        <label>
                                            Use This Redemption Code:
                                            <span>{redemption.redemption_code}</span>
                                        </label>
                                        
                                        <label style={{marginTop: "50px"}}>
                                            Website:
                                            <a href={redemption.outlet.website} target="_blank">{redemption.outlet.website}</a>
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}

export default RedeemedPage
