import React from 'react'
import { useEffect, useState } from "react"
import AwsImage from "../Shared/AwsImage"
import PadLockImage  from "../Images/store/padlock.png"
import DialogModal from "../Shared/DialogModal"


import "../Styles/storeCategoryItemCell.scss"

function StoreCategoryItemCell({ item, category, stripeCheckout, ownedItems, itemPrices }) {
    const [showDialogModal, setShowDialogModal] = useState(false)

    useEffect(() => {
        styleDiamondLayout()
    }, [])

    const padLockPressed = () => {
        setShowDialogModal(true)
    }

    const buyNowClicked = () => {
        const alreadyOwned = itemOwned()
        if (alreadyOwned) {
            return
        }

        if (category != "one-hitters") {
            stripeCheckout(item.stripe_sub_id)
        }else{
            stripeCheckout(item.stripe_id)
        }
    }

    const dialogModalClosed = () => {
        setShowDialogModal(false)
    }

    const dialogCustomItem = {
        headText: `Unlock this additional ${item.multiplier} multiplier by purchasing a Custom Cloud pack first.`,
        subText:  `Earn 5,000 points, and redeem your first bag!`,
        textClass: "store-category-item-dialog-text"
    }

    const itemOwned = () => {
        return ownedItems.includes(item._id)
    }

    const getItemPrice = (raw) => {
        let price = null

        for (let i = 0; i < itemPrices.length; i++) {
            const itemPrice = itemPrices[i]

            const currentItemID = category != "one-hitters" ? item.stripe_sub_id : item.stripe_id

            if (itemPrice.stripe_sub_id === currentItemID) {
                price = raw ? parseFloat((itemPrice.price / 100)) : `$${parseFloat((itemPrice.price / 100))}`
            }
        }

        return price
    }

    const getItemCharityPrice = () => {
        let charityPrice = null
        const itemPrice = getItemPrice(true)
        if (itemPrice != null) {
            charityPrice = `($${parseFloat((itemPrice / 2))} to charity)`
        }

        return charityPrice
    }

    const styleDiamondLayout = () => {
        if (category != "one-hitters") {
            const itemCellGrids = document.getElementsByClassName("store-category-item-cell-grid")
            for (let i = 0; i < itemCellGrids.length; i++) {
                const awsImageContainers = itemCellGrids[i].getElementsByClassName("awsimg-container")
                for (let j = 0; j < awsImageContainers.length; j++) {
                    const incIndex = j + 1
                    const isEven = !(incIndex % 2)

                    if (j === awsImageContainers.length - 1) {
                        if (isEven === false) {
                            awsImageContainers[j].style.width = "100%"
                        }
                    }
                }
            }

        }
    }

    return (
        <tr key={item._id}>
            <td className="store-category-item-cell">
                { category != "one-hitters" && 
                    <div>
                        <div>
                            <div className="store-category-item-cell-title">
                                <div>
                                    <label>{item.title}</label>
                                    <span>(1 like = {item.multiplier} points)</span>
                                </div>

                            </div>
                        </div>

                        <div className="store-category-item-cell-grid">                        
                            <div className="store-category-item-cell-cloudset-1">
                                { item.items.map((item, index) => (
                                    <AwsImage fileKey={item.image} customStyle={{maxHeight: category === "clouds" ? "90px" : "126px"}} />
                                )) }
                            </div>
                        </div>

                        <div className="store-category-item-cell-multiplier">
                            <label>{item.multiplier}x</label>

                            <div className="store-category-item-cell-buy" onClick={buyNowClicked}>
                                
                                { itemOwned() && <label className="owned-item">Purchased</label>}

                                { (category != "stickers" && (!itemOwned() && getItemPrice() != null)) && <span>{getItemPrice()}</span> }
                                { (category === "stickers" && (!itemOwned() && getItemPrice() != null) && ownedItems.length != 0) && <span>{getItemPrice()}</span> }
                                
                                { (category != "stickers" && !itemOwned()) && <label>Buy Now</label> }
                                { (category === "stickers" && ownedItems.length != 0 && !itemOwned()) && <label>Buy Now</label> }

                                
                                { (category === "stickers" && ownedItems.length === 0) && 
                                    <div className="store-category-item-cell-padlock" onClick={(e) => {e.stopPropagation(); padLockPressed()}}>
                                        <img src={PadLockImage}></img>
                                    </div>
                                }
                            </div>
                        </div>

                        <hr></hr>

                        {showDialogModal && <DialogModal closeAction={dialogModalClosed} customItem={dialogCustomItem} /> }
                    </div>
                }

                { category === "one-hitters" &&
                    <div>
                        
                        <div className="store-category-item-cell-title">
                            <label>{item.name}</label>
                        </div>

                        <div className="store-category-item-cell-cloudset-3">
                            <AwsImage fileKey={item.image} />
                        </div>

                        <div className="store-category-item-cell-desc">
                            <label>
                                {item.description}
                            </label>
                        </div>

                        <div className="store-category-item-cell-buy" onClick={buyNowClicked}>
                            { itemOwned() && <label className="owned-item">Purchased</label>}

                            { (!itemOwned() && getItemPrice() != null) && <span>{getItemPrice()} <span>{getItemCharityPrice()}</span></span> }
                            { !itemOwned() && <label>Buy Now</label> }
                            
                        </div>

                        <hr></hr>
                    </div>
                }
            </td>
        </tr>
    )
}

export default StoreCategoryItemCell
