import React from 'react'
import { useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../components/Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import AdminOptions from "../components/AdminPage/AdminOptions"
import AdminBadges from "../components/AdminPage/Badges/AdminBadges"
import AdminBadgeForm from "../components/AdminPage/Badges/AdminBadgeForm"
import AdminUsers from "../components/AdminPage/Users/AdminUsers"
import AdminUsersDetail from "../components/AdminPage/Users/AdminUsersDetail"
import AdminContent from "../components/AdminPage/Content/AdminContent"
import AdminContentForm from "../components/AdminPage/Content/AdminContentForm"
import AdminOneHitters from '../components/AdminPage/OneHitters/AdminOneHitters'
import AdminOneHitterForm from '../components/AdminPage/OneHitters/AdminOneHitterForm'
import AdminOutlets from "../components/AdminPage/Outlets/AdminOutlets"
import AdminOutletsForm from '../components/AdminPage/Outlets/AdminOutletsForm'
import AdminOutletsCodes from '../components/AdminPage/Outlets/AdminOutletsCodes'
import AdminOutletsCodesForm from '../components/AdminPage/Outlets/AdminOutletsCodesForm'
import AdminInvoices from "../components/AdminPage/Invoices/AdminInvoices"

import "../components/Styles/adminPage.scss"

function AdminPage({ mode }) {
    const { token } = useContext(UserContext)
    const { badgeid } = useParams()

	const optionsMode = mode === "options"

    /* Badge Modes */
    const badgesMode = mode === "badges"
    const badgesCreateMode = mode === "badges-create"
    const badgesEditMode = mode === "badges-edit"

    /* User Modes */
    const usersMode = mode === "users"
    const usersDetailMode = mode === "users-detail"


    /* Content Modes */
    const contentMode = mode === "content"
    const contentCreateMode = mode === "content-create"
    const contentEditMode = mode === "content-edit"

    /* One Hitter Modes */
    const oneHittersMode = mode === "one-hitters"
    const oneHittersCreateMode = mode === "one-hitters-create"
    const oneHittersEditMode = mode === "one-hitters-edit"

    /* Outlet Modes */
    const outletsMode = mode === "outlets"
    const outletsCreateMode = mode === "outlets-create"
    const outletsEditMode = mode === "outlets-edit"
    const outletsCodesMode = mode === "outlets-codes"
    const outletsCodesCreateMode = mode === "outlets-codes-create"
    const outletsCodesIssueMode = mode === "outlets-codes-issue"
    const outletsCodesDetailsMode = mode === "outlets-codes-details"

    /* Invoice Modes */
    const invoicesMode = mode === "invoices"

    const [showPageLabelText, setShowPageLabeltext] = useState(() => {
        return !usersMode
    })
	const [pageLabelText, setPageLabelText] = useState(() => {
        return optionsMode ? "Admin" : 
        badgesMode ? "Badges" :
        badgesCreateMode ? "New Badge" :
        badgesEditMode ? "Edit Badge" :
        usersMode ? "" :
        contentMode ? "Clouds / Stickers / Subs" :
        oneHittersMode ? "One Hitters" :
        oneHittersCreateMode ? "New One Hitter" :
        oneHittersEditMode ? "Edit One Hitter" :
        outletsMode ? "Outlets" :
        outletsCreateMode ? "New Outlet" :
        outletsEditMode ? "Edit Outlet" :
        outletsCodesMode ? "Redemption Codes" :
        outletsCodesCreateMode ? "Create Redemption Code" :
        outletsCodesIssueMode ? "Issue Code" :
        outletsCodesDetailsMode ? "Details" :
        invoicesMode ? "Invoices"
        : ""
    })

    const changePageLabelText = (text) => {
        setPageLabelText(text)
    }

    const deleteUser = async (email) => {
  
          try {
            const response = await trackPromise(axios.delete(
                `${process.env.REACT_APP_BASE_API}/admin/users/delete`,
                { 
                    headers: { 'authorization': 'Bearer ' + token.access_token },
                    data: {
                        "email": email
                    } 
                }
            ))
  
            if (response.data != null) {
                alert(`User (${email}) has been successfully Deleted`)
            }
  
        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }
	
    return (
        <div className="admin-page-container">
            { showPageLabelText && <label className="admin-page-label">{pageLabelText}</label> }

			{ optionsMode && <AdminOptions deleteUser={deleteUser} /> }
            
            {/* Badge Components */}
			{ badgesMode && <AdminBadges /> }
            { badgesCreateMode && <AdminBadgeForm mode={"create"} /> }
            { badgesEditMode && <AdminBadgeForm mode={"edit"} /> }

            {/* User Components */}
            { usersMode && <AdminUsers /> }
            { usersDetailMode && <AdminUsersDetail changePageLabelText={changePageLabelText} /> }

            {/* Content Components */}
            { contentMode && <AdminContent /> }
            { contentCreateMode && <AdminContentForm mode={"create"} /> }
            { contentEditMode && <AdminContentForm mode={"edit"} /> }

            {/* One Hitter Components */}
            { oneHittersMode && <AdminOneHitters /> }
            { oneHittersCreateMode && <AdminOneHitterForm mode={"create"} /> }
            { oneHittersEditMode && <AdminOneHitterForm mode={"edit"} /> }

            {/* Outlet Components */}
            { outletsMode && <AdminOutlets /> }
            { outletsCreateMode && <AdminOutletsForm mode={"create"} /> }
            { outletsEditMode && <AdminOutletsForm mode={"edit"} /> }
            { outletsCodesMode && <AdminOutletsCodes /> }
            { outletsCodesCreateMode && <AdminOutletsCodesForm mode={"create"} /> }
            { outletsCodesIssueMode && <AdminOutletsCodesForm mode={"issue"} /> }
            { outletsCodesDetailsMode && <AdminOutletsCodesForm mode={"details"} /> }

            {/* Invoice Components */}
            { invoicesMode && <AdminInvoices /> }
            
        </div>
    )
}

export default AdminPage
