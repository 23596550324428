import React from 'react'
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import { UserContext } from "../Utils/context"
import PostText from "../PostPage/PostText"
import AwsImage from "../Shared/AwsImage"
import DropDown from "../Shared/DropDown"
import AdminUsersDetailReport from "../AdminPage/Users/AdminUsersDetailReport"
import SuspendedImage from "../Images/profile-icons/suspended-image.png"
import VerifiedIcon from "../Images/profile-icons/verified.png"

import "../Styles/commentTable.scss"

function CommentCell({ comment, index, focusCommentID, focusCommentRef, currentUserLikedComment, toggleCommentLike, reportitem, admin, initialSuspension, reportUserSuspendedUntil }) {
    let history = useHistory()
    const { user } = useContext(UserContext)

    const userProfileClicked = (authorID) => {
        if (authorID != null) {
            history.push(`/profile/${authorID}`)
        }
    }

    const dropDownItems = [
        {
            title: "Report",
            url: `/report/comment/${comment.author._id}/${comment._id}`
        }
    ]

    return (
        <tr key={comment._id} id={`comm-${comment._id}`} ref={(focusCommentID === comment._id && index === 0) ? focusCommentRef : null}>
            <td className="comments-tablecell">

                
                <div className="comments-tc-content">
                    <div>
                        <AwsImage fileKey={comment.author.photo_url} customClassName={"comments-tc-profile"} customOnClick={(e) => userProfileClicked(comment.author._id)} customStyle={{cursor: "pointer"}} overrideImage={comment.author.suspended ? SuspendedImage : null} />
                        { comment.author != null && 
                            <div className="post-text-username">
                                <label>
                                    @{comment.author.username}
                                    { comment.author.verified === true &&
                                        <img src={VerifiedIcon}></img>
                                    }
                                </label>
                            </div> 
                        }
                    </div>
                    
                    <PostText mode={"view"} commentBubble={comment.bubble != null ? comment.bubble.image : null} commentValue={comment.caption} commentUsername={comment.author.username} currentUserLikedComment={currentUserLikedComment != null ? currentUserLikedComment(comment) : false} toggleCommentLike={toggleCommentLike} commentID={comment._id} commentLikeCount={comment.like_count != null ? comment.like_count : 0} commentCreatedAt={comment.createdAt} commentIsCurrentUser={comment.author._id === user._id} />
                
                    
                    <div>
                        { (comment.author._id != user._id && !admin) &&
                            <DropDown items={dropDownItems}/>
                        }
                    </div>
                </div>

                { (admin && reportitem != null) && 
                    <div style={{marginTop: "25px"}}>
                        <AdminUsersDetailReport reportitem={reportitem} initialSuspension={initialSuspension} reportUserSuspendedUntil={reportUserSuspendedUntil} />
                    </div>
                }

            </td>
        </tr>
    )
}

export default CommentCell
