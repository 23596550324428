import React from 'react'
import _ from 'lodash';

import SearchIcon from "../Images/navigation-icons/search.png"
import "../Styles/searchBar.scss"

function SearchBar({ presentSearchView, getSearchResults, searchBarRef }) {

    const searchBarFocused = () => {
        presentSearchView("results")
    }

    const searchBarOnChange = _.debounce((e) => {
        if (e.target.value && e.target.value.length > 0) {
            getSearchResults(e.target.value)
        }
    }, 300)

    return (
        <div className="search-bar-container">
            <input className="search-bar" placeholder="Search" type="text" ref={searchBarRef} style={{backgroundImage: `url(${SearchIcon})`}} onFocus={searchBarFocused} onChange={searchBarOnChange}></input>
        </div>
    )
}

export default SearchBar
