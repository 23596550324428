import React from 'react'
import { useState, useEffect, useContext, useRef } from "react"
import { useHistory } from "react-router-dom"
import { UserContext } from "../Utils/context"
import { awsGetObject } from "../Utils/awsHandler"
import SelectionModal from "../Shared/SelectionModal"
import Moment from 'react-moment';
import RemoveBtn from "../Shared/RemoveBtn"
import AwsImage from "../Shared/AwsImage"
import loadImage from "blueimp-load-image/js"


import WhiteBackground from "../Images/white-background.jpg"
import VerifiedIcon from "../Images/profile-icons/verified.png"


import "../Styles/postImage.scss"

function PostImage({ post, mode, postImageChanged, postStickerChanged }) {
    const [showSelectionModal, setShowSelectionModal] = useState(false)
    const [selectedStickerImage, setSelectedStickerImage] = useState(null)

    const { user } = useContext(UserContext)
    let history = useHistory()
    const viewMode = mode === "view"
    const createMode = mode === "create"
    
    const [postImageClassName, setPostImageClassName] = useState(() => {
        return (viewMode && post != null) ? "post-image" : createMode ? "post-image create-post-image" : "post-image"
    })

    const postImageRef = useRef()
    const postImageFileInputRef = useRef()
    const postRemoveContainerRef = useRef()
    const postStickerRef = useRef()

    useEffect(() => {
        if (viewMode && post != null && post.sticker != null) {
            getSticker()
        }
    }, [])

    const getSticker = async () => {
        awsGetObject(post.sticker.image).then(res => {
            const base64 = res.Body.toString('base64')
            postStickerRef.current.setAttribute("src", `data:image;base64,${base64}`)

        }).catch(error => {
            
            //console.log(error)
            //throw(error)
        })
    }

    const addPhotoBtnClicked = () => {
        postImageFileInputRef.current.click()
    }

    const addStickerBtnClicked = () => {
        setShowSelectionModal(true)
    }

    const postImageFileInputChanged = (e) => {
        if (e.target.files[0] != null) {
            loadImage(e.target.files[0], 
                (img) => { 
                    var base64data = img.toDataURL('image/jpeg')
                    postImageRef.current.setAttribute("src", base64data)

                    setPostImageClassName("post-image")

                    postRemoveContainerRef.current.setAttribute("style", "display: block")
        
                    postImageChanged(base64data)
            }, { orientation: true, canvas: true } )
        }
    }

    const postImageRemoved = () => {
        postImageRef.current.setAttribute("src", WhiteBackground)
        setPostImageClassName("post-image create-post-image")

        postRemoveContainerRef.current.setAttribute("style", "display: none")
        postImageChanged(null)
    }

    const postStickerRemoved = () => {
        setSelectedStickerImage(null)
        postStickerChanged(null)
    }

    const stickerChoosen = (stickerID, stickerImage) => {
        setSelectedStickerImage(stickerImage)
        postStickerChanged(stickerID)
    }

    const selectionModalClosed = () => {
        setShowSelectionModal(false)
    }

    const stickerClicked = () => {
        // const win = window.open("https://indacutca.com/", "_blank");
        // if (win != null) {
        //   win.focus();
        // }
    }

    return (
        <div className="post-image-container">
            <div className="post-image-labels-container">
                { createMode && <label className="post-image-label post-image-username">
                    @{user.username}

                    {user.verified === true &&
                        <img src={VerifiedIcon}></img> 
                    }
                </label> }

                { (viewMode && post != null) && <label className="post-image-label post-image-username">
                    @{post.author.username}

                    {post.author.verified === true &&
                        <img src={VerifiedIcon}></img> 
                    }
                </label> }

                { (viewMode && post != null) && <label className="post-image-label" style={{float: "right"}}><Moment format="MM/DD @ hh:mma">{post.createdAt}</Moment></label> }
            </div>
            <div style={{position: "relative"}}>
                { (viewMode && post != null) && <AwsImage fileKey={post.media_url} customClassName={postImageClassName} customRef={postImageRef} customStyle={{backgroundColor: "#d4d4d4"}} /> }
                
                { createMode && 
                    <div className="post-image-removebtn-container" ref={postRemoveContainerRef} style={{display: "none"}}>
                        <RemoveBtn removeAction={postImageRemoved} />
                    </div>
                }
                { createMode && <img className={postImageClassName} ref={postImageRef}></img>}
                
                { (createMode && postImageClassName === "post-image create-post-image") && <button className="post-image-addbtn" type="button" onClick={addPhotoBtnClicked}>+ add a Photo</button> }
                { (createMode && postImageClassName === "post-image create-post-image") && <input ref={postImageFileInputRef} type="file" accept="image/*" style={{display: "none"}} onChange={postImageFileInputChanged}></input> }

                <div className="post-sticker-container">
                    { (viewMode && post != null && post.sticker != null) &&
                        <div onClick={stickerClicked}>
                            <img className="post-sticker" src={post.sticker.image} ref={postStickerRef}></img>
                        </div>
                    }
                    { createMode && 
                        <div>
                            { selectedStickerImage != null && <img className="post-sticker" src={selectedStickerImage}></img> }
                            { selectedStickerImage === null && <img className="post-sticker"></img> }
                            
                            { selectedStickerImage != null && 
                                <div className="post-sticker-removebtn-container">
                                    <RemoveBtn removeAction={postStickerRemoved} />
                                </div> 
                            }
                        </div>
                    }
                    { (createMode && selectedStickerImage === null) &&  <button className="post-sticker-addbtn" type="button" onClick={addStickerBtnClicked}>+ add a Sticker</button> }

                    { (createMode && showSelectionModal) && <SelectionModal closeAction={selectionModalClosed} chooseAction={stickerChoosen} selectionType={"stickers"} /> }
                </div>
            </div>
        </div>
    )
}

export default PostImage
