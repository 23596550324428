import userEvent from '@testing-library/user-event';
import React from 'react'
import { useContext } from 'react'
import { UserContext } from "../Utils/context"

import VerifiedIcon from "../Images/profile-icons/verified.png"

import "../Styles/profileUserInfo.scss"

function ProfileUserInfo({ username, bio, editModeEnabled, usernameChanged, bioChanged, verified }) {
    const { user } = useContext(UserContext)

    const handleUsernameChanged = (value) => {
        usernameChanged(value);
    }

    const handleBioChanged = (value) => {
        bioChanged(value);
    } 

    return (
        <div className="profile-userinfo-container">

            { !editModeEnabled ? (
                    <div>
                        <label className="profile-userinfo-username">
                            {username}
                            { verified === true &&
                                <img src={VerifiedIcon}></img>
                            }
                        </label>
                        <label className="profile-userinfo-bio">{bio}</label>
                    </div>
                ) : (
                    <div>
                        <label className="profile-userinfo-username">{username}</label>
                        <textarea className="profile-userinfo-field profile-userinfo-textarea" defaultValue={user.bio != null ? user.bio : ""} maxLength={224} placeholder="how do you describe yourself?" onChange={(e) => handleBioChanged(e.target.value)}></textarea>
                    </div>
                )
            }

        </div>
    )
}
export default ProfileUserInfo
