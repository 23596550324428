import React from 'react'
import { useHistory } from "react-router-dom"

function AdminContentCell({ content, contentType }) {
    let history = useHistory()

    const contentCellClicked = () => {
        history.push({
            pathname: `/admin/content/${content._id}`,
            state: { type: contentType }
        })
    }

    return (
        <tr onClick={contentCellClicked}>
            <td className="admin-content-cell">
                { contentType === "clouds" &&
                    <div className="admin-content-cell-content">
                        <div><span>{content.name}</span></div>
                        <div>x{content.multiplier}</div>
                        <div>{content.image.replace("clouds/", "")}</div>
                    </div>
                }

                { contentType === "stickers" &&
                    <div className="admin-content-cell-content">
                        <div><span>{content.name}</span></div>
                        <div>x{content.multiplier}</div>
                        <div>{content.image.replace("stickers/", "")}</div>
                    </div>
                }

                { contentType === "subs" &&
                    <div className="admin-content-cell-content">
                        <div><span>{content.title}</span></div>
                        <div>x{content.multiplier}</div>
                        <div>{content.onItem === "Bubble" ? `Clouds (${content.items.length})` : `Stickers (${content.items.length})`}</div>
                    </div>
                }
            </td>
        </tr>
    )
}

export default AdminContentCell
