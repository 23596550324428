import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom"
import { UserContext } from "../components/Utils/context"
import { awsPutObject } from "../components/Utils/awsHandler"
import axios from "axios"
import { trackPromise } from 'react-promise-tracker'
import InfiniteScroll from "react-infinite-scroll-component"
import PostCell from "../components/PostPage/PostCell"
import RefreshContent from "../components/Shared/RefreshContent"

import "../components/Styles/postPage.scss"

function PostPage({ mode, presentStyle, forceLoadPostFeed, disableForceLoadPostFeed }) {
    const history = useHistory()
    const location = useLocation()

    const { user, token } = useContext(UserContext)
    const viewMode = mode === "view"
    const createMode = mode === "create"

    /* View Mode States */
    const [page, setPage] = useState(() => {
        return localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-page`) != null ? parseInt(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-page`))) : 1
    })
    const pageLimit = 6
    const [hasMorePages, setHasMorePages] = useState(() => {
        return (localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-hasnextpage`) != null && JSON.parse(localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-hasnextpage`)) === "true")  ? true : false
    })

    const [postFeed, setPostFeed] = useState(() => {
        return localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-feed`) != null ? JSON.parse(localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-feed`)) : []
    })
    const [postFeedLoaded, setPostFeedLoaded] = useState(false)
    const [relatedPostsFeed, setRelatedPostsFeed] = useState(() => {
        return localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-relatedposts`) != null ? JSON.parse(localStorage.getItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-relatedposts`)) : []
    })
    const [post, setPost] = useState(null)

    /* Create Mode States */
    const [postImage, setPostImage] = useState(null)
    const [postText, setPostText] = useState(null)
    const [postMentions, setPostMentions] = useState(null)
    const [postCloud, setPostCloud] = useState(null)
    const [postCloudType, setPostCloudType] = useState(null)
    const [postSticker, setPostSticker] = useState(null)

    const { postid } = useParams()

    const [hideRelatedPosts, setHideRelatedPosts] = useState(() => {
        return location.state != null ? location.state.hideRelatedPosts : false
    })

    useEffect(() => {

        history.listen(location => {

            if (history.action === "PUSH" && location.pathname === "/feed") {
                window.scrollTo(0, 0)
                localStorage.removeItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-page`)
                localStorage.removeItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-feed`)
                localStorage.removeItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-hasnextpage`)
            }

            if (history.action === "PUSH" && (location.pathname.includes("/posts") && !location.pathname.includes("comments"))) {
                window.scrollTo(0, 0)
                localStorage.removeItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-relatedposts`)
            }

            if (history.action === "PUSH" && location.pathname.includes("/comments")) {
                window.scrollTo(0, 0)
            }
        })

        if (token != null) {
            if (postid != null && post === null) {
                retrievePost(postid)
            }

            if (location.pathname === "/feed") {
                if (postFeed.length === 0 || (location.state != null && location.state.freshpull != null) || forceLoadPostFeed) {
                    retrieveFeed(true)

                    if (forceLoadPostFeed) {
                        disableForceLoadPostFeed()
                    }
                }
            }
        }
    }, [token])

    const postTextChanged = (text) => {
        setPostText(text)
    }

    const postMentionsChanged = (mentions) => {
        let newMentions = mentions.map(m => m.id)

        setPostMentions(newMentions)
    }

    const postImageChanged = (file) => {
        setPostImage(file)
    }

    const postCloudChanged = (cloud, type) => {
        setPostCloud(cloud)
        setPostCloudType(type)
    }

    const postStickerChanged = (sticker) => {
        setPostSticker(sticker)
    }

    const retrieveFeed = async (refresh) => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/posts?feed_type=feed&page=${!refresh ? page : 1}&limit=${pageLimit}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            

            if (response.data.posts != null) {
                const newPosts = response.data.posts.docs

                if (newPosts != null) {
                    const hasNextPage = response.data.posts.hasNextPage
                    const nextPage = response.data.posts.nextPage
                
                    if (page === 1 || refresh) {
                        setPostFeed(newPosts)
                        localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-feed`, JSON.stringify(newPosts))
                    }else if (page > 1) {
                        setPostFeed(postFeed.concat(newPosts))
                        localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-feed`, JSON.stringify(postFeed.concat(newPosts)))
                    }

                    localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-page`, JSON.stringify(nextPage))
                    localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-hasnextpage`, JSON.stringify(hasNextPage === true ? "true" : "false"))
                    
                    
    
                    if (hasNextPage != null) {
                        
                        setPage(nextPage)
                        setHasMorePages(hasNextPage)
                    }
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }

        setPostFeedLoaded(true)
    }

    const refreshFeed = () => {
        retrieveFeed(true)
    }

    const retrievePost = async ()  => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/posts/${postid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.post._id != null) {
                setPost(response.data.post)
            }

            if (response.data.related_posts != null) {
                setRelatedPostsFeed(response.data.related_posts)
                localStorage.setItem(`${process.env.REACT_APP_CACHE_PREFIX}-post-relatedposts`, JSON.stringify(response.data.related_posts))
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const togglePostLike = async (liked, likedPostID) => {

        axios.put(
            `${process.env.REACT_APP_BASE_API}/posts/likes?post_id=${likedPostID}`,
            {},
            { headers: { 'authorization': 'Bearer ' + token.access_token } }
        ).catch(error => {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        })
    }

    const createPost = async (e) => {
        e.preventDefault()

        if (postText === null || postText.trim().length === 0) {
            alert("Post Text is required")
            return false
        }

        if (postImage === null) {
            alert("Post Image is required")
            return false
        }

        /* Make Call To S3 */
        let photo_url = ""
        try {

            const fileName = `users/${user._id}/posts/post_photo_${Date.now()}`
            let postPicBlob = await trackPromise(fetch(postImage).then(r => r.blob()));
            let file = new File([postPicBlob], fileName, { type: postPicBlob.type, lastModified: Date.now() })
            const uplRes = await trackPromise(awsPutObject(file, fileName))
            if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
                photo_url = fileName
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
        

        const caption = postText
        const media_url = photo_url
        const bubble = postCloud
        const onBubble = postCloudType
        const sticker = postSticker

        let sendData = {
            "post": {
                caption,
                media_url
            }
        }

        if (postCloud != null) {
            sendData["post"]["bubble"] = bubble
            sendData["post"]["onBubble"] = onBubble
        }

        if (postSticker != null) {
            sendData["post"]["sticker"] = sticker
        }

        if (postMentions != null && postMentions.length > 0) {
            sendData["post"]["mentions"] = postMentions
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/posts`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.post._id != null) {
                setPost(response.data.post)
                history.push({
                    pathname: "/feed",
                    state: { freshpull: true }
                })
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
        
    }

    return (
        <div className="post-container">
            
            { (presentStyle != null && presentStyle === "feed") ?
                <InfiniteScroll dataLength={postFeed.length} next={retrieveFeed} hasMore={hasMorePages} pullDownToRefresh refreshFunction={refreshFeed} pullDownToRefreshThreshold={80} pullDownToRefreshContent={<RefreshContent type={"pull"} />} releaseToRefreshContent={<RefreshContent type={"release"} />}>
                    <table id="post-feed-table" style={{marginTop: viewMode ? "12px" : "unset"}}>
                        <tbody>
                            { (postFeedLoaded && postFeed.length === 0) &&
                                <tr style={{borderBottom: "unset"}}>
                                    <td>
                                        <label className="post-feed-empty-label">Search and Follow more users to fill up your Home Feed.</label>
                                    </td>
                                </tr>
                            }


                            {postFeed.map((post, index) => (
                                <PostCell post={post} mode={mode} createMode={createMode} viewMode={viewMode} presentStyle={presentStyle} postImageChanged={postImageChanged} postStickerChanged={postStickerChanged} postTextChanged={postTextChanged} postMentionsChanged={postMentionsChanged} postCloudChanged={postCloudChanged} createPost={createPost} togglePostLike={togglePostLike} />
                            )) }
                        </tbody>
                    </table>
                </InfiniteScroll>
            :
            <InfiniteScroll dataLength={relatedPostsFeed.length}>
                <table id="post-feed-table" style={{marginTop: viewMode ? "12px" : "unset"}}>
                    <tbody>
                        <PostCell post={post} mode={mode} createMode={createMode} viewMode={viewMode} presentStyle={presentStyle} postImageChanged={postImageChanged} postStickerChanged={postStickerChanged} postTextChanged={postTextChanged} postMentionsChanged={postMentionsChanged} postCloudChanged={postCloudChanged} createPost={createPost} togglePostLike={togglePostLike} />
                    
                        { (!hideRelatedPosts && relatedPostsFeed.length > 0 && !createMode) &&
                            <tr className="post-feed-related-tablerow">
                                <td>
                                    <label>Related Posts</label>
                                </td>
                            </tr>
                        }
                    </tbody>

                        { (!hideRelatedPosts && relatedPostsFeed.length > 0 && !createMode) &&
                            <tbody>
                                {relatedPostsFeed.map((post, index) => (
                                    <PostCell post={post} mode={mode} createMode={createMode} viewMode={viewMode} presentStyle={presentStyle} postImageChanged={postImageChanged} postStickerChanged={postStickerChanged} postTextChanged={postTextChanged} postMentionsChanged={postMentionsChanged} postCloudChanged={postCloudChanged} createPost={createPost} togglePostLike={togglePostLike} />
                                ))}
                            </tbody>
                        }
                </table>
            </InfiniteScroll> }
        </div>
    )
}

export default PostPage
