import React from "react"
import ReactDOM from "react-dom"
import "./components/Styles/main.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import HttpsRedirect from "react-https-redirect"
import Honeybadger from "@honeybadger-io/js"
import ErrorBoundary from "@honeybadger-io/react"

const localDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

const honeybadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENV
}

const honeybadger = Honeybadger.configure(honeybadgerConfig)


ReactDOM.render(
  <React.StrictMode>
    {  localDev === true ?
        <App />
    :
      <HttpsRedirect>
        <ErrorBoundary honeybadger={honeybadger}>
          <App />
        </ErrorBoundary>
      </HttpsRedirect>
    }
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
