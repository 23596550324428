import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useParams, useLocation } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import { UserContext } from "../../Utils/context"
import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import PostCell from "../../PostPage/PostCell"
import CommentCell from "../../CommentPage/CommentCell"
import ProfileImageFull from '../../ProfilePage/ProfileImageFull'

import "../../Styles/adminUsers.scss"
import "../../Styles/adminUsersDetail.scss"

function AdminUsersDetail({ changePageLabelText }) {
    const location = useLocation()
    const { token } = useContext(UserContext)
    const { userid } = useParams()

    const [tableFilter, setTableFilter] = useState(() => {
        return location.state != null ? location.state.withTableFilter : "current"
    })

    const [currentReports, setCurrentReports] = useState([])
    const [previousReports, setPreviousReports] = useState([])
    const [suspendedReports, setSuspendedReports] = useState([])

    const [reportUser, setReportUser] = useState(null)
    const [reportUserSuspendedUntil, setReportUserSuspendedUntil] = useState(false)
    
    useEffect(() => {
        
        if (token != null) {

            if (reportUser === null) {
                getReportProfile()
            }
            
            getUserReports()
        }

    }, [token])

    const getUserReports = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/reports/user?user_id=${userid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.report_card != null) {
                const reports = response.data.report_card.items

                let current = []
                let previous = []
                let suspended = []

                for (let i = 0; i < reports.length; i++) {
                    const aReport = reports[i]

                    if (aReport.status === "open") {
                        current.push(aReport)
                    }

                    if (aReport.status === "warned" || aReport.status === "dismissed") {
                        previous.push(aReport)
                    }

                    if (aReport.status === "suspended") {
                        suspended.push(aReport)
                    }
                }

                previous = previous.reverse()
                suspended = suspended.reverse()

                setCurrentReports(current)
                setPreviousReports(previous)
                setSuspendedReports(suspended)

                if (response.data.report_card.suspended_until != null) {
                    setReportUserSuspendedUntil(response.data.report_card.suspended_until)
                }
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getReportProfile = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/users/${userid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.user != null) {
                setReportUser(response.data.user)
                changePageLabelText(`@${response.data.user.username}`)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const switchTableFilter = (option) => {
        setTableFilter(option)
    }
    
    return (
        <div className="admin-users-detail">
            <div className="admin-users-table-container">
                <div className="admin-users-options-container">
                    <button className={tableFilter === "current" ? "pro-active" : ""} onClick={() => switchTableFilter("current")}>
                        <span>{currentReports.length}</span>
                        <br></br>
                        Open
                    </button>

                    <button className={tableFilter === "previous" ? "pro-active" : ""} onClick={() => switchTableFilter("previous")}>
                        <span>{previousReports.length}</span>
                        <br></br>
                        Previous
                    </button>

                    <button className={tableFilter === "suspensions" ? "pro-active" : ""} onClick={() => switchTableFilter("suspensions")}>
                        <span>{suspendedReports.length}</span>
                        <br></br>
                        Suspensions
                    </button>
                </div>

                <InfiniteScroll dataLength={tableFilter === "current" ? currentReports.length : tableFilter === "previous" ? previousReports.length : suspendedReports.length}>
                    <table className="admin-users-detail-table">
                        <tbody>
                            { tableFilter === "current" && currentReports.map((currentReport, index) => 
                                currentReport.onContent === "Post" ? (
                                    <PostCell post={currentReport.content} reportitem={currentReport} admin={true} mode={"view"} createMode={false} viewMode={true} presentStyle={null} postImageChanged={() => {}} postStickerChanged={() => {}} postTextChanged={() => {}} postMentionsChanged={() => {}} postCloudChanged={() => {}} createPost={() => {}} togglePostLike={() => {}} />
                                ) :
                                currentReport.onContent === "Comment" ? (
                                    <CommentCell comment={currentReport.content} index={index} reportitem={currentReport} admin={true} />
                                ) :
                                currentReport.onContent === "User" ? (
                                    <tr>
                                        <td>
                                            <div>
                                                <ProfileImageFull toggleEditMode={null} editModeEnabled={false} editProfile={null} viewUser={currentReport.content} isCurrentUser={false} viewUserPoints={null} reportitem={currentReport} admin={true} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : 
                                (<tr></tr>)
                            ) }

                            { tableFilter === "previous" && previousReports.map((previousReport, index) =>
                                previousReport.onContent === "Post" ? (
                                    <PostCell post={previousReport.content} reportitem={previousReport} admin={true} mode={"view"} createMode={false} viewMode={true} presentStyle={null} postImageChanged={() => {}} postStickerChanged={() => {}} postTextChanged={() => {}} postMentionsChanged={() => {}} postCloudChanged={() => {}} createPost={() => {}} togglePostLike={() => {}} />
                                ) :
                                previousReport.onContent === "Comment" ? (
                                    <CommentCell comment={previousReport.content} index={index} reportitem={previousReport} admin={true} />
                                ) :
                                previousReport.onContent === "User" ? (
                                    <tr>
                                        <td>
                                            <div>
                                                <ProfileImageFull toggleEditMode={null} editModeEnabled={false} editProfile={null} viewUser={previousReport.content} isCurrentUser={false} viewUserPoints={null} reportitem={previousReport} admin={true} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : 
                                (<tr></tr>)
                            ) }

                            { tableFilter === "suspensions" && suspendedReports.map((suspendedReport, index) =>
                                suspendedReport.onContent === "Post" ? (
                                    <PostCell post={suspendedReport.content} reportitem={suspendedReport} initialSuspension={index === 0} reportUserSuspendedUntil={reportUserSuspendedUntil} admin={true} mode={"view"} createMode={false} viewMode={true} presentStyle={null} postImageChanged={() => {}} postStickerChanged={() => {}} postTextChanged={() => {}} postMentionsChanged={() => {}} postCloudChanged={() => {}} createPost={() => {}} togglePostLike={() => {}} />
                                ) :
                                suspendedReport.onContent === "Comment" ? (
                                    <CommentCell comment={suspendedReport.content} index={index} reportitem={suspendedReport} initialSuspension={index === 0} reportUserSuspendedUntil={reportUserSuspendedUntil} admin={true} />
                                ) :
                                suspendedReport.onContent === "User" ? (
                                    <tr>
                                        <td>
                                            <div>
                                                <ProfileImageFull toggleEditMode={null} editModeEnabled={false} editProfile={null} viewUser={suspendedReport.content} isCurrentUser={false} viewUserPoints={null} reportitem={suspendedReport} initialSuspension={index === 0} reportUserSuspendedUntil={reportUserSuspendedUntil} admin={true} />
                                            </div>
                                        </td>
                                    </tr>
                                ) : 
                                (<tr></tr>)
                            ) }
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default AdminUsersDetail
