import React from 'react'
import { useContext } from "react"
import axios from "axios"
import { UserContext } from "../Utils/context"
import { useHistory } from "react-router-dom"
import { trackPromise } from "react-promise-tracker" 
import AwsImage from "../Shared/AwsImage"
import Moment from 'react-moment'
import Pluralize from 'react-pluralize'

import SuspendedImage from "../Images/profile-icons/suspended-image.png"

import "../Styles/notificationCell.scss"

function NotificationCell({ notifications, removeNotification }) {
    const { token } = useContext(UserContext)

    let history = useHistory()

    const markNotifAsReadRoute = async(notif) => {
        if (notif.read != null && notif.read === true) {
            goToNotifURL(notif)
            return
        }

        const sendData = {
            notification_id: notif._id
        }

        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/notifications/read`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data != null) {
                goToNotifURL(notif)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const deleteNotif = async (notif, event) => {
        event.stopPropagation()

       try {
            const response = await trackPromise(axios.delete(
                `${process.env.REACT_APP_BASE_API}/notifications?notification_id=${notif._id}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data != null) {
                removeNotification(notif)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const getNotifInfo = (notif) => {
        let url = ""
        let caption = ""
        let caption_continued = ""

        switch(notif.onModel) {
            case "Achievement":
                url = ``
                caption = "You earned an achievement!"

                if (notif.object != null) {
                    if (notif.object.name != null) {

                        const achievementName = notif.object.name
                        if (achievementName === "Follow a Friend") {
                            caption = "You followed your first user."    
                        }

                        if (achievementName === "Clean Profile") {
                            caption = "Profile Complete"
                        }

                        if (achievementName === "First Post") {
                            caption = "You made your first post!"
                        }

                        if (achievementName === "Your First Follower") {
                            caption = "Your first follower!"
                        }
                    }
                }
                
                return [url, caption, caption_continued]
            case "Comment":
                const postCaption = (notif.object.post != null && notif.object.post.caption != null) ? `"${notif.object.post.caption}"` : ""

                url = `/posts/${notif.object.post._id}/comments`
                caption = notif.kind != null && notif.kind === "like comment" ? `liked your comment` : `commented on your post ${postCaption}`
                caption = notif.kind != null && notif.kind === "create comment web" ? "also commented on " : caption
                caption = notif.kind != null && notif.kind === "mention comment" ? "mentioned you in their comment" : caption

                caption_continued = notif.kind != null && notif.kind === "create comment web" ? ` post ${postCaption}` : caption_continued
                
                return [url, caption, caption_continued]
            case "Post":
                url = `/posts/${notif.object._id}`
                caption = `liked your post`
                caption = notif.kind != null && notif.kind === "mention post" ? "mentioned you in their post" : caption

                return [url, caption, caption_continued]
            case "Follower":
                url = `/profile/${notif.author._id}`
                caption = `followed you`
                return [url, caption, caption_continued]
            case "Mention":
                 url = `/posts/${notif.object._id}`
                 caption = `mentioned you in a post`
                return [url, caption, caption_continued]
            default:
                return [url, caption, caption_continued]
        }
    }

    const goToNotifURL = (notif) => {
        if (notif.onModel != null && notif.onModel != "Achievement") {

            const notifURL = getNotifInfo(notif)[0]
            const commentModel = notif.onModel === "Comment"
            const postModel = notif.onModel === "Post"

            if (commentModel) {
                const commentID = notif.object._id

                history.push({
                    pathname: notifURL,
                    state: { focusCommentID: commentID }
                })
            }else if (postModel) {
                history.push({
                    pathname: notifURL,
                    state: { hideRelatedPosts: true }
                })
            }else{
                history.push(notifURL)
            }
            
        }
    }

    const profileClicked = (notif, event, directID) => {
        event.stopPropagation()
        if (!directID) {
            history.push(`/profile/${notif.author._id}`)
        }else{
            history.push(`/profile/${directID}`)
        }
    }

    const notifCellStyle = (notif) => {
        return {
            backgroundColor: notif.read ? "#cacac9" : "white"
        }
    }

    return (
        notifications.map((result, index) => (
            <tr key={result._id}>
                <td className="notification-tablecell" style={notifCellStyle(result)} onClick={() => {markNotifAsReadRoute(result)}}>
                    <div className="notification-tr-content">
                        <div className="notification-tr-img">
                            <AwsImage fileKey={result.author.photo_url} customOnClick={(e) => profileClicked(result, e)} overrideImage={result.author.suspended ? SuspendedImage : null} />
                        </div>
                        <div className="notification-tr-label-container"> 
                            <label className="notification-tr-label">
                                { (result.onModel != null && result.onModel != "Achievement") &&
                                    <span className="notification-tr-label-username" onClick={(e) => profileClicked(result, e)}>@{result.author.username} </span>
                                }
                                
                                <span dangerouslySetInnerHTML={{__html: getNotifInfo(result)[1]}}></span>

                                { (
                                    (result.onModel != null && result.onModel === "Comment" && result.kind != null && result.kind === "create comment web") &&
                                    (result.object.post != null && result.object.post.author != null && result.object.post.author.username != null)
                                  )
                                    &&
                                    <span>
                                        <span className="notification-tr-label-username" onClick={(e) => profileClicked(result, e, result.object.post.author._id)}>@{result.object.post.author.username}</span>'s
                                        {getNotifInfo(result)[2]}
                                    </span>
                                }
                                

                            </label>
                            { (result.object != null && result.object.points != null && result.onModel != null && ((result.kind != null && result.kind === "like comment") || (result.onModel != "Comment" && !result.kind.includes("mention")))) && 
                                    <label className="notification-tr-label" style={{fontSize: "14px"}}><span>(<Pluralize singular={'point'} count={result.object.points} />)</span></label>
                            }
                            <Moment className="notification-tr-time" format={"MM/DD @ hh:mma"}>{result.createdAt}</Moment>
                        </div>
                    </div>
                    <div>
                    <button className="notification-tr-delete" onClick={(e) => {deleteNotif(result, e)}}>&times;</button>
                    </div>
                </td>
            </tr>
        ))

    )
}

export default NotificationCell
