import React from 'react'
import { useState, useEffect, useContext } from "react"
import { UserContext } from "../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import Moment from 'react-moment'

function AdminMetricsCell() {
    const { token } = useContext(UserContext)
    const [metrics, setMetrics] = useState(null)

    useEffect(() => {
        
        if (token != null) {
            getMetrics()
        }

    }, [token])

    const getMetrics = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/metrics`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.metrics != null) {
                setMetrics(response.data.metrics)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const refreshMetrics = async () => {
        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/admin/metrics`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.metrics != null) {
                setMetrics(response.data.metrics)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 
    }

    return (
        <tr>
            <td className="admin-metrics-tablecell">
                
                    <div>
                        <div className="metrics-refresh-div">
                            <button onClick={(e) => {refreshMetrics()}}>Refresh Metrics</button>
                            <label>
                                Last Updated: 
                                { (metrics != null && metrics.createdAt != null) && <Moment format={"MM/DD/YY @ hh:mma"}>{metrics.createdAt}</Moment> }
                                { (metrics === null || metrics.createdAt === null) && " Never" }
                            </label>
                        </div>

                        { metrics != null &&
                            <div className="metrics-container">
                                <label>
                                    Max Points:
                                    { metrics.wallet?.owner?.username != null &&
                                        ` @${metrics.wallet.owner.username} with ${metrics.wallet.total_points?.toLocaleString('en')} points`
                                    }
                                </label>
                                <label>New Users (7 days): {metrics.new_users?.toLocaleString('en')} 
                                    <span>Total: {metrics.total_users?.toLocaleString('en')} ({metrics.no_profile_users?.toLocaleString('en')}) ({metrics.ghost_users?.toLocaleString('en')})</span>
                                </label>
                                
                                {(metrics.dau != null && metrics.total_users != null) &&
                                    <label>DAU: {metrics.dau} ({(metrics.dau / metrics.total_users).toFixed(2)}%) <span>MAU: {metrics.mau} ({(metrics.mau / metrics.total_users).toFixed(2)}%)</span></label>
                                }
                                
                                <label>Posts w/ Clouds: {metrics.posts_with_clouds?.toFixed(2)}% <span>w/ Stickers: {metrics.posts_with_stickers?.toFixed(2)}%</span></label>
                                <label>Comments w/ Clouds: {metrics.comments_with_clouds?.toFixed(2)}%</label>
                                <label style={{height: "auto", paddingTop: "10px"}}>In App Purchases (30 days): {metrics.purchases?.toLocaleString('en')}</label>
                                <label style={{height: "auto"}}>Total Redemptions (30 days): {metrics.redemptions?.toLocaleString('en')}</label>
                            </div>
                        }
                    </div>
            </td>
        </tr>
    )
}

export default AdminMetricsCell
