import React from 'react'
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import Moment from "react-moment"
import Logo from "../../Images/Logo.png"



function AdminInvoicesPDF({ invoice, codes, month, year }) {

    const issuedByUserIsAdmin = (redemption) => {
        return redemption.issued_by.email != null ? process.env.REACT_APP_ADMIN_EMAILS.split(",").includes(redemption.issued_by.email) : false
    }

    const styles = StyleSheet.create({
        page: {
        paddingTop: "25px",
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingBottom: "11px"
        },
        centered: {
        width: "100%",
        height: "auto",
        textAlign: "center",
        marginBottom: "30px"
        },
        section: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "15px",
        },
        image: {
        height: "125px",
        objectFit: "contain",
        marginBottom: "10px"
        },
        bold_text: {
        fontFamily: "Helvetica-Bold",
        fontSize: "11px",
        minWidth: "150px"
        },
        reg_text : {
        fontFamily: "Helvetica",
        fontSize: "11px",
        minWidth: "150px"
        },
        footer: {
        position: "absolute",
        fontSize: "11px",
        bottom: 2,
        left: 0,
        right: 0,
        textAlign: "center"
        }
    })

    const statCount = (type) => {
        let statCount = 0
        for (let i = 0; i < codes.length; i++) {
            const aCode = codes[i]
            if (type === "delivered") {
                statCount++
            }
            if (type === "canceled" && aCode.valid === false) {
                statCount++
            }
        }

        return statCount
    }

    const totalCount = () => {
        const total = statCount("delivered") - statCount("canceled")
        return total
    }

    const InvoiceDocument = () => (
        <Document>
            <Page size="A4" orientation={"landscape"} style={styles.page}>
                    <View style={styles.centered}>
                        <Image style={styles.image} src={Logo}></Image>
                        <Text style={styles.bold_text}>Invoice for:  <Text style={styles.reg_text}>{invoice.name}</Text></Text>
                        <Text style={[styles.bold_text, { marginTop: "12px" }]}>Date:  <Text style={styles.reg_text}>{month} {year}</Text></Text>
                    </View>
                    
                    <View style={styles.section}>
                        <Text style={styles.bold_text}>Date</Text>
                        <Text style={styles.bold_text}>Redemption Code</Text>
                        <Text style={styles.bold_text}>Email</Text>
                        <Text style={styles.bold_text}>Issued By</Text>
                    </View>

                    {codes.map((redemption, i) =>
                        <View style={styles.section}>
                            <Text style={styles.reg_text}><Moment format={"MM/DD/YY"}>{redemption.issued_date}</Moment></Text>
                            <Text style={styles.reg_text}>{redemption.code}</Text>
                            <Text style={styles.reg_text}>{redemption.claimed_by.email}</Text>

                            { (redemption.valid === true && issuedByUserIsAdmin(redemption)) ?
                                <Text style={styles.reg_text}>support</Text> :
                              redemption.valid === false ?
                                <Text style={styles.reg_text}>canceled</Text> :
                                <Text style={styles.reg_text}>{redemption.issued_by.email}</Text>
                            }

                            
                            
                        </View>
                    )}

                <View style={{marginTop: "15px"}}>
                    <Text style={styles.reg_text}>{statCount("delivered")} Delivered</Text>
                    <Text style={styles.reg_text}>{statCount("canceled")} Canceled</Text>
                    <Text style={[styles.bold_text, { marginTop: "12px" }]}>Total: ${totalCount()}</Text>
                </View>

                <Text style={[styles.bold_text, styles.footer]} fixed={true}>Redemption Software LLC</Text>
            </Page>
        </Document>
    )

    return (
      <div className="admin-invoices-pdf-container">
         <PDFDownloadLink document={<InvoiceDocument />} fileName="invoice-test.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading invoice...' : 'Download Invoice')}
         </PDFDownloadLink>

        <label><span>Please note</span>: The Invoice Previewer is only fully functional on Desktop devices. If viewing on mobile, it is suggested that you view/download the invoice using the button above.</label>
        <PDFViewer height={window.innerHeight - 120} width={"100%"}>
            <InvoiceDocument />
        </PDFViewer>
      </div>

    )
}

export default AdminInvoicesPDF
