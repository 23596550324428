import React from 'react'
import { Helmet } from "react-helmet"
import Logo from "../components/Shared/Logo"

import "../components/Styles/contactPage.scss"

function ContactPage() {
    return (
        <div className="contact-page-container">
            <Helmet>
                <title>Do Better - Contact Us</title>
                <meta name="description" content="Contact us at support@dobetter.social. Let's do better together." />
            </Helmet>

            <Logo size={"med"} />

            <label>Contact Us</label>

            <p>
            Let's Do Better. Reach us any time below
            </p>

            <a href="mailto:support@dobetter.social">support@dobetter.social</a>

        </div>
    )
}

export default ContactPage
