import React from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import { useState, useEffect, useRef } from 'react'
import SubmitBtn from "../Shared/SubmitBtn"

import "../Styles/registerForm.scss"

function RegisterStepOne({ registerAccount }) {
    const recaptchaRef = useRef()

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [legalAge, setLegalAge] = useState(false);

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        
        const recaptchaToken = await recaptchaRef.current.executeAsync()

        if (email.trim().length === 0) {
            alert("Email is required.");
            return false;
        }
        
        if (password.trim().length === 0) {
            alert("Password is required.");
            return false;
        }

        if (!legalAge) {
            alert("You are required to at least 21 years old.");
            return false;
        }

        if (recaptchaToken === null || recaptchaToken.trim().length === 0) {
            alert("An error has occurred. Please contact Do Better Support");
            return false;
        }

        registerAccount(email, password, legalAge, recaptchaToken);
    }

    return (
        <div>

            <div>
                <label className="register-step-label">
                    To create your account, enter your 
                    <br></br>
                    email address, and your password.
                </label>
            </div>

            <div className="mar-vert-24"></div>

            <form className="register-form" id="register-step-one-form" onSubmit={handleRegisterSubmit}>
                
                <div className="register-form-content" style={{width: "75%"}}>
                    <input className="register-form-input" placeholder="email address" type="email" onChange={(e) => setEmail(e.target.value)} style={{width: "100%"}} required></input>
                    <input className="register-form-input" placeholder="password" type="password" onChange={(e) => setPassword(e.target.value)} style={{width: "100%"}} required></input>
                </div>

                <div className="register-form-content">
                    <input className="register-age-checkbox" type="checkbox" onClick={(e) => setLegalAge(e.target.checked)} required></input>
                    <label className="register-step-checkbox-label">I am at least 21 years old.</label>
                </div>

                <div className="register-form-content">
                    <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" theme="dark" />
                </div>

                <SubmitBtn text={"Next"} height={38} width={72} />

            </form>

        </div>
    )
}

export default RegisterStepOne
