import React from 'react'
import { useState } from "react"
import StoreFrontCell from "./StoreFrontCell"
import AwsImage from '../Shared/AwsImage'


import "../Styles/storeFront.scss"
 
function StoreFront() {

    const [displayInfo, setDisplayInfo] = useState(false)

    const items = [
        {"imageFileKey": "storefront/storefront-cell-one.png", "location": "/store/clouds"},
        {"imageFileKey": "storefront/storefront-cell-two.png", "location": "/store/one-hitters"},
        {"imageFileKey": "storefront/storefront-cell-three.png", "location": "/store/stickers"},
    ]

    return (
        <div className="store-front-container">
            
            <div className="store-front-top-container">
                <label>#DoBetter with Custom Clouds.</label>
                { !displayInfo && <button onClick={() => setDisplayInfo(true)}>?</button> }
                { displayInfo && <button onClick={() => setDisplayInfo(false)}>X</button> }
            </div>

            { !displayInfo && 
                <div>
                    <table className="store-front-table">
                        <tbody>
                            { items.map((item, index) => (
                                <StoreFrontCell item={item} />
                            )) }
                        </tbody>
                    </table>
                </div>
            }

            { displayInfo && 
                <div className="store-front-info">
                    <AwsImage fileKey={"storefront/store-info-button.png"} />
                </div>
            }

        </div>
    )
}

export default StoreFront
