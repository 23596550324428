import React from 'react'
import { useContext } from "react"
import { UserContext } from "../Utils/context"
import AwsImage from '../Shared/AwsImage'
import BagIcon from "../Images/bag-icon.png"

import "../Styles/pointsStatBar.scss"

function PointsStatBar({ totalPoints, availablePoints }) {
    const { user } = useContext(UserContext)

    return (
        <div className="points-stat-bar">

            <div className="points-profile-full-container">
                { (user.badge_visible && user.badge != null) &&
                    <AwsImage key={user.badge.image} fileKey={user.badge.image} customClassName="points-image-full-badge" useLoadingClass={false}  />
                }
                <div className="points-image-full-user">
                    <AwsImage fileKey={user.photo_url} />
                </div>
            </div>

            <div className="points-stat-available">
                <label>
                    <span>{availablePoints.toLocaleString('en')}</span>
                    <br></br>
                    Available
                </label>
            </div>

            <div className="points-stat-total">
                <label>
                    <span>{totalPoints.toLocaleString('en')}</span>
                    <br></br>
                    Total  
                </label>
                <img src={BagIcon}></img>
            </div>

        </div>
    )
}

export default PointsStatBar
