import React from 'react'
import { useState, useContext, useRef } from 'react'
import { useHistory } from "react-router-dom"
import { usePusher, UserContext } from "../Utils/context"
import ProfileUserInfo from "../ProfilePage/ProfileUserInfo"
import ImageCropModal from "../Shared/ImageCropModal"
import SubmitBtn from "../Shared/SubmitBtn"
import WhiteBackground from "../Images/white-background.jpg"
import BagIcon from "../Images/bag-icon.png"
import EditIcon from "../Images/profile-icons/edit-icon.png"
import SuspendedImage from "../Images/profile-icons/suspended-image.png"
import ProfileActionItem from "../ProfilePage/ProfileActionItem"
import RemoveBtn from "../Shared/RemoveBtn"
import AwsImage from "../Shared/AwsImage"
import SelectionModal from "../Shared/SelectionModal"
import AdminUsersDetailReport from "../AdminPage/Users/AdminUsersDetailReport"

import "../Styles/profileImageFull.scss"
import "../Styles/profileActionItem.scss"

function ProfileImageFull({ toggleEditMode, editModeEnabled, editProfile, viewUser, isCurrentUser, viewUserPoints, reportitem, admin, initialSuspension, reportUserSuspendedUntil }) {
    let history = useHistory()
    const { user } = useContext(UserContext)    

    const [displayBadge, setDisplayBadge] = useState(() => {
        return viewUser.badge_visible != null ? viewUser.badge_visible : true
    })
    const [newUsername, setNewUsername] = useState("")
    const [newBio, setNewBio] = useState(() => {
        return user.bio != null ? user.bio : null
    })
    const [newProfilePic, setNewProfilePic] = useState(null)
    const [profilePicWasChanged, setProfilePicWasChanged] = useState(() => {
        return (viewUser.photo_url === undefined || viewUser.photo_url === null || viewUser.photo_url.length === 0)
    })
    const [showImageCropModal, setShowImageCropModal] = useState(false)

    const [newBadge, setNewBadge] = useState(null)
    const [selectedBadgeImage, setSelectedBadgeImage] = useState(null)
    const [showSelectionModal, setShowSelectionModal] = useState(false)

    const profileImageRef = useRef()
    const profileImageRemoveContainerRef = useRef()
    const profileFileFieldContainer = useRef()

    const handleEditProfile = (edit) => {
        toggleEditMode(edit);
    }

    const handleEditProfileSubmit = (e) => {
        e.preventDefault();

        if (newBio === null || newBio.trim().length === 0) {
            alert("Bio is required")
            return false
        }

        if (profilePicWasChanged === true && newProfilePic === null) {
            alert("Profile Picture is required")
            return false
        }

        editProfile(newProfilePic, newBio, displayBadge, newBadge);
    }

    const usernameChanged = (newUsername) => {
        setNewUsername(newUsername);
    }

    const bioChanged = (newBio) => {
        setNewBio(newBio);
    }

    const badgeChoosen = (badgeID, badgeImage) => {

        if (badgeID === "no-display") {
            setSelectedBadgeImage(null)
            setNewBadge(null)
            setDisplayBadge(false)
            return
        }

        setDisplayBadge(true)
        setSelectedBadgeImage(badgeImage)
        setNewBadge(badgeID)
    }

    const profilePicChanged = (file) => {
        setNewProfilePic(file);
        profileImageRef.current.setAttribute("src", file)
        profileImageRemoveContainerRef.current.setAttribute("style", "display: block")
        profileFileFieldContainer.current.setAttribute("style", "display: none")
    
        setProfilePicWasChanged(true)
    }

    const removeProfilePic = () => {
        setNewProfilePic(null)
        profileImageRef.current.setAttribute("src", WhiteBackground)
        profileImageRemoveContainerRef.current.setAttribute("style", "display: none")
        profileFileFieldContainer.current.setAttribute("style", "display: block")

        setProfilePicWasChanged(true)
    }

    const badgeButtonClicked = () => {
        setShowSelectionModal(true)
    }

    const selectionModalClosed = () => {
        setShowSelectionModal(false)
    }

    const bagIconClicked = () => {
        if (isCurrentUser) {
            history.push("/points")
        }
    }

    const shouldShowAddPhotoButton = editModeEnabled && newProfilePic === null && !viewUser.photo_url
    const shouldShowNewProfilePhoto = editModeEnabled && newProfilePic != null
    const shouldShowExistingProfilePhoto = editModeEnabled && viewUser.photo_url

    const profileFileFieldContainerStyle = {
        display: shouldShowAddPhotoButton ? "block" : "none"
    }

    const profileImageRemoveContainerStyle = {
        display: shouldShowExistingProfilePhoto ? "block" : "none"
    }

    const editBadgeStyle = {
        color: editModeEnabled ? "#0433ff" : "black",
        cursor: editModeEnabled ? "pointer" : "default"
    }

    const pointLabelStyle = {
        color: isCurrentUser ? "#0433ff" : "black",
        cursor: isCurrentUser ? "pointer" : "default"
    }

    return (
        <div>
            <form id="edit-profile-form" onSubmit={(e) => { if (!admin) handleEditProfileSubmit(e)}}>
                <div className="profile-image-full-container">
                    
                    {(isCurrentUser && !editModeEnabled) && 
                        <div className="profile-edit-button" style={{cursor: "pointer"}} onClick={() => handleEditProfile(true)}>
                            <img src={EditIcon}></img>
                            <ProfileActionItem type={"Edit"} presentedWithImage={true} />
                        </div>
                    }


                    { (displayBadge && selectedBadgeImage === null) &&
                        <div>
                            { (viewUser.badge != null && viewUser.badge.image != null) &&
                                <AwsImage key={viewUser.badge.image} fileKey={viewUser.badge.image} customClassName={"profile-image-full-badge"} useLoadingClass={false} />
                            }
                        </div>
                    }

                    { (displayBadge && selectedBadgeImage !== null) &&
                        <div>
                            <img className="profile-image-full-badge" src={selectedBadgeImage}></img>
                        </div>
                    }

                    
                    <div>
                        { !editModeEnabled &&
                            <div className="profile-image-full-user">
                                <AwsImage key={viewUser.photo_url} fileKey={viewUser.photo_url} overrideImage={viewUser.suspended ? SuspendedImage : null} />
                            </div>
                        }

                        { editModeEnabled &&
                            <div className="profile-image-full-user">
                                <AwsImage key={viewUser.photo_url} fileKey={viewUser.photo_url} customRef={profileImageRef} useLoadingClass={false} />

                                <label className="profile-image-file-field-label" ref={profileFileFieldContainer} style={profileFileFieldContainerStyle}>
                                    + add your photo
                                    <ImageCropModal handleCroppedImage={profilePicChanged} />
                                </label> 
                                
                                <div className="profile-image-full-user-remove-container" ref={profileImageRemoveContainerRef} style={profileImageRemoveContainerStyle}>
                                    <RemoveBtn removeAction={removeProfilePic} />
                                </div>
                            </div>
                        }


                        { viewUserPoints != null && 
                            <div className="profile-full-extra-stat-left" onClick={bagIconClicked}>
                                <img src={BagIcon} style={{cursor: isCurrentUser ? "pointer" : "default"}}></img>
                                <label style={pointLabelStyle}>
                                {viewUserPoints.toLocaleString('en')} pts
                                </label>
                            </div> 
                        }

                        <div className="profile-full-extra-stat-right" onClick={(e) => { if (isCurrentUser && editModeEnabled) badgeButtonClicked() }}>
                            <label style={editBadgeStyle}>
                               { (viewUser.badge != null && viewUser.badge.name != null) && `"${viewUser.badge.name}"` }
                               { (viewUser.badge === null || viewUser.badge === undefined) && "No Badge" }
                            </label>
                        </div>
                    </div>


                </div>
                
                <ProfileUserInfo username={viewUser.username} bio={viewUser.suspended ? "This account has been suspended." : viewUser.bio} editModeEnabled={editModeEnabled} usernameChanged={usernameChanged} bioChanged={bioChanged} verified={viewUser.verified === true ? true : false} />

                { editModeEnabled &&
                    <SubmitBtn text={"Save"} height={38} width={72} />
                }

                { (admin && reportitem != null) && 
                    <div style={{marginTop: "10px"}}>
                        <AdminUsersDetailReport reportitem={reportitem} initialSuspension={initialSuspension} reportUserSuspendedUntil={reportUserSuspendedUntil} />
                    </div>
                }

                { (isCurrentUser && editModeEnabled && showSelectionModal) && <SelectionModal closeAction={selectionModalClosed} chooseAction={badgeChoosen} selectionType={"badges"} /> }
            </form>
        </div>
    )
}

export default ProfileImageFull
