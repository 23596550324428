import React from 'react'
import { useState, useEffect, useContext } from "react"
import { UserContext } from "../../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import SubmitBtn from "../../Shared/SubmitBtn"
import AdminInvoicesPDF from './AdminInvoicesPDF'

import "../../Styles/adminInvoices.scss"

function AdminInvoices() {
    const { token } = useContext(UserContext)

    const [outlets, setOutlets] = useState([])
    const months = ["January","February","March","April","May","June","July",
    "August","September","October","November","December"]

    const now = new Date().getUTCFullYear()
    const years = Array(now - (now - 20)).fill('').map((v, idx) => now - idx)

    const [invoiceOutlet, setInvoiceOutlet] = useState(null)
    const [invoiceMonth, setInvoiceMonth] = useState(null)
    const [invoiceYear, setInvoiceYear] = useState(null)

    const [generatedInvoice, setGeneratedInvoice] = useState(null)
    const [generatedCodes, setGeneratedCodes] = useState([])

    useEffect(() => {
        
        if (token != null) {
            getOutlets()
        }

    }, [token])

    const getOutlets = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/outlets`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.outlets !== null) {
                setOutlets(response.data.outlets)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createInvoice = async() => {

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/invoices?outlet_id=${invoiceOutlet}&month=${invoiceMonth}&year=${invoiceYear}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.invoice != null) {
                setGeneratedInvoice(response.data.invoice)
            }

            if (response.data.codes != null) {
                setGeneratedCodes(response.data.codes)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitInvoicesForm = (e) => {
        e.preventDefault()

        if (invoiceOutlet === null || invoiceOutlet.length === 0) {
            alert("Outlet is required")
            return false
        }

        if (invoiceMonth === null || invoiceMonth.length === 0) {
            alert("Month is required")
            return false
        }

        if (invoiceYear === null || invoiceYear.length === 0) {
            alert("Year is required")
            return false
        }

        createInvoice()

        return true
    }

    const exitInvoiceViewer = () => {
        setGeneratedInvoice(null)
        setGeneratedCodes([])
    }

    return (
        generatedInvoice === null ?
        <div>
            <form className="admin-invoices-form" onSubmit={submitInvoicesForm}>

                <div>
                    <label>Outlet:</label>
                    <select onChange={(e) => setInvoiceOutlet(e.target.value)}>
                        <option value="">Please select an outlet</option>

                        { outlets.map((outlet, index) => (
                            <option value={`${outlet._id}`}>{outlet.name}</option>
                        )) }
                    </select>
                </div>

                <div>
                    <label>Select Month:</label>
                    <select onChange={(e) => setInvoiceMonth(e.target.value)}>
                        <option value="">Please select a month</option>

                        { months.map((month, index) => (
                            <option value={`${index}`}>{month}</option>
                        )) }
                    </select>
                </div>

                <div>
                    <label>Select Year:</label>
                    <select onChange={(e) => setInvoiceYear(e.target.value)}>
                        <option value="">Please select a year</option>

                        { years.map((year, index) => (
                            <option value={`${year}`}>{year}</option>
                        )) }
                    </select>
                </div>

                <div className="mar-vert-24">
                    <SubmitBtn text={"Go"} height={38} width={72} />
                </div>

            </form>
        </div>
        :
        <div>
            <button className="admin-invoices-exit-button" type="button" onClick={() => {exitInvoiceViewer()}}>{"< Back"}</button>
            <AdminInvoicesPDF invoice={generatedInvoice} codes={generatedCodes} month={months[parseInt(invoiceMonth)]} year={invoiceYear} />
        </div>
    )
}

export default AdminInvoices
