import React from 'react'
import { useState, useEffect, useContext } from "react"
import { awsGetObject } from "../Utils/awsHandler"
import ListModal from "../Shared/ListModal"
import CloudDefault from "../Images/clouds/cloud-default.png"
import LikeEmpty from "../Images/posts/like-empty.png"
import LikeFilled from "../Images/posts/like-filled.png"
import SelectionModal from "../Shared/SelectionModal"
import Pluralize from 'react-pluralize'
import Moment from "react-moment"
import { MentionsInput, Mention } from 'react-mentions'
import axios from "axios"
import { UserContext } from "../Utils/context"
import { trackPromise } from 'react-promise-tracker'

import "../Styles/postText.scss"

function PostText({ post, commentValue, commentUsername, commentBubble, mode, presentStyle, postTextChanged, postCloudChanged, currentUserLikedComment, toggleCommentLike, commentID, commentLikeCount, commentCreatedAt, postMentionsChanged, commentSubmitted, commentIsCurrentUser }) {
    const { user, token } = useContext(UserContext)
    
    const viewMode = mode === "view"
    const createMode = mode === "create"
    const feedStyle = presentStyle === "feed"

    const [showSelectionModal, setShowSelectionModal] = useState(false)
    const [showListModal, setShowListModal] = useState(false)
    const [selectedCloudImage, setSelectedCloudImage] = useState(null)

    const [textValue, setTextValue] = useState("")

    const [currentUserFollowing, setCurrentUserFollowing] = useState(null)

    const [showScrollIndicatorLabel, setShowScrollIndicatorLabel] = useState(false)

    useEffect(() => {

        if (commentSubmitted != null && commentSubmitted) {
            setTextValue("")
        }

        if (post != null) {
            adaptTextAreaPaddingText()

            if (post.bubble != null) {
                getBubble(post.bubble.image)
            }
        }

        if (commentBubble != null) {
            getBubble(commentBubble)
        }

        if (createMode && token != null && currentUserFollowing === null) {
            getCurrentUserFollowing()
        }

        shouldDisplayScrollableTextViewLabel()
    }, [token, commentSubmitted])

    const getBubble = async (bubble) => {
        awsGetObject(bubble).then(res => {
            const base64 = res.Body.toString('base64')

            setSelectedCloudImage(`data:image;base64,${base64}`)

        }).catch(error => {
            
            //console.log(error)
            //throw(error)
        })
    }

    const getCurrentUserFollowing = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/me`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.following != null) {
                formatCurrentUserFollowingForMentions(response.data.following)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const postTextAreaChanged = (e, newValue, newPlainTextValue, mentions) => {
        adaptTextAreaPaddingText()
        setTextValue(e.target.value)

        if (postTextChanged != null) {
            const formattedPostText = formatPostTextToHTML(e.target.value)
            postTextChanged(formattedPostText, e.target)
        }

        if (postMentionsChanged != null) {
            postMentionsChanged(mentions)
        }
    }

    const customCloudsClicked = () => {
        setShowSelectionModal(true)
    }

    const selectionModalClosed = () => {
        setShowSelectionModal(false)
    }

    const customCloudChoosen = (cloudID, cloudImage, type) => {
        setSelectedCloudImage(cloudImage)
        postCloudChanged(cloudID, type)
    }

    const listModalClosed = () => {
        setShowListModal(false)
    }

    const likesLabelClicked = () => {
        setShowListModal(true)
    }

    const adaptTextAreaPaddingText = () => {

        // This code attempts to keep the textarea text vertically aligned in the center
        const textAreaContainers = document.getElementsByClassName("post-text-area--multiLine")
        for (let i = 0; i < textAreaContainers.length; i++) {
            const container = textAreaContainers[i]
            const textarea = container.getElementsByTagName("textarea")[0]

            if (textarea != null) {
                const previous_height = textarea.style.height
                let lines = 0
                textarea.style.height = 0
                const scrollHeightCalcd = textarea.style.paddingTop != "unset" ? textarea.scrollHeight - parseFloat(window.getComputedStyle(textarea, null).getPropertyValue('padding-top')) : textarea.scrollHeight
                lines = parseInt( scrollHeightCalcd/parseInt(getComputedStyle(textarea).lineHeight) )
                textarea.style.height = previous_height
        
                if (lines < 2) {
                    container.style.marginTop = "6%"
                }
    
                if (lines === 2) {
                    container.style.marginTop = "5%"
                }
        
                if (lines >= 3 && lines < 5) {
                    container.style.marginTop = "0%"
                }
        
                if (lines >= 5) {
                    container.style.marginTop = "unset"
                }
            }
        }


    }

    const shouldDisplayScrollableTextViewLabel = () => {
        if (viewMode && (post != null || commentValue != null)) {
            var viewTextContainer = document.getElementById(`post-text-view-${post != null ? post._id : commentID != null ? commentID : "undef"}`)
            var hasVerticalScrollbar = viewTextContainer.scrollHeight > viewTextContainer.clientHeight
            setShowScrollIndicatorLabel(hasVerticalScrollbar)
        }
    }

    const formatPostTextToHTML = (postText) => {
        let newPostText = postText

        // Format Mention
        newPostText = newPostText.split("[!^!]").join("<a class=\"mention\" href=\"/profile/")
        newPostText = newPostText.split("[!^^!]").join("\">@")
        newPostText = newPostText.split("[!^^^!]").join("</a>")

        return newPostText
    }

    function linkify(inputText) {
        let replacedText, replacePattern1, replacePattern2, replacePattern3;
    
        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
    
        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
    
        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    
        return replacedText;
    }

    const formatCurrentUserFollowingForMentions = (following) => {
        
        let mentionUsers = []
        for (let i = 0; i < following.length; i++) {
            const aFollowing = following[i]
            let menUser = {}
            menUser["id"] = aFollowing._id
            menUser["display"] = `${aFollowing.username}`
            mentionUsers.push(menUser)
        }

        setCurrentUserFollowing(mentionUsers)
    }

    const getMentionUsernameByID = (userID) => {
        let username = ""
        for (let i = 0; i < currentUserFollowing.length; i++) {
            const aFollowing = currentUserFollowing[i]
            if (aFollowing.id === userID) {
                username = `@${aFollowing.display}`
                break
            }
        }

        return username
    }

    const renderSuggestion = (entry, search, highlightedDisplay, index, focused) => {
        const suggestionList = document.getElementsByClassName("post-text-area__suggestions")[0]
        if (suggestionList != null) {
            suggestionList.addEventListener("touchmove", handleSuggestionsTouchMove)
        }

        return (<div className="user">{highlightedDisplay}</div>)
    }

    const handleSuggestionsTouchMove = (e) => {
        e.stopPropagation()
    }

    const scrollIndicatorStyle = {
        right: (commentValue != null && !commentIsCurrentUser) ? "-3px" : "-14px"
    }

    return (

        <div className="post-text-container">
            { createMode && <div className="post-text-clouds-btn">
                <label onClick={customCloudsClicked}>{"Custom Clouds >"}</label>
            </div> }
            { showSelectionModal && <SelectionModal closeAction={selectionModalClosed} chooseAction={customCloudChoosen} selectionType={"bubbles"} />}

            <div className="post-cloud-container">
                <img className="post-cloud-img" src={selectedCloudImage != null ? selectedCloudImage : CloudDefault}></img>
                { showScrollIndicatorLabel && <label className="post-scroll-indicator-label" style={scrollIndicatorStyle}>scroll</label> }
                
                { createMode &&
                    <MentionsInput className="post-text-area" placeholder="what's up?" onChange={postTextAreaChanged} value={textValue} readOnly={viewMode}>
                        <Mention trigger="@" data={currentUserFollowing} markup="[!^!]__id__[!^^!]__display__[!^^^!]" displayTransform={id => getMentionUsernameByID(id)} style={{
                            backgroundColor: "#d1e4fc"
                        }}  renderSuggestion={renderSuggestion}
                        />
                    </MentionsInput>
                }

                { (viewMode && (post != null || commentValue != null)) &&
                    <div className="post-text-area post-text-view" id={`post-text-view-${post != null ? post._id : commentID != null ? commentID : "undef"}`} dangerouslySetInnerHTML={{
                        __html: post != null ? linkify(post.caption.replace(/\n\r?/g, "<br />")) : linkify(commentValue.replace(/\n\r?/g, "<br />"))
                    }}>
                    </div>
                }

            </div>

            <div className="post-comment-actions-container">
                { (viewMode && !feedStyle) && 
                    <div className="post-text-date">
                    { commentCreatedAt != null &&  <Moment format={"MM/DD @ hh:mma"}>{commentCreatedAt}</Moment> }
                    </div>
                }
                
                { (viewMode && !feedStyle && commentID && toggleCommentLike != null) && 
                    <div className="post-text-like" style={{right: !commentIsCurrentUser ? "-28px" : "0"}}>
                        { !currentUserLikedComment && <img src={LikeEmpty} onClick={() => toggleCommentLike(true, commentID)}></img> }
                        { currentUserLikedComment && <img src={LikeFilled}></img> }
                        <label onClick={likesLabelClicked}><Pluralize singular={'like'} count={commentLikeCount} /></label>
                        { showListModal && <ListModal closeAction={listModalClosed} listType={"comment-likes"} commentID={commentID} />}
                    </div> 
                }
            </div>
        </div>
    )
}

export default PostText