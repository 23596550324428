import React from 'react'
import { useState } from "react"
import SubmitBtn from "../Shared/SubmitBtn"


import "../Styles/helpForm.scss"

function HelpStepOne({ findEmail }) {

    const [email, setEmail] = useState("")

    const handleHelpSubmit = async (e) => {
        e.preventDefault()

        if (email.trim().length === 0) {
            alert("Email is required")
            return false
        }

        findEmail(email)
    }

    return (
        <div>
            <div>
                <label className="help-step-label">
                    Enter your email address.
                </label>
            </div>

            <div className="mar-vert-24"></div>

            <form className="help-form" id="help-step-one-form" onSubmit={handleHelpSubmit}>
                
                <div className="help-form-content" style={{width: "75%"}}>
                    <input className="help-form-input" placeholder="email address" type="email" onChange={(e) => setEmail(e.target.value)} style={{width: "100%"}} required></input>
                </div>

                <div className="mar-vert-24"></div>

                <SubmitBtn text={"Next"} height={38} width={72} />

            </form>

        </div>
    )
}



export default HelpStepOne
