import React from 'react'
import { useContext } from 'react'
import { useHistory } from "react-router-dom"
import { UserContext } from "../Utils/context"
import SuspendedImage from "../Images/profile-icons/suspended-image.png"
import AwsImage from "../Shared/AwsImage"


import "../Styles/postProfilePic.scss"

function PostProfilePic({ post, mode }) {
    let history = useHistory()
    const { user } = useContext(UserContext)

    const viewMode = mode === "view"
    const createMode = mode == "create"

    const shouldDisplayUserBadge = ((createMode && user.badge_visible) || (viewMode && post != null && post.author.badge_visible))
    const userBadgeToDisplay = (createMode && user.badge != null) ? user.badge.image : (viewMode && post != null && post.author.badge != null) ? post.author.badge.image : ""

    const userProfileClicked = () => {
        if (post != null && post.author?._id != null) {
            history.push(`/profile/${post.author._id}`)
        }
    }
    
    return (
        <div className="post-profile-full-container">
            {  shouldDisplayUserBadge &&
                <AwsImage fileKey={userBadgeToDisplay} customClassName="post-image-full-badge" useLoadingClass={false}  />
            }
            <div className="post-image-full-user">
                { createMode && <AwsImage fileKey={user.photo_url} /> }
                { (viewMode && post != null) && <AwsImage fileKey={post.author.photo_url} customOnClick={userProfileClicked} customStyle={{cursor: "pointer"}} overrideImage={post.author.suspended ? SuspendedImage : null} /> }
            </div>
        </div>
    )
}

export default PostProfilePic
