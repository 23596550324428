import React from 'react'
import { useEffect } from "react"
import PostProfilePic from "./PostProfilePic"
import PostText from "./PostText"
import PostImage from "./PostImage"
import PostActions from "./PostActions"
import PostAddComment from "./PostAddComment"
import SubmitBtn from "../Shared/SubmitBtn"
import AdminUsersDetailReport from "../AdminPage/Users/AdminUsersDetailReport"

function PostCell({ post, reportitem, initialSuspension, reportUserSuspendedUntil, admin, mode, createMode, viewMode, presentStyle, postImageChanged, postStickerChanged, postTextChanged, postMentionsChanged, postCloudChanged, createPost, togglePostLike }) {
    return (
        <tr key={post != null ? post._id : ""}>
            <td>
                <form id="create-post-form" onSubmit={createPost}>
                    <div style={{width: "97%", display: "inline-flex", alignItems: "center"}}>
                        <PostProfilePic post={post} mode={mode} />
                        <PostText post={post} mode={mode} presentStyle={presentStyle} postTextChanged={postTextChanged} postCloudChanged={postCloudChanged} postMentionsChanged={postMentionsChanged} />
                    </div>
                    <PostImage post={post} mode={mode} postImageChanged={postImageChanged} postStickerChanged={postStickerChanged} />
                    
                    { (viewMode && post != null && !admin) && <PostActions post={post} togglePostLike={togglePostLike} /> }
                    { (viewMode && post != null && !admin) && <div className="mar-vert-36"></div> }
                    { (viewMode && post != null && !admin) && <PostAddComment post={post} />}

                    { (admin && reportitem != null) && 
                        <AdminUsersDetailReport reportitem={reportitem} initialSuspension={initialSuspension} reportUserSuspendedUntil={reportUserSuspendedUntil} />
                    }

                    { createMode && <div className="create-post-submitbtn-container">
                        <SubmitBtn text={"Post"} height={38} width={72} />
                    </div> }
                </form>
            </td>
        </tr>
    )
}

export default PostCell
