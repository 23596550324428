import React from 'react'
import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import SubmitBtn from "../Shared/SubmitBtn"


import "../Styles/adminCaptureModal.scss"

function AdminCaptureModal({ closeAction, customAction, type }) {
    /* Modal States */
    const [show, setShow] = useState(true)
    const handleShow = () => setShow(true)
    const handleClose = () => {
        closeAction()
        setShow(false)
    }

    const [suspendInterval, setSuspendInterval] = useState(null)
    const [reason, setReason] = useState("")

    const suspendType = type === "suspend"

    const modalTitle = suspendType ? "Suspend User" : "Dismiss Report"

    const submitCapture = (e) => {
        e.preventDefault()

        if (suspendType && (suspendInterval === null || suspendInterval.length === 0)) {
            alert("Suspend time is required")
            return false
        }

        if (reason.trim().length === 0) {
            alert("Reason is required")
            return false
        }

        if (suspendType) {
            customAction(suspendInterval, reason)
        }else{
            customAction(reason)
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} scrollable={false}>
                <Modal.Header closeButton style={{position: "sticky", top: "0", zIndex: "1", background: "white"}}>
                <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: "400px", padding: "0px"}}>
                   <form className="admin-capture-form" onSubmit={submitCapture}>
                        { suspendType && 
                            <div>
                                <label>Suspend Time:</label>
                                <select onChange={(e) => setSuspendInterval(e.target.value)}>
                                    <option value="">Please select a suspension time</option>
                                    <option value="7">7 days</option>
                                    <option value="14">14 days</option>
                                    <option value="30">30 days</option>
                                    <option value="180">6 months</option>
                                    <option value="365">1 year</option>
                                    <option value="730">2 years</option>
                                    <option value="3650">Indefinite</option>
                                </select>
                            </div>
                        }

                        <div>
                            <label>Reason:</label>
                            <textarea placeholder={"Describe the reason for this action"} onChange={(e) => setReason(e.target.value)}></textarea>
                        </div>

                        <div className="mar-vert-36">
                            <SubmitBtn text={suspendType ? "Suspend" : "Dismiss"} height={38} width={80} customBkgHex={suspendType ? "#bd1b11" : "#38b23a"} />
                        </div>
                   </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminCaptureModal
