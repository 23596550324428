import React from 'react'
import { Link } from "react-router-dom"

import "../Styles/landingHelpLink.scss"

function LandingHelpLink() {
    return (
        <div className="landing-help-link-container">
            <Link to="/help" className="landing-help-link">
                Forgot Password?
            </Link>
        </div>
    )
}

export default LandingHelpLink