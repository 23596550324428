import React from 'react'

import "../Styles/circleCount.scss"

function CircleCount({ type, count }) {

    const dotStyle = type === "dot"
    const circleClassName = dotStyle ? "circle-count circle-dot" : "circle-count"

    return (
        <div className={circleClassName}>{ !dotStyle && count }</div>
    )
}

export default CircleCount
