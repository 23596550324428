import React from 'react'
import AwsImage from "../Shared/AwsImage"
import UnFollowIcon from "../Images/profile-icons/unfollow-icon.png"

function ReportUser({ user, followed, unfollowUser }) {

    return (
        <tr>
            <td className="report-user-cell">
                <div className="report-user-tr-content">
                    <AwsImage fileKey={user.photo_url} customClassName={"report-user-tr-img"} />
                        <div className="report-user-tr-label-container"> 
                            <label className="report-user-tr-userlabel">@{user.username}</label>
                        </div>

                        { followed &&
                            <div className="report-user-tr-unfollow" onClick={() => unfollowUser()}>
                                <img src={UnFollowIcon}></img>
                            </div>
                        }
                </div>


            </td>
        </tr>
    )
}

export default ReportUser
