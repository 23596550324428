import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import SearchActive from "../Images/navigation-icons/search.png"
import SearchInactive from "../Images/navigation-icons/search-inactive.png"
import HomeActive from "../Images/navigation-icons/home.png"
import HomeInactive from "../Images/navigation-icons/home-inactive.png"
import CreateActive from "../Images/navigation-icons/newpost.png"
import CreateInactive from "../Images/navigation-icons/newpost-inactive.png"

import "../Styles/header.scss";

function HeaderIconLink({ location }) {

    const currentLocation = useLocation().pathname

    let useCustomStyle = () => {
        switch (location) {
            case "/create":
                return location === "/create" ? {height: "34px"} : {}
        }
    }

    const getIconImage = () => {
        /* Custom Icon Active/Inactive Handling */
        if (currentLocation.includes("/posts") && location === "/search") {
            return SearchActive
        }

        /* Default Icon Active/Inactive Handling */
        switch (location) {
            case "/search":
                return location === currentLocation ? SearchActive : SearchInactive
            case "/feed": 
                return location === currentLocation ? HomeActive : HomeInactive
            case "/create": 
                return location === currentLocation ? CreateActive : CreateInactive
        }
    }

    return (
        <div>
            <Link to={location} className="head-link"> 
                <img src={getIconImage()} style={useCustomStyle()} alt=""></img>
            </Link>
        </div>
    )
}

export default HeaderIconLink
