import React from 'react'
import { useContext } from "react"
import { UserContext } from "../Utils/context"

import "../Styles/messageBubble.scss"

function MessageBubble({ messages }) {
    const { user } = useContext(UserContext)

    const isMessageFromCurrentUser = (message) => {
        let isCurrentUser = false
        if (message != null) {
            if (message.author != null) {
                if (message.author._id === user._id) {
                    isCurrentUser = true
                }
            }
        }

        return isCurrentUser
    }

    return (
        messages.map((result, index) => (
            <tr key={result._id}>
                <td className="message-bubble-tablecell">
                    <div className={`message-bubble ${isMessageFromCurrentUser(result) ? "message-bubble-right" : "message-bubble-left"}`}>
                        {result.body}
                    </div>
                </td>
            </tr>
        ))
    )
}

export default MessageBubble
