import React from 'react'

import "../Styles/registerSteps.scss"

function RegisterSteps({ stepNumber }) {
    return (
        <div className="register-steps-container">

            <span className={stepNumber === 1 ? "register-step register-step-active" : "register-step"}>1</span>
            <span className={stepNumber === 2 ? "register-step register-step-active" : "register-step"}>2</span>
            <span className={stepNumber === 3 ? "register-step register-step-active" : "register-step"}>3</span>
        </div>
    )
}

export default RegisterSteps
