import React from 'react'
import { useState, useEffect, useContext } from "react"
import { UserContext } from '../components/Utils/context'
import { trackPromise } from 'react-promise-tracker'
import axios from "axios"

import "../components/Styles/purchaseHistoryPage.scss"

function PurchaseHistoryPage() {
    const { token } = useContext(UserContext)
    const [hasPurchases, setHasPurchases] = useState(null)

    useEffect(() => {
        
        if (token != null && hasPurchases === null) {
            customerPortal()
        }

    }, [token])

    const customerPortal = async () => {
        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/store/customer-portal`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.url != null) {
                if (response.data.url.length === 0) {
                    setHasPurchases(false)
                }else{
                    setHasPurchases(true)

                    window.location.href = response.data.url
                }
            }

        }catch (error) {
            if (error.response.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    return (
        <div className="purchase-history-page-container">
            { (hasPurchases != null && !hasPurchases) &&
                <label>You haven't made any purchases.</label>
            }
        </div>
    )
}

export default PurchaseHistoryPage
