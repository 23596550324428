import React from 'react'
import { useHistory } from "react-router-dom"

function AdminOutletsCell({ outlet }) {
    let history = useHistory()

    const outletCellClicked = () => {
        history.push(`/admin/outlets/${outlet._id}`)
    }

    const getAvailableCodeCount = () => {
        let availableCodeCount = 0
        for (let i = 0; i < outlet.codes.length; i++) {
            const aCode = outlet.codes[i]
            if (aCode["claimed_by"] === undefined) {
                availableCodeCount++
            }
        }

        return availableCodeCount
    }

    return (
        <tr onClick={outletCellClicked}>
            <td className="admin-outlets-cell">
                <div className="admin-outlets-cell-content">
                    <div><span>{outlet.name}</span></div>
                    <div></div>
                    <div>{getAvailableCodeCount()} available</div>
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletsCell
