import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import { UserContext } from "../../Utils/context"
import AdminOutletsCell from "./AdminOutletsCell"

import "../../Styles/adminOutlets.scss"

function AdminOutlets() {
    let history = useHistory()
    const { token } = useContext(UserContext)

    const [outlets, setOutlets] = useState([])

    useEffect(() => {
        
        if (token !== null) {
            getOutlets()
        }
        
    }, [token])

    const getOutlets = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/admin/outlets`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.outlets !== null) {
                setOutlets(response.data.outlets)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const createOutletButtonPressed = () => {
        history.push("/admin/outlets/create")
    }

    return (
        <div className="admin-outlets">
            <table className="admin-outlets-table">
                <thead>
                    <tr>
                        <td>
                            <button type="button" onClick={createOutletButtonPressed}>+</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                   { outlets.map((outlet, index) => (
                        <AdminOutletsCell outlet={outlet} />
                    )) }
                </tbody>
            </table>
        </div>
    )
}

export default AdminOutlets
