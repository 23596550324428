import React from 'react'
import { useState, useEffect, useContext } from "react"
import { useParams, useHistory } from "react-router-dom"
import { UserContext } from "../components/Utils/context"
import axios from "axios"
import { trackPromise } from "react-promise-tracker"
import ReportUser from "../components/ReportPage/ReportUser"
import SubmitBtn from "../components/Shared/SubmitBtn"

import "../components/Styles/reportPage.scss"

function ReportPage() {
    let history = useHistory()
    const { user, token } = useContext(UserContext)
    const { content, userid, contentid } = useParams()

    const [reportUser, setReportUser] = useState(null)
    const [reportUserFollowed, setReportUserFollowed] = useState(false)

    const [category, setCategory] = useState("")
    const [reason, setReason] = useState("")

    useEffect(() => {
        if (token !== null && userid !== null) {
            getProfile()
        }
    }, [token])

    const getProfile = async () => {
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/users/${userid}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.followers != null) {

                const reportUserFollowers = response.data.followers
                for (let i = 0; i < reportUserFollowers.length; i++) {
                    const aUser = reportUserFollowers[i]
                    if (aUser._id === user._id) {
                        setReportUserFollowed(true)
                        break
                    }
                }
            }

            if (response.data.user != null) {
                setReportUser(response.data.user)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const unfollowReportUser = async () => {
        try {
            const response = await trackPromise(axios.put(
                `${process.env.REACT_APP_BASE_API}/users/unfollow?user_id=${reportUser._id}`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data != null) {
                setReportUserFollowed(false)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const reportContent = async () => {

        const onContentEndPoint = 
            content === "post" ? "posts" :
            content === "comment" ? "comments" :
            content === "user" ? "profiles" : ""

        const sendData = {
            "user": {
                "user_id": userid
            },
            "report": {
                "content": contentid != null ? contentid : userid,
                "reason": reason.trim(),
                "category": category
            }
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API}/reports/${onContentEndPoint}`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            )

            if (response.data.report_card != null) {
                alert("Your report has successfully been submitted.")
                history.goBack()
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const submitReport = (e) => {
        e.preventDefault()

        if (category === null || category.length === 0) {
            alert("Category is required")
            return false
        }

        if (reason === null || reason.length === 0) {
            alert("Reason is required")
            return false
        }

        reportContent()

        return true
    }

    return (
        <div className="report-page-container">
            <div>
                <label className="report-page-label">Report this User</label>
            </div>

            <div style={{margin: "50px 16px"}}>
                <table className="report-user-table">
                    <tbody>
                        { reportUser !== null && <ReportUser user={reportUser} followed={reportUserFollowed} unfollowUser={unfollowReportUser} /> }
                    </tbody>
                </table>
                
            </div>

            <div>
                <form className="report-page-form" onSubmit={submitReport}>
                    <div>
                        <label>Category:</label>
                        <select onChange={(e) => setCategory(e.target.value)}>
                            <option value="">Please select a category</option>
                            <option value="explicit">Explicit Content</option>
                            <option value="violence">Violence</option>
                            <option value="harasment">Harassment</option>
                            <option value="hate_speech">Hate Speech</option>
                            <option value="copyright">Copyright Infringement</option>
                            <option value="self_harm">Self Harm</option>
                        </select>
                    </div>

                    <div>
                        <label>Reason:</label>
                        <textarea placeholder={"Describe the reason for the report"} onChange={(e) => setReason(e.target.value)}></textarea>
                    </div>

                    <div className="mar-vert-36">
                        <SubmitBtn text={"Submit"} height={38} width={80} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ReportPage
