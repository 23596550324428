import React from 'react'
import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import SubmitBtn from "./SubmitBtn"
import BagIcon from "../Images/bag-icon.png"
import CloseIcon from "../Images/remove-icon.png"

import "../Styles/dialogModal.scss"

function DialogModal({ closeAction, submitAction, customItem, showBagIcon, showCloseIcon, showSubmitBtn }) {
    /* Modal States */
    const [show, setShow] = useState(true)
    const handleShow = () => setShow(true)
    const handleClose = () => {
        closeAction()
        setShow(false)
    }

    return (
        <>
            <Modal className="dialog-modal" show={show} onHide={handleClose} scrollable={false}>
                <Modal.Body className="dialog-modal-body">
                    { (!customItem || customItem === null) && 
                        <div>
                            <img className="dialog-modal-body-img" src={BagIcon}></img>
                            <div className="dialog-modal-body-content">
                                <div className="dialog-modal-body-content-head">1,000 points!</div>
                                <span>You just put 1,000 points in your <br></br>dobetter bag!</span>
                                <br></br>
                                <br></br>
                                Earn more points, for completing your profile.
                                <br></br>
                                <br></br>
                                Earn 5,000 points, and redeem your first bag!
                            </div>
                        </div>
                    }

                    { customItem != null &&
                        <div className={customItem.textClass != null ? customItem.textClass : ""}>
                            { (showBagIcon != null && showBagIcon === true) && <img className="dialog-modal-body-img" src={BagIcon}></img> }
                            { (showCloseIcon != null && showCloseIcon === true) && <img className="dialog-close-icon" src={CloseIcon} onClick={() => {handleClose()}}></img> }
                            <div className="dialog-modal-body-content">
                                <div className="dialog-modal-body-content-head">{customItem.headText}</div>
                                <div dangerouslySetInnerHTML={{ __html: customItem.subText }}>

                                </div>
                            </div>
                        </div>
                    }

                    <div className="dialog-modal-body-confirm">
                        { !showSubmitBtn && <button type="button" onClick={handleClose}>Got it!</button> }
                        { showSubmitBtn && 
                            <form onSubmit={(e) => {submitAction(e); handleClose();}}>
                                <SubmitBtn text={customItem.submitBtnText != null ? customItem.submitBtnText : "Submit"} height={38} width={95} />
                            </form>
                            
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DialogModal
