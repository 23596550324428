import React from 'react'
import { useState, useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import { trackPromise } from 'react-promise-tracker';
import { UserContext } from "../components/Utils/context"
import axios from "axios"
import UserCell from "../components/Shared/UserCell"
import InfiniteScroll from "react-infinite-scroll-component"


import "../components/Styles/profileDynamicPage.scss"

function ProfileRelationsPage() {
    const { user, token } = useContext(UserContext)
    const { userid, type } = useParams()

    const [viewUser, setViewUser] = useState(null)
    const [viewUserFollowers, setviewUserFollowers] = useState([])
    const [viewUserFollowing, setviewUserFollowing] = useState([])

    const [followersCount, setFollowersCount] = useState(0)
    const [followingCount, setFollowingCount] = useState(0)

    const [currentUserFollowers, setCurrentUserFollowers] = useState([])
    const [currentUserFollowing, setCurrentUserFollowing] = useState([])

    const [relationType, setRelationType] = useState(() => {
        return type != null ? type : "followers"
    })

    const isCurrentUser = user._id === userid
        
    useEffect(() => {
        if (userid != null) {
            retrieveProfile(userid)
        }
    }, [])
    
    const retrieveProfile = async (userID) => {

        // Retrieve Viewing User's Profile
        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/users/${userID}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.followers != null) {
                setviewUserFollowers(response.data.followers)
                setFollowersCount(response.data.followers.length)

                if (isCurrentUser) {
                    setCurrentUserFollowers(response.data.followers)
                }
            }

            if (response.data.following != null) {
                setviewUserFollowing(response.data.following)
                setFollowingCount(response.data.following.length)

                if (isCurrentUser) {
                    setCurrentUserFollowing(response.data.following)
                }
            }

            if (response.data.user != null) {
                setViewUser(response.data.user)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }

        if (!isCurrentUser) {
            // Retrieve Current User's Profile
            try {
                const response = await trackPromise(axios.get(
                    `${process.env.REACT_APP_BASE_API}/me`,
                    { headers: { 'authorization': 'Bearer ' + token.access_token } }
                ))

                if (response.data.followers != null) {
                    setCurrentUserFollowers(response.data.followers)
                }

                if (response.data.following != null) {
                    setCurrentUserFollowing(response.data.following)
                }

            }catch (error) {
                if (error.response?.data?.message != null) {
                    alert(error.response.data.message)
                }
                throw(error)
            }
        }
    }

    const switchRelationType = (option) => {
        setRelationType(option)
    }

    const triggerRelation = async (userID, option) => {
        try {
            const response = await trackPromise(axios.put(
                `${process.env.REACT_APP_BASE_API}/users/${option}?user_id=${userID}`,
                {},
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data != null) {
                retrieveProfile(userid)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    return (
        <div className="profile-relations-page-container">
            { viewUser != null && 
            <div>
                <div className="profile-relations-username-container">
                    @{viewUser.username}
                </div>
                <div className="profile-relations-options-container">
                    <button className={relationType === "followers" ? "pro-active" : ""} onClick={() => switchRelationType("followers")}>
                        <span>{viewUserFollowers.length}</span>
                        <br></br>
                        Followers
                    </button>

                    <button className={relationType === "following" ? "pro-active" : ""} onClick={() => switchRelationType("following")}>
                        <span>{viewUserFollowing.length}</span>
                        <br></br>
                        Following
                    </button>
                </div>
                <InfiniteScroll dataLength={relationType === "followers" ? viewUserFollowers.length : viewUserFollowing.length}>
                    <table className="profile-relations-table">
                        <tbody>
                            <UserCell users={relationType === "followers" ? viewUserFollowers : viewUserFollowing} currentUserFollowers={currentUserFollowers} currentUserFollowing={currentUserFollowing} triggerRelation={triggerRelation} />
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div> 
            }
        </div>
    )
}

export default ProfileRelationsPage
