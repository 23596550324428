import React from 'react'
import { useState } from 'react'
import SubmitBtn from "../Shared/SubmitBtn"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import "../Styles/registerForm.scss"

function RegisterStepThree({ registerPhoneNumber, registerActivationCode }) {

    const [stepThreeMode, setStepThreeMode] = useState("send")

    const [phoneNumber, setPhoneNumber] = useState("");
    const [activationCode, setActivationCode] = useState("");

    const handlePhoneNumberSubmit = (e) => {
        e.preventDefault();

        if (phoneNumber.trim().length === 0) {
            alert("Mobile Number is required");
            return false;
        }

        registerPhoneNumber(phoneNumber);
        setStepThreeMode("verify");
    }

    const handleActivationCodeSubmit = (e) => {
        e.preventDefault();

        if (activationCode.trim().length === 0) {
            alert("Activation Code is required");
            return false;
        }
        
        registerActivationCode(activationCode);
    }
    
    return (
        <div>
            
            <div id="register-send-container" style={{display: stepThreeMode === "send" ? "block" : "none"}}>
                <form id="register-phone-number-form" onSubmit={handlePhoneNumberSubmit}>
                    <div className="mar-vert-24" style={{marginBottom: "0px"}}>
                        <label className="register-step-label">Two-Factor Authentication</label>
                    </div>
                    
                    <div className="mar-vert-24">
                        <label className="register-step-label" style={{margin: "0 70px"}}>Please enter your mobile phone number.</label>
                    </div>

                    <div className="register-form-content mar-vert-60">
                        <PhoneInput countryCodeEditable={false} inputClass="register-phone-input" buttonClass="register-phone-input-btn" placeholder="Mobile Number" country={'us'} onChange={(phone) => setPhoneNumber(phone)} required />
                    </div>

                    <div className="mar-vert-24">
                        <label className="register-step-label" style={{margin: "0 70px"}}>We'll send you an Activation Code.</label>
                    </div>

                    
                    <SubmitBtn text={"Send Code"} height={38} width={124} />

                </form>
            </div>

            <div id="register-verify-container" style={{display: stepThreeMode === "verify" ? "block" : "none"}}>
                <form id="register-phone-number-verify-form" onSubmit={handleActivationCodeSubmit}>
                    <div className="mar-vert-36">
                        <label className="register-step-label" style={{lineHeight: "28px"}}>
                            Enter your Activation Code:
                            <br></br>
                            <a className="register-sendagain-link" onClick={(e) => {e.preventDefault(); setStepThreeMode("send"); return false;}}>Send Again</a>
                        </label>
                    </div>

                    <div className="register-form-content mar-vert-60">
                        <input className="register-form-input" placeholder="Activation Code" type="text" onChange={(e) => setActivationCode(e.target.value)} autoComplete="one-time-code" required></input>
                    </div>

                    <SubmitBtn text={"Finish"} height={38} width={80} />
                </form>
            </div>

        </div>
    )
}

export default RegisterStepThree
