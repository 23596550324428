import React from 'react'
import axios from "axios"
import { useEffect, useContext } from "react"
import { UserContext } from "../components/Utils/context"
import { trackPromise } from "react-promise-tracker"
import NotificationCell from "../components/NotificationPage/NotificationCell"
import InfiniteScroll from "react-infinite-scroll-component"
import RefreshContent from "../components/Shared/RefreshContent"

import "../components/Styles/notificationPage.scss"

function NotificationPage({ notifications, handleNotificationCount, removeNotification, retrieveNotifications, hasMoreNotifPages }) {
    const { token } = useContext(UserContext)

    useEffect(() => {
        if (token) {
            refreshNotifications()
        }
    }, [token])

    const refreshNotifications = () => {
        retrieveNotifications(true, true)
    }

    return (
        <div className="notification-page-container">
            <div className="notifications-head-container">
                Notifications
            </div>
            <InfiniteScroll dataLength={notifications != null ? notifications.length : 0} next={retrieveNotifications} hasMore={hasMoreNotifPages} pullDownToRefresh refreshFunction={refreshNotifications} pullDownToRefreshThreshold={80} pullDownToRefreshContent={<RefreshContent type={"pull"} />} releaseToRefreshContent={<RefreshContent type={"release"} />}>
                <table className="notifications-table">
                    <tbody>
                        { notifications != null && <NotificationCell notifications={notifications} removeNotification={removeNotification} /> }
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    )
}

export default NotificationPage
