import React from 'react'
import { useHistory, Link } from "react-router-dom";

import ProfileActionItem from "./ProfileActionItem"
import FollowIcon from "../Images/profile-icons/follow-icon.png"
import UnfollowIcon from "../Images/profile-icons/unfollow-icon.png"
import MessageIcon from "../Images/profile-icons/message-icon.png"
import EditIcon from "../Images/profile-icons/edit-icon.png"

import "../Styles/profileActionBar.scss"

function ProfileActionBar({ type, viewUser, isCurrentUser, postCount, followers, following, toggleFollowUser, currentUserFollowingViewUser }) {
    let history = useHistory()

    const followersCount = (followers != null && followers.length > 0) ? followers.length : 0
    const followingCount = (following != null && following.length > 0) ? following.length : 0

    const followActionItemType = currentUserFollowingViewUser ? "unfollow" : "follow"
    const followActionItemText = currentUserFollowingViewUser ? "Unfollow" : "Follow"

    const relationIcon = currentUserFollowingViewUser ? UnfollowIcon : FollowIcon


    const messageClicked = () => {
        history.push(`/messages/${viewUser._id}`)
    }
    

    return (
        <div>

            { type === "stats" && (
                <div className="profile-action-bar">
                    <Link to={{pathname: `/profile/${viewUser._id}/posts`, state: { username: viewUser.username }}}>
                        <ProfileActionItem type={"Posts"} count={postCount} presentedWithImage={false} />
                    </Link>

                    <Link to={`/profile/${viewUser._id}/followers`}>
                        <ProfileActionItem type={"Followers"} count={followersCount} presentedWithImage={false} />
                    </Link>
                    
                    <Link to={`/profile/${viewUser._id}/following`}>
                        <ProfileActionItem type={"Following"} count={followingCount} presentedWithImage={false} />
                    </Link>
                </div>    
            )}

            { (type === "actions" && !isCurrentUser) && (
                <div className="profile-action-bar" style={{justifyContent: "space-between", position: "absolute", width: "100%", padding: "15px 5%", zIndex: 1}}>
                    <div style={{cursor: "pointer"}} onClick={() => toggleFollowUser(followActionItemType)}>
                        <img src={relationIcon}></img>
                        <ProfileActionItem type={followActionItemText} presentedWithImage={true} />
                    </div>
                    <div style={{cursor: "pointer"}} onClick={messageClicked}>
                        <img src={MessageIcon}></img>
                        <ProfileActionItem type={"Message"} presentedWithImage={true} />
                    </div>
                </div>
            )}
            
        </div>
    )
}

export default ProfileActionBar
