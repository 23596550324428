import React from 'react'
import { useState } from 'react'
import SubmitBtn from "../Shared/SubmitBtn"

import "../Styles/loginForm.scss"


function LoginForm({ login }) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        
        if (email.trim().length === 0 || password.trim().length === 0) {
            alert("Both email and password are required");
            return false;
        }

        login(email, password);
    }

    return (
        <div className="login-form-container">
            <form id="login-form" onSubmit={handleLoginSubmit}>
                <div className="mar-vert-12">
                    <input className="login-form-input" placeholder="email" type="email" onChange={(e) => {setEmail(e.target.value)}} required></input>
                    <input className="login-form-input" placeholder="password" type="password" onChange={(e) => {setPassword(e.target.value)}} required></input>
                </div>
                <div style={{paddingTop: "10px"}}>
                    <SubmitBtn text={"Log In"} />
                </div>
            </form>
            
        </div>
    )
}

export default LoginForm
