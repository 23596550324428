import React from 'react'
import { useHistory } from 'react-router-dom'
import AwsImage from "../Shared/AwsImage"

import FollowIcon from "../Images/profile-icons/follow-icon.png"
import UnFollowIcon from "../Images/profile-icons/unfollow-icon.png"
import SuspendedImage from "../Images/profile-icons/suspended-image.png"
import VerifiedIcon from "../Images/profile-icons/verified.png"
import NotVerifiedIcon from "../Images/profile-icons/not-verified.png"

import "../Styles/userCell.scss"

function UserCell({ users, currentUserFollowers, currentUserFollowing, triggerRelation, userClickLocation, verifyUser }) {
    let history = useHistory()

    const adminUserSearchLocation = userClickLocation === "admin/users" ? true : false

    const userCellClicked = (result) => {
        if (userClickLocation != null) {
            history.push(`/${userClickLocation}/${result._id}`)
        }else{
            history.push(`/profile/${result._id}`)
        }
    }

    const relationClicked = (result, event) => {
        event.stopPropagation()
        event.preventDefault()

        let relation = "follow"
        for (let i = 0; i < currentUserFollowing.length; i++) {
            const aFollowing = currentUserFollowing[i]
            if (aFollowing._id === result._id) {
                relation = "unfollow"
                break
            }
        }

        triggerRelation(result._id, relation)
    }

    const getRelationIcon = (result) => {
        let icon = FollowIcon
        for (let i = 0; i < currentUserFollowing.length; i++) {
            const aFollowing = currentUserFollowing[i]
            if (aFollowing._id === result._id) {
                icon = UnFollowIcon
                break
            }
        }

        return icon
    }

    const verifyClicked = (result, event) => {
        event.stopPropagation()
        event.preventDefault()

        const alertMessage = `Are you sure you'd like to ${result.verified === true ? "Unverify" : "Verify"} @${result.username}?`
        if (window.confirm(alertMessage)) {
            verifyUser(result)
        }
    }

    const getVerifiedIcon = (result) => {
        return result.verified === true ? VerifiedIcon : NotVerifiedIcon
    }

    return (
        users.map((result, index) => (
                <tr key={result != null ? result._id : ""}>
                   { result != null && 
                        <td className="user-result-tablecell" onClick={() => userCellClicked(result)}>
                                <div className="user-result-tr-content-holder">
                                    <div className="user-result-tr-content">
                                        <AwsImage fileKey={result.photo_url} customClassName={"user-result-tr-img"} overrideImage={result.suspended ? SuspendedImage : null} />
                                        <div className="user-result-tr-label-container"> 
                                            <label className="user-result-tr-userlabel">
                                                @{result.username}

                                                { result.verified === true &&
                                                    <img src={VerifiedIcon}></img>
                                                }
                                            </label>
                                            <label className="user-result-tr-biolabel">{result.suspended ? "This account has been suspended." : result.bio}</label>
                                        </div>
                                    </div>
                                    
                                    { triggerRelation && 
                                        <div className="user-result-custom-action" onClick={(e) => relationClicked(result, e)}>
                                            <img src={getRelationIcon(result)}></img>
                                        </div>
                                    }

                                    { adminUserSearchLocation && 
                                        <div className="user-result-custom-action" onClick={(e) => verifyClicked(result, e)}>
                                            <img src={getVerifiedIcon(result)}></img>
                                        </div>
                                    }
                                </div>
                        </td>
                    }
                </tr>
        ))
    )
}

export default UserCell
