import React from 'react'
import { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { UserContext } from "../Utils/context"
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import AwsImage from '../Shared/AwsImage'
import DialogModal from "../Shared/DialogModal"

import "../Styles/redeemOutletsCell.scss"

function RedeemOutletsCell({ outlet, selectedState }) {
    let history = useHistory()
    const { token } = useContext(UserContext)

    const [showDialogModal, setShowDialogModal] = useState(false)

    const actionButtonClicked = () => {
        setShowDialogModal(true)
    }

    const dialogModalClosed = () => {
        setShowDialogModal(false)
    }

    const redeemFormSubmit = async (e) => {
        e.preventDefault()

        const sendData = {
            "outlet_id": outlet._id
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/me/points/redemption`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            if (response.data.redemption != null) {
                history.push(`/points/redeemed/${response.data.redemption._id}`)
            }

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }

        return true
    }

    const dialogCustomItem = {
        headText: `Confirm.`,
        subText:  `<br></br>Your redemption code will be generated one time only, and should be redeemed immediately.<br></br>This redemption will deduct 5,000 points from your account.`,
        submitBtnText: "Redeem"
    }

    const getLocationsForSelectedState = () => {
        let stateLocations = []
        for (let i = 0; i < outlet.locations.length; i++) {
            const aLocation = outlet.locations[i]
            if (aLocation.state === selectedState) {
                stateLocations.push(aLocation)
            }
        }

        return stateLocations
    }

    return (
        <tr key={outlet._id}>
            <td className="redeem-outlet-cell">
                <div>   
                    <div className="redeem-outlet-cell-title">
                        <label>{outlet.name}</label>
                        <span>({outlet.sub_title})</span>
                    </div>

                    <div className="redeem-outlet-cell-image-container">
                        <AwsImage fileKey={outlet.logo} />
                    </div>

                    <div className="redeem-outlet-cell-desc">
                        <label>
                            {outlet.description}
                        </label>
                    </div>

                    <div className="redeem-outlet-action-info" onClick={actionButtonClicked}>
                        <div>
                            <label>
                                Available:
                                { (outlet.nationwide != null && outlet.nationwide === true) &&
                                    <span>Everywhere</span>
                                }

                                { (outlet.nationwide === null || !outlet.nationwide) && getLocationsForSelectedState().map((location, index) => (
                                    <span>{location.city}{(getLocationsForSelectedState().length - 1) === index ? "" : ","}</span>
                                )) }
                            </label>

                        </div>
                        <div>
                            <label className="redeem-outler-action-label">
                                { outlet.redemption_text }
                            </label>
                        </div>
                        
                    </div>

                    {showDialogModal &&
                        <DialogModal closeAction={dialogModalClosed} submitAction={redeemFormSubmit} customItem={dialogCustomItem} showBagIcon={true} showCloseIcon={true} showSubmitBtn={true} />
                    }

                    <hr></hr>
                </div>
            </td>
        </tr>
    )
}

export default RedeemOutletsCell
