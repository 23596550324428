import React from 'react'
import { useState, useEffect, useContext } from "react"
import { UserContext } from '../Utils/context'
import { trackPromise } from "react-promise-tracker"
import axios from "axios"
import RedeemOutletsCell from "./RedeemOutletsCell"

import "../Styles/redeemOutlets.scss"

function RedeemOutlets({ availablePoints }) {
    const { token } = useContext(UserContext)

    const states = ["AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"]

    const [selectedState, setSelectedState] = useState(null)
    const [stateOutlets, setStateOutlets] = useState([])

    const getOutletsForState = async (state) => {

        try {
            const response = await trackPromise(axios.get(
                `${process.env.REACT_APP_BASE_API}/store/outlets?state=${state}`,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))
            
            if (response.data.outlets != null) {
                setStateOutlets(response.data.outlets)
            }


        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const stateSelectChanged = (state) => {
        setSelectedState(state)
        getOutletsForState(state)
    }

    return (
        <div>
            <div className="redeem-outlets-select-container">
                <label>Select your State:</label>
                <select onChange={(e) => stateSelectChanged(e.target.value)}>
                    <option value="">Select</option>

                    { states.map((state, index) => (
                        <option value={`${state}`}>{state}</option>
                    )) }
                </select>
            </div>

            <table className="redeem-outlets-table">
                <thead>
					<tr>
                        <td>
                            { selectedState === null &&
                                <label>For a list of outlets in your area, please select your State above.</label>
                            }

                            { (selectedState != null && stateOutlets.length === 0) &&
                                <label>No available outlets found in {selectedState}.</label>
                            }
                        </td>
                    </tr>
                </thead>

                <tbody>
                    { stateOutlets.map((outlet, index) => (
                        <RedeemOutletsCell outlet={outlet} availablePoints={availablePoints} selectedState={selectedState} />
                    )) }
                </tbody>
            </table>
        </div>
    )
}

export default RedeemOutlets
