import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import axios from "axios"
import { UserContext } from "../components/Utils/context"
import { trackPromise } from 'react-promise-tracker';

import Logo from "../components/Shared/Logo"
import RegisterSteps from "../components/RegisterPage/RegisterSteps"
import RegisterStepOne from "../components/RegisterPage/RegisterStepOne"
import RegisterStepTwo from "../components/RegisterPage/RegisterStepTwo"
import RegisterStepThree from "../components/RegisterPage/RegisterStepThree"

function RegisterPage({ localStoreUserData }) {
    const { user, token } = useContext(UserContext)
    const history = useHistory();
    const [registerStepsNumber, setRegisterStepsNumber] = useState(1);

    useEffect(() => {
        if (user) {
          if (!user.username) {
              setRegisterStepsNumber(2)
          }else if (!user.phone_confirmed) {
              setRegisterStepsNumber(3)  
          }
        }
    }, []);

    const registerAccount = async (email,  password, legalAge, recaptcha) => {

        const account = { email, password, recaptcha };
        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/auth/register`,
                account
            ))
            
            localStoreUserData(response.data)
            
            setRegisterStepsNumber(2);

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }

            throw(error)
        }        
        
    }

    const registerPhoneNumber = async (phoneNumber) => {
        const sendData = {
            "verify": {
                "phone_number": "+" + phoneNumber
            }
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/auth/request-phone-confirmation`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            localStoreUserData(response.data)

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        } 

    }

    const registerActivationCode = async (activationCode) => {

        const sendData = {
            "verify": {
                "code": activationCode
            }
        }

        try {
            const response = await trackPromise(axios.post(
                `${process.env.REACT_APP_BASE_API}/auth/confirm-phone`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            localStoreUserData(response.data)

            if (response.data.user != null) {
                history.push(`/profile/${response.data.user._id}`);
            }else{
                history.push("/")
            }
            

        }catch (error) {
            if (error.response?.data?.message != null) {
                alert(error.response.data.message)
            }
            throw(error)
        }
    }

    const registerUsername = async (username) => {


        const sendData = {
            "user": {
                "username": username
            }
        }
        try {
            const response = await trackPromise(axios.patch(
                `${process.env.REACT_APP_BASE_API}/me`,
                sendData,
                { headers: { 'authorization': 'Bearer ' + token.access_token } }
            ))

            localStoreUserData(response.data)
            setRegisterStepsNumber(3);

        }catch (error) {
            if (error != null) {
                alert("This username has already been or taken and or special characters were used.")
            }
            throw(error)
        } 

        
    }

    return (
        <div className="register-container" style={{minHeight: "calc(100vh - 120px)"}}>
            <Logo size={"med"}/>
            <div className="mar-vert-12"></div>
            <RegisterSteps stepNumber={registerStepsNumber} />
            <div className="mar-vert-24"></div>

            { registerStepsNumber === 1 && (<RegisterStepOne registerAccount={registerAccount} />) }
            { registerStepsNumber === 2 && (<RegisterStepTwo registerUsername={registerUsername} />)}
            { registerStepsNumber === 3 && (<RegisterStepThree registerPhoneNumber={registerPhoneNumber} registerActivationCode={registerActivationCode} />)}
            
        </div>
    )
}

export default RegisterPage;
