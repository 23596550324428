import React from 'react'

function NotFoundPage() {
    return (
        <div>
            <h2>404 Not Found</h2>
        </div>
    )
}

export default NotFoundPage;
